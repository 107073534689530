import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import Sidebar from "../components/layout/Sidebar";
import Header from "../components/layout/Header";
import { call } from "../utils/apiCall";
import useAuth from "../hooks/useAuth";
import useQuery from "../utils/useQuery";


const Content = ({data, initialTab, setTabName}) => {
  const { isAuthenticated } = useAuth();
  return (
      <section className="dasboard_main_area list-experts-area">
        {!isAuthenticated && <h2>Legal</h2>}
        <div className="legal list-experts-main">
          <Tabs
              defaultActiveKey={initialTab || "policy"}
              id="uncontrolled-tab-example"
              onSelect={(key) => setTabName(key)}
          >
            <Tab eventKey="policy" title="Policy">
              <div>
                <div className="tabs_pannel" id="tab1">
                  <div className="table-exp-main">
                    <div className="p-3 txt3">
                      <textarea
                      id = "legal-textarea"
                      value={data}
                      disabled = {true}>
                      </textarea>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="term_condition" title="Terms and Conditions">
              <div>
                <div className="tabs_pannel" id="tab2">
                  <div className="table-exp-main">
                    <div className="p-3 txt3">
                      <textarea
                      id = "legal-textarea"
                      value={data}
                      disabled = {true}></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </section>
  );
};



const Legal = () => {
  let query = useQuery();
  let initialTab = query.get("tab");

  const [data, setData] = useState("");
  const [tabName, setTabName] = useState(query.get("tab") || "policy");

  useEffect(() => {
    (async () => {
      try {
        const res = await call(
          "get",
          `/get-footer-content?search=${tabName}`,
          null,
          null,
          null
        );
        setData(res?.data?.data[0].footer_text);
        console.log(res.data.data, "about us Data");
      } catch (err) {
        console.log(err);
      }
    })();
  }, [tabName]);

  const { isAuthenticated } = useAuth();


  return (
    <main className="page_wrapper">
      {isAuthenticated ? (
        <section className="dashboard_section d-flex">
          <div>
            <Sidebar />
          </div>
          <section className="dashboard_main_section">
            <Header title="Legal" />
            <Content data={data} initialTab={initialTab} setTabName={setTabName}  />
          </section>
        </section>
      ) : (
        <Content data={data} initialTab={initialTab} setTabName={setTabName} />
      )}
    </main>
  );
};

export default Legal;
