import React from 'react';
import map from "../../../assets/img/location-icon.svg";
import date from "../../../assets/img/calender-icon.svg";
import { Link,  useHistory} from 'react-router-dom';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';

const QuestionItem = ({item, id, name, ownerId, size, page, filterData, selected}) => {
    const history = useHistory();
    // const onClick1 = (value) => {
    //     {history.push("/user", {data: {item.id}})}
    // }

    const funcEditButton = () => {
        const question_text= item.question_text;
        // if (item.question_status === "open"){
        history.push({
            pathname: `/edit/${id}`,
            data: {
                name, id, question_text
              }
            
          });
        // }
        // else
        // alert ("User cannot edit the Closed question.")
    }

    const handleSubmit = e => {
        e.preventDefault();
        history.replace({
          pathname: "/question",
          data: {
            name, size, filterData, page, selected 
          }
        });
        history.push({
          pathname: `/user/${id}/${ownerId}`,
          data: {
            name, size, filterData, page, selected
          }
        });
      };

      const lastActivityDate = (responseDate, subresponseDate) => {
        if (new Date(responseDate).valueOf() > new Date(subresponseDate).valueOf())
        {
           
           // console.log(date1, date2, "response")
            return responseDate;
        }
        else
     //   console.log(date1, date2, "sub-repsonse")
        return subresponseDate;
    }

    // const shubh = lastActivityDate ("2022-08-24T15:25:13.000Z", null)
    // console.log(shubh)

    return (
        <div className="my-question-item">
            <div className="myquestion-row row gx-0">
                <div className="question-label myquestion-col">
                    <p>Question :</p>
                </div>
                <div className="my-question myquestion-col ">
                  
                {/* <span to={{pathname: `/user/${id}/${ownerId}`, data: id}}>  </span>  */}
                {name==="My Questions"?
                <><p><span onClick={handleSubmit} style={{cursor:"pointer"}}>{item.question_text}</span> {item.response_count <= 1 && item.question_status === "open"? <span><sup style={{cursor:"pointer"}}><EditTwoToneIcon onClick={funcEditButton} sx={{ top: "5px", right: "5px", fontSize: 16, color:'#808080',  "&:hover": { color: "#0d9edf" }}}  /></sup></span> : ""}</p> </>:
                <p style={{cursor:"pointer"}} onClick={handleSubmit}>{item.question_text}</p> }
                {/* <span onClick={shubh}><b>EDIT</b></span> */}
                    {/* <span onClick={() => }>shubh</span>
                    */}


                    
                </div>
                <div className="my-question-status myquestion-cols open  text-end">
                    {item.question_status === "open" ? (
                        <p>Open</p>
                    ) : (
                        <p style={{color:"#FF0000"}}>Closed</p>
                    )}
                </div>
            </div>
            <div className="myquestion-details row">
                <div className="question-details-col location ">
                <p>
                        <img src={date} alt="" />
                        {new Date(item.createdAt)
                            .toLocaleDateString('en-US', {
                                day: 'numeric', month: 'short', year: 'numeric'
                              }).replace(/ /g, ' ')}
                    </p>
                </div>
                <div className="question-details-col date ">
                
                    <p>
                        <img src={map} alt="" />
                        {item.location_name}
                    </p>
                </div>
                <div className="question-details-col response-counter  text-end">
                    <p>
                        {item.response_count} Total
                        Responses
                    </p>
                </div>
            </div>
            <div className="myquestion-details row">
                <div className="question-details-col location ">
                {/* <p>
                Last Response Date: 
                        {new Date(item.createdAt)
                            .toLocaleDateString('en-US', {
                                day: 'numeric', month: 'short', year: 'numeric'
                              }).replace(/ /g, ' ')}
                    </p> */}
                </div>
                <div className="question-details-col date ">
                
                    {/* <p>
                        <img src={map} alt="" />
                        {item.location_name}
                    </p> */}
                </div>
                <div className="question-details-col response-counter  text-end">
                    <p>
                    Last Activity:   {lastActivityDate(item.last_response_date, item.last_response_of_response_date)===null ?
                              "NA" :
                              new Date(lastActivityDate(item.last_response_date, item.last_response_of_response_date))
                            .toLocaleDateString('en-US', {
                                day: 'numeric', month: 'short', year: 'numeric'
                              }).replace(/ /g, ' ')
                            }
                    </p>
                </div>
            </div>
           
        </div>
    );
};

export default QuestionItem;
