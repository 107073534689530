import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import tick from "../../../assets/img/tick.png";
import close from "../../../assets/img/close.png";
import block from "../../../assets/img/block.png";
import Sidebar from "../../../components/layout/Sidebar";
import Header from "../../../components/layout/Header";
import Form from 'react-bootstrap/Form'
import ExpertsList from "./Experts-list";
import CometChatAdv from "./CometChatAdv";
import {useEffect, useState} from 'react' ;
import {CometChat} from '@cometchat-pro/chat'
import TablePaginationWrapper from "../../../components/TablePaginationWrapper";
import { call } from "../../../utils/apiCall";
import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner";
import 'react-toastify/dist/ReactToastify.css';

function List() {

  const[requestFilter, setRequestFilter] = useState("Sent")
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [update, setUpdate] = useState(false)
  const [chatLoader, setChatLoader] = useState(true)
  const [cometError, setCometError] = useState(false)
  const [cometErrorMessage, setCometErrorMessage] = useState("Something went wrong. Please try again later :(")
  // const [expertUpdated, setExpertUpdated] = useState(false)

  useEffect(() => {
    const authKey = "1d5300d739f8a6205e3d91fc068dca079af8a227";
    const userData = localStorage.getItem("user");
    let uid = JSON.parse(userData).data.id
    CometChat.login(uid, authKey).then(
      user => {
        console.log("Login Successful:", { user });    
        setChatLoader(false)
      },
      error => {
        setCometError(true)
        if(error.code === "ERR_UID_NOT_FOUND"){
          setCometErrorMessage("This user does not have an account in Comet Chat")
        }
      }
    );
  }, [])

  useEffect(() => {
    console.log(page, totalPages);
    if (page > totalPages && totalPages !== 0) {
      setPage(totalPages);
    }
    else if(totalPages === 0){
      setPage(0)
    }
  }, [totalPages]);

  const onSizeChange = (e) => {
    setSize(parseInt(e.target.value));
  };

  const onClickNext = () => {
    setPage(page + 1);
  };

  const onClickBack = () => {
    if(page !== 0){
      setPage(page - 1)
    }
  };

  useEffect(() => {
    let token = localStorage.getItem("accessToken");
    let payload = {
      page: page || 1,
      size,
      type : requestFilter
    };
    (async () => {
      try {
        const res = await call("post", "/request-list", null, payload, token);
        console.log(res.data);
        setTotalPages(res.data?.total_pages);
        console.log(res.data.list, "list data");
        setList(res.data.list);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [page, size, requestFilter, update]);

  const handleRequestFilterChange = (e) => {
    setRequestFilter(e.target.value)
    setPage(1)
  }

  const handleRequestAction = async (targetUser, targetAction) => {
    let token = localStorage.getItem("accessToken")
    const payload = {
      user_id : targetUser,
      status : targetAction
    }
    const res = await call("post", '/request-actions', null, payload, token)
    if(res.data.success === true){
      toast.configure()
      toast.success(res.data.message, {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
      setUpdate(!update)
    }
    else if(res.data.success === false){
      toast.configure()
      toast.error(res.data.message, {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
    }
  }

    return (
      <>
        <main className="page_wrapper">
          <section className="dashboard_section d-flex">
            <div>
              <Sidebar />
            </div>
            

            <section className="dashboard_main_section">
              <Header title='List of Experts' />
              <section className="dasboard_main_area list-experts-area"> 

                <div className="list-experts-main">
                  <Tabs
                    defaultActiveKey="profile"
                    id="uncontrolled-tab-example"
                  >
                    <Tab eventKey="profile" title="List of Experts">
                      <ExpertsList expertListUpdater = {setUpdate} currentUpdater = {update} pager = {setPage}/>
                    </Tab>
                    <Tab eventKey="Connections" title="Connections">
                      <div>
                        {/* <div className="tabs_pannel" id="tab2">
                          <div className="connect-area">
                            <div className="connect-container">
                              <div className="connection-row d-flex align-items-center">
                                <div className="connect-col connection-img">
                                  <div className="profile">
                                    <img
                                      className="img-fluid"
                                      src="assets/img/connection-icon-1.png"
                                      alt=""
                                    />
                                  </div>
                                </div>
                                <div className="connect-col connection-content">
                                  <div className="connect-header d-flex justify-content-between align-items-center">
                                    <div className="connect-name">
                                      <p>Nakula Bagchi</p>
                                    </div>
                                    <div className="connect-date">
                                      <p> 02/04/2022</p>
                                    </div>
                                  </div>
                                  <div className="connect-body">
                                    <p>
                                      Lorem ipsum dolor sit amet, consectetur
                                      adipiscing elit, sed do eiusmod tempor
                                      incididunt ut labore et dolore magna
                                      aliqua.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="connection-row d-flex align-items-center">
                                <div className="connect-col connection-img">
                                  <div className="profile">
                                    <img
                                      className="img-fluid"
                                      src="assets/img/connection-icon-2.png"
                                      alt=""
                                    />
                                  </div>
                                </div>
                                <div className="connect-col connection-content">
                                  <div className="connect-header d-flex justify-content-between align-items-center">
                                    <div className="connect-name">
                                      <p>Marvin McKinney</p>
                                    </div>
                                    <div className="connect-date">
                                      <p> 02/04/2022</p>
                                    </div>
                                  </div>
                                  <div className="connect-body">
                                    <p>
                                      Amet minim mollit non deserunt ullamco est
                                      sit aliqua dolor do amet sint. Velit
                                      officia consequat duis enim velit mollit.
                                      Ex.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="connection-row d-flex align-items-center">
                                <div className="connect-col connection-img">
                                  <div className="profile">
                                    <img
                                      className="img-fluid"
                                      src="assets/img/connection-icon-3.png"
                                      alt=""
                                    />
                                  </div>
                                </div>
                                <div className="connect-col connection-content">
                                  <div className="connect-header d-flex justify-content-between align-items-center">
                                    <div className="connect-name">
                                      <p>Devon Lane</p>
                                    </div>
                                    <div className="connect-date">
                                      <p> 02/04/2022</p>
                                    </div>
                                  </div>
                                  <div className="connect-body">
                                    <p>
                                      dolor do amet sint. Velit officia
                                      consequat duis enim velit m
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div className="connection-row d-flex align-items-center">
                                <div className="connect-col connection-img">
                                  <div className="profile">
                                    <img
                                      className="img-fluid"
                                      src="assets/img/connection-icon-4.png"
                                      alt=""
                                    />
                                  </div>
                                </div>
                                <div className="connect-col connection-content">
                                  <div className="connect-header d-flex justify-content-between align-items-center">
                                    <div className="connect-name">
                                      <p>Brooklyn Simmons</p>
                                    </div>
                                    <div className="connect-date">
                                      <p> 02/04/2022</p>
                                    </div>
                                  </div>
                                  <div className="connect-body delivered">
                                    <p>
                                      Lorem ipsum dolor{" "}
                                      <span>
                                        <img
                                          src="assets/img/simle-hear-emoji.svg"
                                          alt=""
                                        />
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="connection-row d-flex align-items-center">
                                <div className="connect-col connection-img">
                                  <div className="profile">
                                    <img
                                      className="img-fluid"
                                      src="assets/img/connection-icon-5.png"
                                      alt=""
                                    />
                                  </div>
                                </div>
                                <div className="connect-col connection-content">
                                  <div className="connect-header d-flex justify-content-between align-items-center">
                                    <div className="connect-name">
                                      <p>Annette Black</p>
                                    </div>
                                    <div className="connect-date">
                                      <p> 02/04/2022</p>
                                    </div>
                                  </div>
                                  <div className="connect-body read">
                                    <p>👨 👨 👦 👦</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="table-pagination-wrapper">
                            <div className="table-pagination-con d-flex align-items-center justify-content-lg-end justify-content-center">
                              <span className="pagination-label">
                                Items per page:
                              </span>
                              <select className="select-page-number" name="" id="">
                                <option value="">05</option>
                              </select>
                              <span className="pagination-label page-counter">
                                1 of 3
                              </span>
                              <span className="pagination-controls">
                                <a className="prev" href="{}">
                                  ‹
                                </a>
                                <a className="next" href="{}">
                                  ›
                                </a>
                              </span>
                            </div>
                          </div>
                        </div> */}
                        <div className="tabs_pannel" id="tab2">  
                          {chatLoader === true ? 
                          <div className="chat-loader-area">
                            {cometError === false ? 
                            <>
                              <Oval
                              height={80}
                              width={80}
                              color="#0d9edf"
                              wrapperStyle={{}}
                              wrapperClass=""
                              visible={true}
                              ariaLabel='oval-loading'
                              secondaryColor="#0d9edf"
                              strokeWidth={2}
                              strokeWidthSecondary={2}
                              />
                              <p>Loading...</p>
                            </> : 
                            <>
                              <p>{cometErrorMessage}</p>
                            </>}
                          </div>  
                          : 
                          <CometChatAdv />}
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="Requests" title="Requests">
                      <div>
                        <div className="tabs_pannel" id="tab3">
                          <div className="table-exp-main request-area">
                            <div className="experts-table-filter select_container">
                              <span className="filter-sort ">
                                <div className="dropholder">
                                    <Form.Select value = {requestFilter} onChange = {(e) => handleRequestFilterChange(e)} size="sm" aria-label="Default select example">
                                      <option value = "Received">Recived</option>
                                      <option value="Sent">Sent</option>
                                    </Form.Select>
                                </div>
                              </span>
                            </div>
                            <div className="exp-table-container table-responsive">
                              <table className="table list_experts_table">
                                <thead>
                                  <tr>
                                    <th style={{display: "none"}}>
                                      <div className="input-box">
                                        <input type="checkbox" name="" id="" />
                                      </div>
                                    </th>
                                    <th>
                                      <span className="exp-name">
                                        Name{" "}
                                        <img
                                          src="assets/img/table-up-arrow.png"
                                          alt=""
                                        />
                                      </span>
                                    </th>
                                    <th>Location</th>
                                    <th>Badge</th>
                                    <th>
                                      <span className="act-title"> Action</span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    list && list.map((ele) => {
                                      return(
                                        <tr>
                                          <td style={{display: "none"}}>
                                            <div className="input-box">
                                              <input type="checkbox" name="" id="" />
                                            </div>
                                          </td>
                                          <td>
                                            <div className="exp-name-val">
                                              {" "}
                                              {ele.screen_name}
                                            </div>
                                          </td>
                                          <td>{ele.locations}</td>
                                          <td>{ele.badge || "N/A"}</td>
                                          <td>
                                            {
                                              requestFilter==="Sent" ?
                                              <button style={{padding:"4px 15px"}} onClick = {() => handleRequestAction(ele.user_id, "cancel")} className="btn btn-xl shadow-none request-action flag-cancel">Cancel</button> :
                                              <>
                                              <span className="d-hover">
                                              <span className="hover">
                                                  <img src={tick} alt="img" onClick={() => handleRequestAction(ele.user_id, "accept")}/>
                                                  <span>Accept</span>
                                              </span>
                                              <span className="mx-3 hover">
                                                <img src={close} alt="img" onClick={() => handleRequestAction(ele.user_id, "reject")}/>
                                                <span>Reject</span>
                                              </span>
                                              <span className="hover">
                                                <img src={block} alt="img" onClick={() => handleRequestAction(ele.user_id, "block")}/>
                                                <span>Block</span>
                                              </span>
                                              </span>
                                              </>
                                            }
                                            
                                          </td>
                                        </tr> 
                                      )
                                    })
                                  }                 
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <TablePaginationWrapper
                            onChange={onSizeChange}
                            page={page}
                            pagination={totalPages}
                            onClickNext={onClickNext}
                            onClickBack={onClickBack}
                          />
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </section>
            </section>
          </section>
        </main>
      </>
    );
  }
export default List;

