import React, { useState, useEffect, useRef } from "react";
import bell from "../../assets/img/bell-icon.svg";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import useAuth from "../../hooks/useAuth";
//import axios from 'axios'
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
//import {Buffer} from 'buffer';
import { call } from "../../utils/apiCall";
import { time_ago } from "../../utils/timeFunc";
import App from "../imageverify";

export default function Header(props) {
  const [close, setClose] = useState(true);
  const [notify, setNotify] = useState(true);
  const [admin, setAdmin] = useState(true);
  // const [simage, setSimage] = useState(profile);
  const [notificationsList, setNotificationsList] = useState();
  const [notificationsCount, setNotificationsCount] = useState();
  const [loading, setLoading] = useState(false);
  const node1 = useRef();
  const node2 = useRef();

  const { logout } = useAuth();
  const { user } = useAuth();

 // console.log(user);

  // const menuToggle = () => {
  //   setClose(!close)
  // }
  const openDiv = () => {
    notificationList();
    setNotify(!notify);
  };
  const adminClick = () => {
    setAdmin(!admin);
  };

  useEffect (() => {
    const token = localStorage.getItem("accessToken");
    (async () => {
      try {
        const res = await call(
          "get",
          `/count-unread-notifications/`,
          null,
          null,
          token
        );
        setNotificationsCount(res?.data);
        console.log(res.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [])

  // useEffect(() => {
  //   if(user?.data?.profile_image!==null){
  //   let token = localStorage.getItem("accessToken");
  //   axios.get(`${user?.data?.profile_image}`, { headers: { 'authorization': token }, responseType: 'arraybuffer' }).then((res) => {

  //     let data = `data:${res.headers["content-type"]
  //       };base64,${new Buffer(res.data, "binary").toString("base64")}`;
  //     setSimage(data)
  //   });}
  // }, [])

  const notificationList = () => {
   
      setLoading(true);
      const token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "post",
            `/notification-list`,
            null,
            {
              page: 1,
              size: 5,
            },
            token
          );
          setNotificationsList(res?.data);
          console.log(res.data.data);
          setLoading(false);
        } catch (err) {
          console.log(err);
          setLoading(false);
        }
      })();
    
  };

  const clickOutsideAdmin = (e) => {
    if (node1.current.contains(e.target)) {
   //   console.log("clicked inside");
      return;
    }
   // console.log("clicked outside scope");
    setAdmin(true);
  };

  const clickOutsideNotify = (e) => {
    if (node2.current.contains(e.target)) {
   //   console.log("clicked inside");
      return;
    }
  //  console.log("clicked outside scope");
    setNotify(true);
  };

  useEffect(() => {
    document.addEventListener("mousedown", clickOutsideAdmin);

    return () => {
      document.removeEventListener("mousedown", clickOutsideAdmin);
    };
  }, [admin]);

  useEffect(() => {
    document.addEventListener("mousedown", clickOutsideNotify);

    return () => {
      document.removeEventListener("mousedown", clickOutsideNotify);
    };
  }, [notify]);

  const updateReadStatus = (status, notification_id, quesId, userId) => {
    if (status===false) {
    const token = localStorage.getItem("accessToken");
    (async () => {
      try {
        const res = await call(
          "post",
          `/notification-read-update`,
          null,
          {
            read_flag:true,
            notification_log_id: notification_id
        },
          token
        );
       if (res.data.success===true){
        if(quesId === null){
          window.location.href = `/list`
        }
        else{
          window.location.href = `/user/${quesId}/${userId}`
        }
        
       }
      } catch (err) {
        console.log(err);
      }
    })(); 
  }
    else{
      if(quesId === null){
        window.location.href = `/list`
      }
      else{
        window.location.href = `/user/${quesId}/${userId}`
      }

    }
    
  }

  const markAllRead = () => {
    const token = localStorage.getItem("accessToken");
    (async () => {
      try {
        const res = await call(
          "post",
          `/notification-read-update`,
          null,
          {
            read_flag:true,
            update_all:true
        },
          token
        );
       
        notificationList();
        setNotificationsCount();
      } catch (err) {
        console.log(err);
        
      }
    })();
  }

  return (
    <div>
      <div className={close ? "side_bar_wrapper" : "side-bar-active"}>
        <Sidebar />
      </div>

      <header className="dashboard_header">
        <nav className="header_nav d-flex justify-content-between align-items-center">
          <div className="dashboard_title d-flex align-items-center">
            <h1>{props.title}</h1>
          </div>
          <div className="header_menu_main">
            <ul className="header_menu d-flex align-items-center">
              <li className="header_menu_item">
                <div ref={node2} className="bell-icon">
                  <span className="new-notification" onClick={() => openDiv()}>
                    <img src={bell} alt="" />
                    {notificationsCount?.data === 0 || notificationsCount?.data===null ? <></> : notificationsCount?.data? <span className="button__badge">{notificationsCount?.data}</span>:<></>}
                  </span>
                  <div
                    ref={node2}
                    className={
                      notify ? "notification-close" : "notification-open"
                    }
                  >
                    <div
                      style={{ width: "440px" }}
                      className="notification-box"
                    >
                      <div className="d-flex justify-content-between top">
                        <div className="heading">
                          <h5>
                            Notifications
                            <Link to="/notificationsList">
                              <span className="action-cell">
                                <span
                                  style={{
                                    cursor: "pointer",
                                    color: "#0d9edf",
                                  }}
                                >
                                  <u>Show all</u>
                                </span>
                              </span>
                            </Link>
                          </h5>
                        </div>
                        <div className="read">
                          <h5 onClick={markAllRead}>
                            Mark all as read <CheckCircleOutlineRoundedIcon />
                          </h5>
                        </div>
                      </div>
                      {loading ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            paddingBottom: "20px",
                          }}
                        >
                          <b>Loading...</b>
                        </div>
                      ) : (
                        <div className="box">
                          {notificationsList?.data?.map((item, index) => {
                            return (
                              <div className="user">
                               {item.read_flag ? <></>: <div className="active"></div>}
                                <div className="img">
                                  <App
                                    imageURL={item.user_profile_image}
                                    width="40px"
                                    height="40px"
                                  />
                                </div>
                                <div className="content">
                                {/* <a href={`/user/${item.question_id}/${item.sender_user_id}`} style={{backgroundColor: "#e9ecef"}}> */}
                                <span onClick={() => {updateReadStatus(item.read_flag, item.id, item.question_id,item.question_owner_id )}} style={{backgroundColor: "#e9ecef"}}>
  <div className="hover-item">
                                  <p>
                                    <b>{item.notification_title} </b>
                                  </p>
                                  <p>{item.notification_body} </p>
	</div>
</span>
                                  <div className="day">
                                    <span>
                                      {time_ago(new Date(item?.createdAt))} at{" "}
                                      {new Date(item?.createdAt)
                                        .toLocaleTimeString("en-US", {
                                          hour: "2-digit",
                                          minute: "2-digit",
                                        })
                                        .replace(/ /g, " ")}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </li>
              <li ref={node1} className="header_menu_item">
                <span onClick={() => adminClick()}>
                  <span className="profile-area-row d-flex align-items-center">
                    <span className="user-img">
                      <App
                        imageURL={user?.data?.profile_image}
                        alt="profile_pic"
                      />
                    </span>
                    <span className="user-name">
                      <p className="name">
                        {user?.data?.screen_name}
                        <span>
                          {" "}
                          <ArrowDropDownIcon />
                        </span>
                      </p>
                    </span>
                  </span>
                </span>
                <span className={admin ? "d-none" : "d-block"}>
                  <div className="admin">
                    <ul>
                      <li>
                        <Link to={"/account"}>Account Settings</Link>
                      </li>
                      <li>
                        <Link to={"/about"}>About Us</Link>
                      </li>
                      <li>
                        <Link to={"/contact"}>Contact Us</Link>
                      </li>
                      <li>
                        <Link to={"/faq"}>FAQs</Link>
                      </li>
                      <li>
                        <Link to={"/legal"}>Legal</Link>
                      </li>
                      <li>
                        <Link to={"/login"} onClick={logout}>
                          Logout
                        </Link>
                      </li>
                    </ul>
                  </div>
                </span>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </div>
  );
}
