import React, { createContext, useEffect, useReducer } from 'react'
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import { authApi } from '../api/authAPI'
import { call } from '../utils/apiCall'

const initialState = {
    isAuthenticated: "",
    isInitialised: false,
    user: null,
}

const asyncExample = async(accessToken) => {
    let data=false;
    try {
        let res = await call("get", "/verify-token", null, null,accessToken)
       data=res.data.success;
      } catch (err) {
          console.log(err)
      }
      return data;
}


const isValidToken = async(accessToken) => {
    if (!accessToken) {
        return false
    }
       

  const globalData = await asyncExample(accessToken);
   return globalData;
      
}
const setSession = (accessToken, user) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken)
        localStorage.setItem('user', user)
    } else {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('user')
        delete axios.defaults.headers.common.Authorization
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user } = action.payload
            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
            }
        }
        case 'LOGIN': {
            const { user } = action.payload
            console.log(user)
            localStorage.setItem('user', JSON.stringify(user))
            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    logout: () => {},
    register: () => Promise.resolve(),
    updateDetails: () => Promise.resolve(),
    forgotPassword: () => Promise.resolve(),
    resetPassword: () => Promise.resolve(),
})

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const login = async (email, password,type) => {
        const user = await authApi.login(email, password,type)

        dispatch({
            type: 'LOGIN',
            payload: {
                user,
            },
        })
    }

    const register = async (formdata) => {
        await authApi.register(formdata)
    }

    const updateDetails = async (formdata) => {
       const user = await authApi.updateDetails(formdata)
//console.log(updatedUser.data)
       dispatch({
        type: 'LOGIN',
        payload: {
            user,
        },
    })
}
    

    const logout = () => {
        setSession(null, null)
        dispatch({ type: 'LOGOUT' })
    }

    const expirytoken = () => {
       
        dispatch({
            type: 'INIT',
            payload: {
                isAuthenticated: false,
                user: null,
            },
        })
    }

    const forgotPassword = async (email) => {
        await authApi.forgotPassword(email)
    }

    const resetPassword = async (email, code, password) => {
        await authApi.resetPassword(email, code, password)
    }
    useEffect(() => {
        console.log("raj")
        ;(async () => {
            try {
                const accessToken = window.localStorage.getItem('accessToken')
                let user = window.localStorage.getItem('user')
                const global = await isValidToken(accessToken);
                console.log(accessToken)
                console.log(global)
                if (accessToken && global) {
                    setSession(accessToken, user)
                    //TODO: Add api to set user in context
                    user = JSON.parse(user)
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: true,
                            user,
                        },
                    })
                } else {
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    })
                }
            } catch (err) {
                console.error(err, 'error')
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                })
            }
        })()
    }, [])

    // if (!state.isInitialised) {
    //     return <MatxLoading />
    // }
    console.log(initialState)
console.log(state)
    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                logout,
                expirytoken,
                register,
                updateDetails,
                forgotPassword,
                resetPassword,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
