import React, { useState, useEffect } from 'react';
import logo from '../assets/img/logo.png';
import { call } from '../utils/apiCall'
import { useParams } from 'react-router-dom';

export default function EmailActivation() {

  const [responsePath, setResponseMessage] = useState('');
  //const token = localStorage.getItem("accessToken"); 
  const { id, token } = useParams();

  useEffect(() => {

    let userData = { user_id: id };
    (async () => {
      try {
        const res = await call(
          'patch',
          '/verify-email',
          null,
          userData,
          token
        )
        setResponseMessage(res.data.message)


      } catch (err) {
        console.log(err?.response?.data?.message)
      }
    })()

  }
    , []);


  return (
    <div>
      <div className="limiter">
        <div className="container-login100">

          <div className="wrap-login100">
            <form className="login100-form validate-form">
              <div >
                <span className="login100-form-title"> Email Activation </span>
                <p className="mt-3">Thanks for the confirming or verifying your new Email id. {responsePath}</p>

              </div>
              <div className="text-center mt-4 p-b-20">
              </div>

            </form>

            <div className="login100-more" >
              <div className="logo m-4 ">
                <a href="/" >
                  <img src={logo} className="img-fluid" alt='img' />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
