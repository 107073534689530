import { useState, useEffect, useContext } from "react";
import Sidebar from "../../../components/layout/Sidebar";
import Header from "../../../components/layout/Header";
import { Link, useParams, useHistory } from "react-router-dom";
import map from "../../../assets/img/location-icon.svg";
import gptPic from "../../../assets/img/gpt-pic.jpg"
import respond from "../../../assets/img/respond-icon.svg";
import userIcon from "../../../assets/img/user-icon.svg";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import date from "../../../assets/img/calender-icon.svg";
import delIcon from "../../../assets/img/delete-icon.svg";
import Form from "react-bootstrap/Form";
import { call } from "../../../utils/apiCall";
import { Modal } from "react-bootstrap";
import TablePaginationWrapper from "../../../components/TablePaginationWrapper";
import App from "../../../components/imageverify";
import AuthContext from "../../../contexts/AuthContext";
import Respond from "./RespondofResponse";

const User = (props) => {
  // const [replyMessage, setReplyMessage] = useState(true);
  const [questionResponse, setQuestionResponse] = useState("");
  const [responseofResponse, setResponseofResponse] = useState("");
  const [responseData, setResponseData] = useState("");
  const [errorResponse, setErrorResponse] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenPoints, setIsOpenPoints] = useState(false);
  const [isOpenQuestion, setIsOpenQuestion] = useState(false);
  const [isSpam, setIsSpam] = useState(false);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [pagination, setPagination] = useState("");
  const [image, setImage] = useState(null);
  const [filename, setFilename] = useState(null);
  const [status, setquestionStatus] = useState("");
  const [status1, setquestionStatus1] = useState("");
  const [spamId, setspamId] = useState("");
  const [name, setName] = useState("");
  const [otherQuestion, setOtherQuestion] = useState("");
  const [otherResponse, setOtherResponse] = useState("");
  const [questionResponseError, setQuestionResponseError] = useState("");
  const [imageAnswer, setImageAnswer] = useState(null);
  const [fileAnswer, setFileAnswer] = useState(null);
  const [spamType, setSpamType] = useState(null);
  const [isViewResponse, setIsViewResponse] = useState(false);
  const [newList, setnewList] = useState(false);
  const [updatedPoints, setUpdatedPoints] = useState(false);
  const [totalDistributedPoints, setTotalDistributedPoints] = useState()
  const [updateList, setUpdateList] = useState(false)

  let { quesid, id } = useParams();

  const newId = parseInt(id);
  const newquesId = parseInt(quesid);

  // const openModal = () => {
  //   setIsOpenPoints(true)
  // }
  // const closeModal = () => {
  //   setIsOpen(false)
  //   setImage(null)
  //   setFilename("")
  // }

  const openStatusModal = () => {
    setIsOpenQuestion(true);
  };

  const closeStatusModal = () => {
    setIsOpenQuestion(false);
    setquestionStatus("");
  };

  const openViewResponseModal = () => {
    setIsViewResponse(true);
  };

  const closeViewResponseModal = () => {
    setIsViewResponse(false);
  };

  // const openSpamModal = () => {
  //   setIsSpam(true)
  // }
  // const closeSpamModal = () => {
  //   setIsSpam(false)
  // }

  // const closeModalPoints = () => {
  //   setIsOpenPoints(false)

  // }
  // const resetError = () => {
  //   setErrorResponse("")

  // }
  const resetResponseError = () => {
    setQuestionResponseError("");
  };

  const NquestionResponse = (responseId) => {
    setQuestionResponse((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        question_response: prevState.data.question_response.map((item) =>
          item.question_response_id === responseId
            ? {
                ...item,
                spam_flage: 1,
              }
            : item
        ),
      },
    }));
  };

  const OtherQuesResponse1 = (responseId) => {
    let updatedList = otherQuestion.map((item) =>
      item.question_response_id === responseId
        ? {
            ...item,
            spam_flage: 1,
          }
        : item
    );
    setOtherQuestion(updatedList);
  };

  const OtherQuesResponse2 = (responseId, count) => {
    let updatedList = otherQuestion.map((item) =>
      item.question_response_id === responseId
        ? {
            ...item,
            upvote_flage: 1,
            upvote_count: count,
          }
        : item
    );
    setOtherQuestion(updatedList);
  };

  const OtherQuesResponse3 = (responseId, count) => {
    let updatedList = otherQuestion.map((item) =>
      item.question_response_id === responseId
        ? {
            ...item,
            upvote_flage: 0,
            upvote_count: count,
          }
        : item
    );
    setOtherQuestion(updatedList);
  };

  const upvoteMyQues = (responseId, count) => {
    setQuestionResponse((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        question_response: prevState.data.question_response.map((item) =>
          item.question_response_id === responseId
            ? {
                ...item,
                upvote_flage: 1,
                upvote_count: count,
              }
            : item
        ),
      },
    }));
  };

  const removeVoteMyQues = (responseId, count) => {
    setQuestionResponse((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        question_response: prevState.data.question_response.map((item) =>
          item.question_response_id === responseId
            ? {
                ...item,
                upvote_flage: 0,
                upvote_count: count,
              }
            : item
        ),
      },
    }));
  };

  const userData = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    if (props.location && props.location.data) {
      setName(props.location.data);
    }
    let token = localStorage.getItem("accessToken");
    (async () => {
      try {
        const res = await call(
          "get",
          `/get-my-question-responses?questionId=${quesid}&page=${page}&size=${size}`,
          null,
          null,
          token
        );
        setQuestionResponse(res.data);
        setquestionStatus1(res.data.data.question_status);
        setTotalDistributedPoints(res?.data?.data?.total_distribution_points)
        setPagination(res.data.page_count);
        if (res.data.data.question_response === null) {
          setOtherQuestion([]);
        } else setOtherQuestion(res.data.data.question_response);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [page, size, newList, updatedPoints, updateList]);

  const ViewAllResponse = (e) => {
    let data = {
      point: "50",
      point_type: "3",
      question_id: quesid,
      point_sub_type: "view_response",
    };
    console.log(data);
    let token = localStorage.getItem("accessToken");
    (async () => {
      try {
        const res = await call("post", "/add-user-points", null, data, token);
        console.log(res.data);
        setnewList(true);
        closeViewResponseModal();
      } catch (err) {
        console.log(err);
      }
    })();
  };

  // const responofrespond = (id) => {

  //   setIsOpen(true)
  //   console.log(id)
  //   let token = localStorage.getItem("accessToken");
  //   (async () => {
  //     try {
  //       const res = await call(
  //         'get',
  //         `/get-respond-of-response?question_response_id=${id}&page=1&size=20`,
  //         null,
  //         null,
  //         token
  //       )
  //       setResponseofResponse(res.data)
  //       setResponseData("")
  //     } catch (err) {
  //       console.log(err)
  //     }
  //   })()

  // }

  // const handleSubmit = (data, feedbackType, responseId, count, quesType) => {
  //   let token = localStorage.getItem("accessToken");
  //   (async () => {
  //     try {
  //       const res = await call(
  //         'post',
  //         '/add-question-response-feedback',
  //         null,
  //         data,
  //         token
  //       )
  //       console.log(res.data)
  //       if (feedbackType === "spam" && quesType==="my_question") {
  //         setQuestionResponse(prevState => ({
  //           ...prevState,
  //           data: {
  //             ...prevState.data,
  //             question_response: prevState.data.question_response.map((item) =>
  //               item.question_response_id === responseId
  //                 ? {
  //                   ...item,
  //                   spam_flage: 1
  //                 }
  //                 : item

  //             )
  //           }
  //         }))
  //         closeSpamModal();
  //       }
  //      else if (feedbackType === "spam" && quesType==="other_question") {
  //        let updatedList = otherQuestion.map((item) =>
  //        item.question_response_id === responseId
  //          ? {
  //            ...item,
  //            spam_flage: 1
  //          }
  //          : item

  //      )
  //      setOtherQuestion(updatedList)
  //         closeSpamModal();
  //       }
  //       else if (feedbackType === "respond") {
  //         setImage(null)
  //         setFilename("")
  //         console.log(res.data.data?.question_response[0].question_response_id);
  //         responofrespond(res.data.data?.question_response[0].question_response_id)
  //       }

  //       else if (feedbackType === "upvote" && quesType==="my_question") {
  //         setQuestionResponse(prevState => ({
  //           ...prevState,
  //           data: {
  //             ...prevState.data,
  //             question_response: prevState.data.question_response.map((item) =>
  //               item.question_response_id === responseId
  //                 ? {
  //                   ...item,
  //                   upvote_flage: 1,
  //                   upvote_count: count
  //                 }
  //                 : item

  //             )
  //           }
  //         }))
  //       }

  //       else if (feedbackType === "upvote" && quesType==="other_question") {
  //         let updatedList = otherQuestion.map((item) =>
  //         item.question_response_id === responseId
  //           ? {
  //             ...item,
  //             upvote_flage: 1,
  //             upvote_count: count
  //           }
  //           : item

  //       )
  //       setOtherQuestion(updatedList);
  //       }

  //       else if (feedbackType === "remove_upvote" && quesType==="my_question") {
  //         setQuestionResponse(prevState => ({
  //           ...prevState,
  //           data: {
  //             ...prevState.data,
  //             question_response: prevState.data.question_response.map((item) =>
  //               item.question_response_id === responseId
  //                 ? {
  //                   ...item,
  //                   upvote_flage: 0,
  //                   upvote_count: count
  //                 }
  //                 : item

  //             )
  //           }
  //         }))
  //       }
  //       else if (feedbackType === "remove_upvote" && quesType==="other_question") {
  //         let updatedList = otherQuestion.map((item) =>
  //         item.question_response_id === responseId
  //           ? {
  //             ...item,
  //             upvote_flage: 0,
  //             upvote_count: count
  //           }
  //           : item

  //       )
  //       setOtherQuestion(updatedList)
  //       }

  //     } catch (err) {
  //       console.log(err)
  //     }
  //   })()

  // }

  // const responsefeedback = (responseId, feedbackType, count, quesType) => {

  //   const data1 = new FormData()
  //   data1.append('response_id', responseId)
  //   data1.append('feedback_type', feedbackType)

  //   if (feedbackType === "spam") {
  //     handleSubmit(data1, feedbackType, responseId, null, quesType)
  //   }

  //   else if (feedbackType === "respond") {
  //     data1.append('feedback_text', responseData)
  //     data1.append('attachment_file', image)
  //     if (checkValidation()) {
  //       handleSubmit(data1, feedbackType, responseId, null, null)
  //     }
  //   }

  //   else if (feedbackType === "upvote") {
  //     let upVote_count = count + 1;
  //     handleSubmit(data1, feedbackType, responseId, upVote_count, quesType)
  //   }

  //   else if (feedbackType === "remove_upvote") {
  //     let upVote_count = count - 1;
  //     handleSubmit(data1, feedbackType, responseId, upVote_count, quesType)
  //   }
  // }

  console.log(questionResponse.data);
  console.log(responseofResponse);
  console.log(otherQuestion);

  const closeQuestion = (e) => {
    let quesData = {
      question_id: newquesId,
      question_status: status,
    };
    console.log(quesData);
    let token = localStorage.getItem("accessToken");
    (async () => {
      try {
        const res = await call(
          "patch",
          `/update-question-status`,
          null,
          quesData,
          token
        );
        console.log(res.data);
        if (status === "closed") {
          window.location.reload();
        } else if (status === "open") {
          alert("New question Copy successfully. Go to My Questions");
          history.push({
            pathname: "/question",
          });
        }
      } catch (err) {
        console.log(err);
      }
    })();
  };

  const checkResponseValidation = () => {
    let formIsValid = true;
    let firstSpace = /^[^\s].*/;
    if (!firstSpace.test(otherResponse)) {
      setQuestionResponseError("Invalid response text!");
      formIsValid = false;
    }
    if (otherResponse.length === 0) {
      setQuestionResponseError("Please enter your reply.");
      formIsValid = false;
    }

    return formIsValid;
  };

  const responseQuestion = (questionId) => {
    if (checkResponseValidation()) {
      const dataQuestion = new FormData();
      dataQuestion.append("question_id", questionId);
      dataQuestion.append("response_text", otherResponse);
      dataQuestion.append("attachment_file", imageAnswer);

      let token = localStorage.getItem("accessToken");
      (async () => {
        try {
          const res = await call(
            "post",
            `/add-question-response`,
            null,
            dataQuestion,
            token
          );
          if(res.data.success===true){
          console.log(res.data);
          setOtherResponse("");
          setImageAnswer(null);
          setFileAnswer("");
          setOtherQuestion([
            ...res.data.data.question_response,
            ...otherQuestion,
          ]);}
          else{(alert(res.data.message))
          window.location.reload()}
        } catch (err) {
          console.log(err);
        }
      })();
    }
  };

  console.log(otherQuestion);

  const onChange = (e) => {
    setResponseData(e.target.value);
  };
  const onChangeAnswer = (e) => {
    setOtherResponse(e.target.value);
  };
  const onChangeSize = (e) => {
    if (page !== pagination || page < pagination) {
      setSize(e.target.value);
    } else if (page === 1) {
      setSize(e.target.value);
    } else if (page === pagination) {
      setSize(5);
    }
  };

  const onClickNext = () => {
    setPage(page + 1);
  };

  const onClickBack = () => {
    setPage(page - 1);
  };

  const statushandle = (e) => {
    if (e.target.value) {
      setquestionStatus(e.target.value);
      openStatusModal();
    }
  };

  const closedQuestion = () => {
    setquestionStatus("open");
    openStatusModal();
  };

  // const spamhandle = (value, quesType) => {
  //   setspamId(value)
  //   setSpamType(quesType)
  //   openSpamModal();
  // }

  const GoBack = (e) => {
    e.preventDefault();
    history.push({
      pathname: "/question",
      data: name,
    });
  };

  // const spamValue = (responseId, feedbackType) => e => {
  // console.log(feedbackType)
  // console.log(responseId)
  // setQuestionResponse(prevState => ({
  //   ...prevState,
  //   data: {
  //      ...prevState.data,
  //      question_response: prevState.data.question_response.map((item) =>
  //      item.question_response_id === responseId
  //      ? {
  //       ...item,
  //       spam_flage: 1
  //      }
  //      :item

  //      )
  //   }
  // }))
  // // let allanswers = {...questionResponse};
  // console.log( questionResponse)
  // // let oneanwerobj = {...allanswers.data};

  // // let answertexts = {...oneanwerobj.question_response}

  // //  let answertexts1 = {...answertexts[index]}

  // //  answertexts1.spam_flage = 1 ;

  // //  answertexts[index] = answertexts1
  // //   oneanwerobj.question_response = answertexts
  // //   allanswers.data = oneanwerobj
  // //   setQuestionResponse(allanswers)
  // // oneanwerobj[index] = answertexts;
  // // allanswers[key] = oneanwerobj

  // // setAnswerTextsData(allanswers);
  // }

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(event.target.files[0]);
      setFilename(event.target.files[0].name);
      event.target.value = "";
    }
  };

  const onAnswerImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImageAnswer(event.target.files[0]);
      setFileAnswer(event.target.files[0].name);
      event.target.value = "";
    }
  };

  const checkValidation = () => {
    let formIsValid = true;
    let firstSpace = /^[^\s].*/;
    if (!firstSpace.test(responseData)) {
      setErrorResponse("Invalid reply text");
      formIsValid = false;
    }
    if (responseData.length === 0) {
      setErrorResponse("Please enter your reply.");
      formIsValid = false;
    }

    return formIsValid;
  };

  const truncate = (str) => {
    var dotIndex = str.lastIndexOf(".");
    return str.length > 12
      ? str.substring(0, 10) + "..." + str.substring(dotIndex)
      : str;
  };

  const clearImage = () => {
    setImage(null);
    setFilename("");
    setImageAnswer(null);
    setFileAnswer("");
  };

  console.log(imageAnswer);
  console.log(fileAnswer);
  return (
    <>
      <main className="page_wrapper">
        <section className="dashboard_section d-flex">
          <div>
            <Sidebar />
          </div>

          <section className="dashboard_main_section">
            <Header title="Questions" />
            <section className="dasboard_main_area list-experts-area questions-main-section single-question">
              <div className="back-btn-main">
                <span onClick={GoBack}>
                  <KeyboardBackspaceIcon /> Back
                </span>
              </div>

              {userData &&
              userData.user &&
              userData.user.data &&
              questionResponse &&
              questionResponse.data ? (
                newId === userData.user.data.id ? (
                  <div>
                    <div className="my-question-section single-question-section">
                      <div className="my-question-container">
                        <div className="my-question-item">
                          <div className="myquestion-row row gx-0">
                            <div className="question-label myquestion-col">
                              <p>Question :</p>
                            </div>
                            <div className="my-question myquestion-col ">
                              <p>{questionResponse.data.question_text}</p>
                            </div>
                            <div className="my-question-status myquestion-cols open  text-end">
                              <div className="select_container">
                                <div className="dropholder">
                                  <Form.Select
                                    aria-label="Default select example"
                                    className="dropdown"
                                    onChange={statushandle}
                                    value={status}
                                  >
                                    {questionResponse.data.question_status ===
                                    "open" ? (
                                      <>
                                        <option value="">Open</option>
                                        <option value="closed">Close</option>
                                      </>
                                    ) : (
                                      <>
                                        <option value="">Closed</option>
                                        <option value="open">Open</option>
                                      </>
                                    )}
                                  </Form.Select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="myquestion-details row">
                            <div className="question-details-col location ">
                              <p>
                                <img src={date} alt="" />
                                {new Date(questionResponse.data.createdAt)
                                  .toLocaleDateString("en-US", {
                                    day: "numeric",
                                    month: "short",
                                    year: "numeric",
                                  })
                                  .replace(/ /g, " ")}
                              </p>
                            </div>
                            <div className="question-details-col date ">
                              <p>
                                <img src={map} alt="" />
                                {questionResponse.data.location_name}
                              </p>
                            </div>
                            <div className="question-details-col response-counter  text-end">
                              <p>
                                {questionResponse.data.response_count} Total
                                Responses
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <section className="responses-wrapper">
                      {questionResponse.data.question_response?.map(
                        (item, index) => {       
                          return (
                            <div className="response-item" key={index}>
                              <div className="response-content">
                                {/* <div className="response-details row"> */}
                                {/* <div className="response-user col-12 col-sm-9"> */}
                                <p>{item.response_text}</p>
                                {/* </div> */}
                                {/* <div style={{textAlign:"right"}}className="response-date col-12 col-sm-3">
                        <p>
                           {new Date(item.createdAt)
                             .toLocaleDateString('en-US', {
                              day: 'numeric', month: 'long', year: 'numeric'
                            }).replace(/ /g, ' ')}
                          </p>
                          </div> */}
                                {/* </div> */}

                                <br />
                                {item.doc_path && 
                                  (<div style={{ width: "300px" }}>
                                    <App
                                      imageURL={item.doc_path}
                                      width="100%"
                                    />
                                  </div>)}
                              </div>
                              <div className="response-details row">
                                <div className="response-user col-12 col-md-6 col-lg-6">
                                  <span className="response-img">
                                    {
                                      item.response_user_id ? 
                                      <App
                                      imageURL={item.profile_image}
                                      width="25px"
                                      height="25px"
                                    /> :
                                    <img src={gptPic} alt="" width= "25px" height="25px"/>
                                    }
                                  </span>
                                  <span
                                    style={{ paddingLeft: "8px" }}
                                    className="response-user-name"
                                  >
                                    {item.response_user_id ? <Link>{item.screen_name}</Link> : <Link>{"Chat GPT"}</Link>}
                                  </span>
                                  {
                                    item.response_user_id ? 
                                    <span className="response-user-name">
                                    <Link>Silver</Link>
                                  </span> : 
                                  <></>
                                  }
                                  <span className="response-date">
                                    {" "}
                                    {new Date(item.createdAt)
                                      .toLocaleDateString("en-US", {
                                        day: "numeric",
                                        month: "short",
                                        year: "numeric",
                                      })
                                      .replace(/ /g, " ")}
                                  </span>
                                </div>

                                <div className="response-ctrls col-12 col-md-6 col-lg-6">
                                  <Respond
                                    item={item}
                                    NquestionResponse={NquestionResponse}
                                    OtherQuesResponse2={OtherQuesResponse2}
                                    upvoteMyQues={upvoteMyQues}
                                    type="myQuestion"
                                    OtherQuesResponse1={OtherQuesResponse1}
                                    OtherQuesResponse3={OtherQuesResponse3}
                                    removeVoteMyQues={removeVoteMyQues}
                                    setUpdatedPoints={setUpdatedPoints}
                                    status={status1}
                                    totalDistributedPoints={totalDistributedPoints}
                                    updateList = {updateList}
                                    setUpdateList = {setUpdateList}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </section>
                    <TablePaginationWrapper
                      onChange={onChangeSize}
                      page={page}
                      pagination={pagination}
                      onClickNext={onClickNext}
                      onClickBack={onClickBack}
                    />
                  </div>
                ) : (
                  <div>
                    <div className="my-question-section single-question-section">
                      <div className="my-question-container">
                        <div className="my-question-item">
                          <div className="myquestion-row row gx-0">
                            <div className="question-label myquestion-col">
                              <p>Question :</p>
                            </div>
                            <div className="my-question myquestion-col ">
                              <p>{questionResponse.data.question_text}</p>
                            </div>
                            <div className="my-question-status myquestion-cols open  text-end">
                              <div className="select_container">
                                {questionResponse.data.question_status ===
                                "open" ? (
                                  <p>Open</p>
                                ) : (
                                  <p style={{ color: "#FF0000" }}>Closed</p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="myquestion-details row">
                            <div className="question-details-col location ">
                              <p>
                                <img src={date} alt="" />
                                {new Date(questionResponse.data.updatedAt)
                                  .toLocaleDateString("en-US", {
                                    day: "numeric",
                                    month: "short",
                                    year: "numeric",
                                  })
                                  .replace(/ /g, " ")}
                              </p>
                            </div>
                            <div className="question-details-col date d-flex ">
                              <p>
                                <img src={map} alt="" />
                                {questionResponse.data.location_name}
                              </p>
                              <p className="tool ms-4">
                                <img src={respond} alt="" />
                                {questionResponse?.data?.last_response_date ===
                                null
                                  ? "NA"
                                  : new Date(
                                      questionResponse?.data?.last_response_date
                                    )
                                      .toLocaleDateString("en-US", {
                                        day: "numeric",
                                        month: "short",
                                        year: "numeric",
                                      })
                                      .replace(/ /g, " ")}

                                <span className="tool_tip_main">
                                  <span className="tool_tip">
                                    Last Response Date
                                  </span>
                                </span>
                              </p>
                          
                              <p style={{marginLeft:"1.5rem"}}>
                              <img className="userIcon" src={userIcon} alt="" />{questionResponse.data.screen_name}
                               
                              </p>
                           
                            </div>
                            <div className="question-details-col response-counter  text-end">
                              <p>
                                {questionResponse.data.response_count} Total
                                Responses
                              </p>
                            </div>
                          </div>

                          {questionResponse.data.question_status ===
                          "closed" ? (
                            <div className="mt-3 text-end">
                              <Link
                                className="btn btn-outline p-0 px-3 w-auto h-auto"
                                onClick={closedQuestion}
                              >
                                100 points to open question
                              </Link>
                              {questionResponse.data.view_response === true ? (
                                <div></div>
                              ) : (
                                <Link
                                  onClick={openViewResponseModal}
                                  className="btn btn-xl p-2 px-3 ms-4"
                                >
                                  50 points to see all responses
                                </Link>
                              )}{" "}
                            </div>
                          ) : questionResponse.data.view_response === false ? (
                            <div className="mt-3 text-end">
                              <Link
                                onClick={openViewResponseModal}
                                className="btn btn-xl p-2 px-3"
                              >
                                50 points to see all responses
                              </Link>
                              {/* {questionResponse.data.question_response===null ?
                <Link className="btn btn-xl p-2 px-3" >50 points to see all responses</Link>:<div></div>} */}
                            </div>
                          ) : (
                            <div></div>
                          )}
                          {/* <Link to={'/open'} className="btn btn-xl p-2 px-3 shadow-none text-capitalize">50 points to see
                        all responses</Link> */}
                          {questionResponse.data.question_status ===
                          "closed" ? (
                            <div className="respond-text-area account "><p style={{color:"#ff0000"}}>The question has been closed new responses are not allowed.</p></div>
                          ) : (
                            <div className="respond-text-area account ">
                              <Form>
                                <Form.Group
                                  className="mt-3 position-relative"
                                  controlId="exampleForm.ControlTextarea1"
                                >
                                  <Form.Control
                                    as="textarea"
                                    rows={3}
                                    onChange={onChangeAnswer}
                                    onClick={resetResponseError}
                                    value={otherResponse}
                                    placeholder = "Respond for Chance to Earn Points"
                                  />

                                  <span className="up-load-btn">
                                    <form>
                                      <label className="d-flex justify-content-end">
                                        <AttachFileIcon sx={{ fontSize: 20 }} />
                                        Add Picture
                                        <input
                                          type="file"
                                          size="lg"
                                          style={{ display: "none" }}
                                          onChange={onAnswerImage}
                                        />
                                      </label>
                                    </form>

                                    {fileAnswer && (
                                      <span
                                        style={{
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {truncate(fileAnswer)}
                                        <img
                                          src={delIcon}
                                          onClick={clearImage}
                                        />
                                      </span>
                                    )}
                                  </span>
                                </Form.Group>
                              </Form>
                              {questionResponseError && (
                                <label className="label-input100 text-danger">
                                  {questionResponseError}
                                </label>
                              )}
                              <div className="row submit-btn single-question-submit">
                                <div className="text-left small">
                                  <button
                                    className="btn btn-xl shadow-none"
                                    onClick={() =>
                                      responseQuestion(questionResponse.data.id)
                                    }
                                  >
                                    Send
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}

                          <div>
                            <div className="border mt-4 mb-2"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-white mt-2 p-3">
                      {otherQuestion &&
                        otherQuestion?.map((item, index) => {
                          return (
                            <div>
                              {index < size && (
                                <div className="response-item" key={index}>
                                  <div className="response-content">
                                    <p>{item.response_text}</p>
                                    <br />
                                    {item.doc_path && (
                                      <div style={{ width: "300px" }}>
                                        <App
                                          imageURL={item.doc_path}
                                          width="100%"
                                        />
                                      </div>
                                    )}
                                  </div>
                                  <div className="response-details row">
                                    <div className="response-user col-12 col-md-6 col-lg-6">
                                      <span className="response-img">
                                          {
                                            item.response_user_id ? 
                                            <App
                                            imageURL={item.profile_image}
                                            width="25px"
                                            height="25px"
                                          /> :
                                          <img src={gptPic} alt="" width= "25px" height="25px"/>
                                          }
                                      </span>
                                      <span
                                        style={{ paddingLeft: "8px" }}
                                        className="response-user-name"
                                      >
                                        {item.response_user_id ? <Link>{item.screen_name}</Link> : <Link>{"Chat GPT"}</Link>}
                                      </span>
                                      {
                                        item.response_user_id ? 
                                        <span className="response-user-name">
                                        <Link>Silver</Link>
                                      </span> : 
                                      <></>
                                      }
                                      <span className="response-date">
                                        {new Date(item.createdAt)
                                          .toLocaleDateString("en-US", {
                                            day: "numeric",
                                            month: "short",
                                            year: "numeric",
                                          })
                                          .replace(/ /g, " ")}
                                      </span>
                                    </div>

                                    <div className="response-ctrls col-12 col-md-6 col-lg-6">
                                      {/* other questuons list resp was here */}
                                      <Respond
                                        item={item}
                                        NquestionResponse={NquestionResponse}
                                        OtherQuesResponse2={OtherQuesResponse2}
                                        upvoteMyQues={upvoteMyQues}
                                        type="otherQuestion"
                                        OtherQuesResponse1={OtherQuesResponse1}
                                        OtherQuesResponse3={OtherQuesResponse3}
                                        removeVoteMyQues={removeVoteMyQues}
                                        status={status1}
                                        totalDistributedPoints={totalDistributedPoints}
                                        updateList = {updateList}
                                        setUpdateList = {setUpdateList}
                                      />
                                    </div>
                                  </div>
                                  <div className="border mt-3 mb-2"></div>
                                </div>
                              )}
                            </div>
                          );
                        })}
                    </div>
                    <TablePaginationWrapper
                      onChange={onChangeSize}
                      page={page}
                      pagination={pagination}
                      onClickNext={onClickNext}
                      onClickBack={onClickBack}
                    />
                  </div>
                )
              ) : (
                ""
              )}
            </section>
          </section>
        </section>
      </main>

      {/* 
      <Modal
        show={isOpenPoints}
        onHide={closeModalPoints}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>

        </Modal.Header>

        <Modal.Body className="px-5 terms-1">
          <h5 style={{ color: "#000000", textAlign: "center" }}>Enter the point you want to give this response.</h5>
        </Modal.Body>
        <div style={{ display: "flex", justifyContent: "center", paddingBottom: "20px" }}>
          <div style={{ display: "flex", justifyContent: "center", paddingLeft: "40px", paddingRight: "40px", height: "50px", width: "120px", border: "2px solid black" }}><input style={{ textAlign: "center", border: "none" }} type="text" name="points" maxlength="3"></input></div>
        </div>
        <div style={{ display: "flex", justifyContent: "center", paddingBottom: "20px" }}>

          <button style={{ padding: "15px", borderRadius: "5px", width: "300px" }} className="btn-xl " >Submit</button></div>
      </Modal>
       */}
      <Modal
        show={isOpenQuestion}
        onHide={closeStatusModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body className="px-5 terms-1">
          <h5 style={{ color: "#000000", textAlign: "center" }}>
            Are you want to sure{" "}
            {status1 === "open" ? <span>close</span> : <span>open</span>} this
            question?
          </h5>
        </Modal.Body>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: "20px",
          }}
        >
          <div className="text-left small">
            <button className="btn btn-outline mx-3" onClick={closeStatusModal}>
              No{" "}
            </button>
            <button className="btn btn-xl shadow-none " onClick={closeQuestion}>
              Yes
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        show={isViewResponse}
        onHide={closeViewResponseModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body className="px-5 terms-1">
          <h5 style={{ color: "#000000", textAlign: "center" }}>
            Are you sure to spend 50 points to view all responses?
          </h5>
        </Modal.Body>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: "20px",
          }}
        >
          <div className="text-left small">
            <button
              className="btn btn-outline mx-3"
              onClick={closeViewResponseModal}
            >
              No{" "}
            </button>
            <button
              className="btn btn-xl shadow-none "
              onClick={ViewAllResponse}
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default User;
