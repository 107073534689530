import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { call } from "../../../utils/apiCall";
import Loader from "../../../components/Loaders/ComponentLoader";

const AccountNotifications = ({ data, update }) => {
  const [loading, setLoading] = useState(false);

  const handleUpdate = async (e) => {
    setLoading(true);
    let token = localStorage.getItem("accessToken");
    try {
      const res = await call(
        "patch",
        `/set-notification-setting`,
        null,
        { setOverall: e.target.checked ? 1 : 0 },
        token
      );
      console.log(res.data.data, "about us Data");
      update();
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const updateNotificationItems = async (e) => {
    setLoading(true);
    let token = localStorage.getItem("accessToken");
    try {
      const res = await call(
        "patch",
        `/set-notification-setting`,
        null,
        { notification_id: e.target.id },
        token
      );
      console.log(res.data.data, "about us Data");
      update();
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <div>
      {loading ? <Loader/>:null}
      <div className="tabs_pannel" id="tab3">
        <div className="notification">
          <p>
            Notification
            <Form>
              <Form.Check
                onChange={handleUpdate}
                checked={data?.overallNotification}
                isValid={true}
                type="switch"
                id="custom-switch"
              />
            </Form>
          </p>
          <div className="checkboxes ">
            <p>
              Show Notifications For<span></span>
            </p>
            {data?.settingsList?.notification_case?.map((item) => (
              <Form className="m-0">
                {["checkbox"].map((type) => (
                  <div key={`default-${type}`} className="mb-3">
                    <Form.Check
                      type={type}
                      disabled={!data?.overallNotification}
                      checked={item.isSelected}
                      onChange={updateNotificationItems}
                      id={item.notification_id}
                      label={item.name}
                    />
                  </div>
                ))}
              </Form>
            ))}
          </div>
          <div className="checkboxes ">
            <p>
              Setting<span></span>
            </p>
            {data?.settingsList?.notification_type?.map((item) => (
              <Form className="m-0">
                {["checkbox"].map((type) => (
                  <div key={`default-${type}`} className="mb-3">
                    <Form.Check
                      type={type}
                      disabled={!data?.overallNotification}
                      checked={item.isSelected}
                      onChange={updateNotificationItems}
                      id={item.notification_id}
                      label={item.name}
                    />
                  </div>
                ))}
              </Form>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountNotifications;
