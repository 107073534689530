import {useEffect, useState} from "react";
import Sidebar from "../../../components/layout/Sidebar";
import Header from "../../../components/layout/Header";
// import { Button } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { call } from '../../../utils/apiCall';
import Loader from "../../../components/Loaders/ComponentLoader";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

toast.configure()

const Buy = () => {

  const history = useHistory()

  const [point, setPoint] = useState()
  const [amount, setamount] = useState()
  const [pointRate, setPointRate] = useState()
  const [amountRate, setAmountRate] = useState()
  const [isLoading, setisLoading] = useState(false)

  function popupwindow(url, title, w, h) {
    var y = window.outerHeight / 2 + window.screenY - ( h / 2)
    var x = window.outerWidth / 2 + window.screenX - ( w / 2)
    return window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + y + ', left=' + x);
} 

useEffect ( () => {
  const token = localStorage.getItem("accessToken");
  (async () => {
    try {
      const res = await call("get", `/admin/get-point`, null, null, token);
      setPointRate(res?.data?.data[0]?.point);
      setAmountRate(res?.data?.data[0]?.amount);
      console.log(res.data.data);
    } catch (err) {
      console.log(err);
        }
  })();
},[])


  const paymentRedirect = (e) => {
    e.preventDefault();
    setisLoading(true)
    const token = localStorage.getItem("accessToken");
    
    let paymentData = {
      amount: parseInt(amount),
      os_type: "web"
    };
    if(amount < 1 || amount > 25){
      setisLoading(false)
      toast.error("The amount should be greater than or equal to $1 or less than or equal to $25", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
    }
    else{
      (async () => {
        try {
            const res = await call(
                'post',
                '/make-payment',
                null,
                paymentData,
                token
            )
            console.log(res.data)
            setisLoading(false)
            if(res.data?.forwardLink){
              var win = window.open(res.data?.forwardLink, "paypal", 600, 800)
              var timer = setInterval(function() { 
                if(win.closed) {
                    clearInterval(timer);
                    history.push('/points')
                }
            }, 1000);
            }
            if (res?.data?.success === false){
              alert(res?.data?.message)
            }
  //           var strWindowFeatures = "location=yes,height=570,width=520,scrollbars=yes,status=yes";
  // var URL = "https://www.linkedin.com/cws/share?mini=true&amp;url";
  // var win = window.open(URL, "_blank", strWindowFeatures);
        } catch (err) {
            console.log(err)
            setisLoading(false)
        }
      })()

    }
  }

  const onChange = (e) => {
    setPoint(e.target.value);
    setamount(e.target.value * (amountRate/pointRate))
  }

  return (
    <>
      <main className="page_wrapper">
        <section className="dashboard_section d-flex">
          <div>
            <Sidebar />
          </div>
          <section className="dashboard_main_section">
            <Header title="Points" />

            <section class="new-question-section dasboard_main_area points-area">
              <div class="dashboard_container">
                <div class="back-btn-main mb-5">
                  <Link to={"./points"}>
                    <KeyboardBackspaceIcon /> Buy Points
                  </Link>
                </div>
                <div class="new-question-from-wrapper redeem">
                
                  <form class="new-question-from" action="" onSubmit={paymentRedirect}>
                    <div class="field-wrapper position-relative">
                               {isLoading ? <Loader/> : null }
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="label-input100 ">
                          Enter Amount of Points to purchase
                        </Form.Label>
                        <Form.Control type="number" placeholder="Enter Points" min="5" onChange={onChange} required/>
                        <div className="text-center">
                          <p>or</p>
                        </div>
                        <Form.Label className="label-input100 ">
                          Amount
                        </Form.Label>
                        <Form.Control type="number" placeholder="Total Amount"  disabled value={amount}/>
                        <span>
                        ${amountRate} = {pointRate} points
                        </span>
                      </Form.Group>
                    </div>
                    <div class="submit-wrapper submit-main row mt-5">
                      <div class="text-left ">
                       <Link to="/points"> <button className="btn btn-outline">Cancel</button></Link>
                        <button
                         // to={"./bank"}
                         type="submit"
                          className="btn btn-xl text-center h-42 py-3"
                        >
                          Buy
                        </button>
                      </div>
                    </div>
                  </form> 
                </div>
              </div>
            </section>
          </section>
        </section>
      </main>
    </>
  );
};

export default Buy;
