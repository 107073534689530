import { useState, useEffect } from "react";
import Sidebar from "../../../components/layout/Sidebar";
import Header from "../../../components/layout/Header";
import cards from "../../../assets/img/cards.png";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { call } from "../../../utils/apiCall";

const Card = () => {
  //const [message, setMessage] = useState ();
  const [modeList, setModeList] = useState()
  const [cardData, setCardData] = useState({
    name: "",
    cardNumber: "",
    expiryDate: "",
    cvv: "",
    cardType:""
  })

  useEffect ( () => {
    const token = localStorage.getItem("accessToken");
    (async () => {
      try {
        const res = await call("get", `/get-payment-mode-list?page=1&size=5`, null, null, token);
        setModeList(res?.data?.data);
      } catch (err) {
        console.log(err);
      }
    })();
  },[])

 const AddCard = () => {
   // setIsLoading(true)
    const token = localStorage.getItem("accessToken");
    (async () => {
      try {
        const res = await call("post", `/add-card-details`, null, {
          name:cardData.name,
          card_number:cardData.cardNumber,
          cvv:cardData.cvv,
          expiry_date:cardData.expiryDate,
          payment_mode_id:cardData.cardType
  }, token);
        //setMessage(res?.data);
        alert(res?.data?.message);
      setCardData((prevState) => ({
      ...prevState,
      name: "",
    cardNumber: "",
    expiryDate: "",
    cvv: "",
    cardType:""
    }));
      } catch (err) {
        console.log(err);
     alert(err?.response?.data?.message)
      }
    })();
  };

  const onChange = (input) => (e) => {
    const { value } = e.target;
    setCardData((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  }

  return (
    <>
      <main className="page_wrapper">
        <section className="dashboard_section d-flex">
          <div>
            <Sidebar />
          </div>

          <section className="dashboard_main_section">
            <Header title="Points" />

            <section className="new-question-section dasboard_main_area points-area">
              <div className="dashboard_container">
                <div className="back-btn-main mb-5">
                  <Link to={"./points"}>
                    <KeyboardBackspaceIcon /> Add New Card
                  </Link>
                </div>
                <div className="cards-details">
                  <div className="cards">
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                        {modeList?.map((item,key) => {
                          return (  <FormControlLabel
                            value={item.id}
                            control={<Radio />}
                            label={item.payment_mode_name}
                            className="me-5"
                            onChange={onChange("cardType")}
                          />)
                        })}
                      </RadioGroup>
                    </FormControl>
                    <Form.Group
                      className="my-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="label-input100 ">
                        NAME ON CARD
                      </Form.Label>
                      <Form.Control type="text" placeholder="" onChange={onChange("name")} value={cardData.name}/>
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="label-input100 ">
                        CARD NUMBER
                      </Form.Label>
                      <Form.Control type="text" placeholder="" onChange={onChange("cardNumber")} value={cardData.cardNumber}/>
                    </Form.Group>
                    <div className="row">
                      <div className="col-sm-6">
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label className="label-input100 ">
                            EXPIRY DATE
                          </Form.Label>
                          <Form.Control type="text" placeholder="" onChange={onChange("expiryDate")} value={cardData.expiryDate}/>
                        </Form.Group>
                      </div>
                      <div className="col-sm-6">
                        <Form.Group
                          className="mb-3 cvv"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label className="label-input100 ">
                            CVV
                          </Form.Label>
                          <Form.Control type="text" placeholder="" onChange={onChange("cvv")} value={cardData.cvv}/>
                          <div className="cards-img">
                            <img src={cards} alt="img" />
                          </div>
                        </Form.Group>
                      </div>
                      <div className="mt-3">
                        <a className="btn btn-outline" href="/account">
                          Cancel
                        </a>
                        <button className="btn btn-xl" onClick={AddCard}>
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
            </section>
          </section>
        </section>
      </main>
    </>
  );
};



export default Card;
