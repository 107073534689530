import React, { Component } from "react";
// import Header from './header';
import Router from "../../router/router";
import "../../assets/css/style.css";

const Layout = () => {
  return (
    <div className="instructions">
      {/* <Header/> */}
      <Router />
    </div>
  );
};

export default Layout;
