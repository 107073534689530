import React, { useState, useEffect } from "react";
import icon from "../../../assets/img/new-que-icon.svg";
import my from "../../../assets/img/my-open-icon.svg";
import gaint from "../../../assets/img/points-gained-icon.svg";
import point from "../../../assets/img/points-bought-icon.svg";
import rec from "../../../assets/img/exp-req-recived-icon.svg";
import req from "../../../assets/img/req-acc-icon.svg";
import Sidebar from "../../../components/layout/Sidebar";
import Header from "../../../components/layout/Header";
import { call } from "../../../utils/apiCall";
import { useHistory } from "react-router-dom";

export default function Dashboard() {
  const history = useHistory()
  const [dashboardData, setDashboardData] = useState("");

  useEffect(() => {
    let token = localStorage.getItem("accessToken");

    if (token) {
      (async () => {
        try {
          const res = await call("get", "/user-dashboard", null, null, token);
          setDashboardData(res.data);
        } catch (err) {
          console.log(err);
        }
      })();
    }
  }, []);

  return (
    <div>
      <main className="page_wrapper">
        <section className="dashboard_section d-flex">
          <Sidebar />

          <section className="dashboard_main_section">
            <Header title="Dashboard" />

            <section className="dasboard_main_area">
              <div className="dashboard_container">
                <div className="dasboard_counter_main">
                  <div className="counter_title">
                    <h3>Questions Tile</h3>
                  </div>
                  <div className="row counter-card-row">
                    <div className="col-lg-6  counter-cards" onClick={() => history.push('/question')}>
                      <div className="inner-column">
                        <div className="counter-card-img card-inner-cols">
                          <img src={icon} alt="" />
                        </div>
                        <div className="counter-card-content card-inner-cols">
                          <div className="card-head-main d-flex justify-content-between ">
                            <div className="counter-label">
                              <h4>New questions</h4>
                            </div>
                            <div className="counter-filter">
                              <span>Last 3 days</span>
                            </div>
                          </div>
                          <div className="counter-val-main">
                            <p>{dashboardData.other_question_count}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6  counter-cards" onClick={() => history.push('/question')}>
                      <div className="inner-column">
                        <div className="counter-card-img card-inner-cols">
                          <img src={my} alt="" />
                        </div>
                        <div className="counter-card-content card-inner-cols">
                          <div className="card-head-main d-flex justify-content-between ">
                            <div className="counter-label">
                              <h4>My open questions</h4>
                            </div>
                          </div>
                          <div className="counter-val-main">
                            <p>{dashboardData.my_question_count}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dasboard_counter_main">
                  <div className="counter_title">
                    <h3>Points Tile</h3>
                  </div>
                  <div className="row counter-card-row">
                    <div className="col-lg-6  counter-cards" onClick={() => history.push('/points')}>
                      <div className="inner-column">
                        <div className="counter-card-img card-inner-cols">
                          <img src={gaint} alt="" />
                        </div>
                        <div className="counter-card-content card-inner-cols">
                          <div className="card-head-main d-flex justify-content-between ">
                            <div className="counter-label">
                              <h4>Points gained</h4>
                            </div>
                            <div className="counter-filter">
                              <span>Last 3 days</span>
                            </div>
                          </div>
                          <div className="counter-val-main">
                            <p>{dashboardData.points_gained}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6  counter-cards" onClick={() => history.push('/points')}>
                      <div className="inner-column">
                        <div className="counter-card-img card-inner-cols">
                          <img src={point} alt="" />
                        </div>
                        <div className="counter-card-content card-inner-cols">
                          <div className="card-head-main d-flex justify-content-between ">
                            <div className="counter-label">
                              <h4>Points bought</h4>
                            </div>
                            <div className="counter-filter">
                              <span>Last 3 days</span>
                            </div>
                          </div>
                          <div className="counter-val-main">
                            <p>{dashboardData.points_bought}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dasboard_counter_main">
                  <div className="counter_title">
                    <h3>Experts Tile</h3>
                  </div>
                  <div className="row counter-card-row">
                    <div className="col-lg-6  counter-cards" onClick={() => history.push('/list')}>
                      <div className="inner-column">
                        <div className="counter-card-img card-inner-cols">
                          <img src={rec} alt="" />
                        </div>
                        <div className="counter-card-content card-inner-cols">
                          <div className="card-head-main d-flex justify-content-between ">
                            <div className="counter-label">
                              <h4>Expert request recieved</h4>
                            </div>
                          </div>
                          <div className="counter-val-main">
                            <p>{dashboardData.request_received_count}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6  counter-cards" onClick={() => history.push('/list')}>
                      <div className="inner-column">
                        <div className="counter-card-img card-inner-cols">
                          <img src={req} alt="" />
                        </div>
                        <div className="counter-card-content card-inner-cols">
                          <div className="card-head-main d-flex justify-content-between ">
                            <div className="counter-label">
                              <h4>Requests accepted</h4>
                            </div>
                          </div>
                          <div className="counter-val-main">
                            <p>{dashboardData.request_accepted_count}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </section>
      </main>
    </div>
  );
}
