import React, {useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Sidebar from "../components/layout/Sidebar";
import Header from "../components/layout/Header";
import TablePaginationWrapper from '../components/TablePaginationWrapper'
import {call} from "../utils/apiCall";
import useAuth from "../hooks/useAuth";

const Faq = () => {

  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [totalPages, setTotalPages] = useState(1);

  const onSizeChange = (e) => {
    setSize(parseInt(e.target.value));
  };

  const onClickNext = () => {
    setPage(page + 1);
  };

  const onClickBack = () => {
    setPage(page - 1);
  };

  const getUrl = () => {
    if(searchTerm) {
      return `/get-faq?search=${searchTerm}&page=${page}&size=${size}`
    }else{
      return `/get-faq?&page=${page}&size=${size}`
    }
  }
  useEffect (() => {
    (async() => {
      try{
        const result = await call('get',getUrl(),{},{},{})
        console.log(result.data);
          setData(result.data.data);
          setTotalPages(result.data.page_count)
      }catch (e) {
        console.log(e)
      }
    })();

  },[searchTerm, page, size])

  const handleSearchInput = (e) => {
    setTimeout(() => {
      setPage(1)
      setSearchTerm(e.target.value);

    }, 500)
  }

  const { isAuthenticated } = useAuth();

  return (
    <>
       {isAuthenticated ? (
      <main className="page_wrapper">
        <section className="dashboard_section d-flex">
          <div>
            <Sidebar />
          </div>

          <section className="dashboard_main_section">
          {isAuthenticated && <Header title="FAQs" />}
            <section className="dasboard_main_area list-experts-area">
              <div className="faq">
                <h4>How can we help you?</h4>
                <div className="input">
                  <Form.Control onChange={handleSearchInput} type="text" placeholder="Search... " />
                  <i className="fa fa-search"></i>
                </div>
                <div className="accordian mt-4">
                  {data?.map((item, idx) => <Accordion key={idx} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                        className="mt-3"
                    >
                      <Typography>
                        <strong>Question: </strong> {item.faq_question}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        {item.faq_answer}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>)}
                  {data?.length === 0 && <h1>No Data Found!</h1>}
                </div>
                <TablePaginationWrapper
                  onChange={onSizeChange}
                  page={page}
                  pagination={totalPages}
                  onClickNext={onClickNext}
                  onClickBack={onClickBack}
                />
              </div>
            </section>
          </section>
        </section>
      </main> ):(
         <section className="dasboard_main_area list-experts-area">
             {!isAuthenticated && <h1>FAQs</h1>}
         <div className="faq">
           <h4>How can we help you?</h4>
           <div className="input">
             <Form.Control onChange={handleSearchInput} type="text" placeholder="Search... " />
             <i className="fa fa-search"></i>
           </div>
           <div className="accordian mt-4">
             {data?.map((item, idx) => <Accordion key={idx} >
               <AccordionSummary
                   expandIcon={<ExpandMoreIcon />}
                   aria-controls="panel2a-content"
                   id="panel2a-header"
                   className="mt-3"
               >
                 <Typography>
                   <strong>Question: </strong> {item.faq_question}
                 </Typography>
               </AccordionSummary>
               <AccordionDetails>
                 <Typography>
                   {item.faq_answer}
                 </Typography>
               </AccordionDetails>
             </Accordion>)}
             {data?.length === 0 && <h1>No Data Found!</h1>}
           </div>
           <TablePaginationWrapper
              onChange={onSizeChange}
              page={page}
              pagination={totalPages}
              onClickNext={onClickNext}
              onClickBack={onClickBack}
            />
           
         </div>
       </section>
      )

}
    </>
  );
};

export default Faq;
