import React, { useState, useEffect } from "react";
import Sidebar from "../components/layout/Sidebar";
import Header from "../components/layout/Header";
import { call } from '../utils/apiCall'
import App from "../components/imageverify";
import TablePaginationWrapper from "../components/TablePaginationWrapper";
import { time_ago } from "../utils/timeFunc";

export default function NotificationsList () {
    const [notificationsList, setNotificationsList] = useState()
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(5);
    const [pagination, setPagination] = useState("");

    useEffect(() => {
        const token = localStorage.getItem("accessToken");
        (async () => {
          try {
            const res = await call("post", `/notification-list`, null, {
              page:page,
            size:size
      }, token);
          setNotificationsList(res?.data);
          setPagination(res.data.page_count);
          console.log(res.data.data);
          } catch (err) {
            console.log(err);
          }
        })();
      }, [page,size]);

      useEffect(() => {
        if (page > pagination) {
          setPage(1);
        }
      }, [pagination]);

      const onChange = (e) => {
        setSize(e.target.value);
      };

      const onClickNext = () => {
        setPage(page + 1);
      };
    
      const onClickBack = () => {
        setPage(page - 1);
      };

    return (
      <div>
        <main className="page_wrapper">
          <section className="dashboard_section d-flex">
            <Sidebar/>

            <section className="dashboard_main_section">
              <Header title='Notifications'/>
              <section className="dasboard_main_area list-experts-area questions-main-section points">
              <div className="points-counter d-flex justify-content-between align-items-center">
              <div style={{width:"100%" }} className="notification-box">
                     
                      <div className="box">
                      {notificationsList?.data?.map((item, index) => {
                      return (
                        <div className="user">
                       
                        <div className="img">
                        <App imageURL={item.user_profile_image} width="40px" height="40px" />
                        </div>
                        <div className="content">
                        <a href={item.notification_type_id === 3  ? `/list` : `/user/${item.question_id}/${item.sender_user_id}`} style={{backgroundColor: "#e9ecef"}}>
	<div class="hover-item">
                                  <p>
                                    <b>{item.notification_title} </b>
                                  </p>
                                  <p>{item.notification_body} </p>
	</div>
</a>
                          <div className="day">
                            <span>{time_ago(new Date(item?.createdAt))} at at{" "} {new Date(item?.createdAt)
                                  .toLocaleTimeString('en-US', {
                                   hour:"2-digit" , minute: "2-digit"
                                  }).replace(/ /g, ' ')}</span>
                          </div>
                        </div>
                      </div>
                    )
                      })}

                  
                        {/* <div className="user">
                          <div className="img">
                            <img src={profile} alt="" />
                          </div>
                          <div className="content">
                            <p>Lorem ipsum consectetur adipiscing Ieiusmod tempor incididunt.</p>
                            <div className="day">
                              <span>Last Wednesday at 9:42 AM</span>
                            </div>
                          </div>
                        </div>
                        <div className="user">
                          <div className="img">
                            <img src={profile} alt="" />
                          </div>
                          <div className="content">
                            <p>Lorem ipsum consectetur adipiscing Ieiusmod tempor incididunt.</p>
                            <div className="day">
                              <span>Last Wednesday at 9:42 AM</span>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                    </div>
                    <TablePaginationWrapper
                              onChange={onChange}
                              page={page}
                              pagination={pagination}
                              onClickNext={onClickNext}
                              onClickBack={onClickBack}
                            />
                    </section>
            </section>
          </section>
        </main>
      </div>
    );
  }
