import React from "react";

const TablePaginationWrapper = ({
  onChange,
  page,
  size,
  pagination,
  onClickNext,
  onClickBack,
}) => {
  
  return (
    <div>
      <div className="table-pagination-wrapper">
        <div className="table-pagination-con d-flex align-items-center justify-content-lg-end justify-content-center">
          <span className="pagination-label">Items per page:</span>
          <select
            className="select-page-number"
            name=""
            id=""
            onChange={onChange}
            value={size}
          >
            <option value="5">05</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
          </select>
          <span className="pagination-label page-counter">
            {page} of {pagination}
          </span>

          <span className="pagination-controls">
            {page === 1 ? (
              <a  href="javascript:void(0)" className="prev">
                ‹
              </a>
            ) : (
              <a  href="javascript:void(0)" className="prev" onClick={onClickBack}>
                ‹
              </a>
            )}
            {page === pagination ? (
              <a href="javascript:void(0)" className="next">
                ›
              </a>
            ) : (
              <a  href="javascript:void(0)" className="next" onClick={onClickNext}>
                ›
              </a>
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default TablePaginationWrapper;
