import React, { useState} from "react";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
// import face from "../assets/img/face.png";
// import apple from "../assets/img/apple.png";
// import google from "../assets/img/google.png";
import logo from "../assets/img/logo.png";
import newlogo2 from "../assets/img/newlogo2.jpg"
import Form from 'react-bootstrap/Form'
import GoogleLogin from "../config/GoogleLogin";
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import useAuth from '../hooks/useAuth'
import { fetchToken } from '../services/firebase'

export default function Login() {

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [errorPassword, setErrorPassword] = useState('')
  const [responsePath, setResponsePath] = useState(null);
  const [responseError, setResponseError] = useState(null)
  const [passwordShown, setPasswordShown] = useState(false);
  const [isTokenFound, setTokenFound] = useState(false);

  const { login, isAuthenticated } = useAuth()


  const location = useLocation();
  const history = useHistory();
  console.log(isAuthenticated)
  if (isAuthenticated) {
    const { from } = location.state || { from: { pathname: "/dashboard" } };
    history.replace(from);
  }

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const setStatus = () => {
    setErrorMessage('')
    setResponsePath(null)
  }
  const setpass = () => {
    setErrorPassword('')
    setResponsePath(null)
  }
  const checkValidation = () => {
    let emailvalid = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    let formIsValid = true;

    if (!emailvalid.test(email)) {
      setErrorMessage("Email id is not valid")
      formIsValid = false;
    }
    if (!email) {
      setErrorMessage("Email should not empty")
      formIsValid = false;
    }
    if (!password) {
      setErrorPassword("Password should not empty")
      formIsValid = false;
    }

    return formIsValid;
  }
  const handleFormSubmit = async () => {
    try {
      await login(email, password, "email");
      fetchToken(setTokenFound)
      setResponsePath("loginSuccess")
    } catch (e) {
      setResponsePath("loginFail")
      setResponseError(e.message)
      if(e.response.data){
        setResponseError(e.response.data.message)
      }
    }
  }

  const submitLoginData = (e) => {
    e.preventDefault();

    if (checkValidation()) {
      handleFormSubmit()
    }
  };
  return responsePath === "loginSuccess" ? (
    <Redirect to="/dashboard" />
  ) : (
    <div className="limiter">
      <div className="container-login100">
        <div className="wrap-login100">
          <form className="login100-form validate-form">
            <span className="login100-form-title"> Welcome </span>
            <p>Please login below</p>
            <p>
              No account?&nbsp;
              <Link to="/signup">
                <strong>Sign Up Now !</strong>
              </Link>
            </p>
            <div
              className="wrap-input100 validate-input mt-4"
              data-validate="Valid email is required: ex@abc.xyz"
            >
              <div className="text-center">
                {responsePath === "loginFail" && (
                  <label className="label-input100 text-danger" >
                    {responseError}
                  </label>
                )}
              </div>
              <div className="label-input100 ">Email</div>
              <div className="input">
                <i className="icon-message"></i>
                <Form.Control type="email" placeholder="Enter your email address" onClick={setStatus} onChange={(e) =>
                  setEmail(e.target.value)
                }
                />

              </div>
              {errorMessage && (
                <label className="label-input100 text-danger" >
                  {errorMessage}
                </label>
              )}
              <span className="focus-input100"></span>
            </div>

            <div
              className="wrap-input100 validate-input mt-4"
              data-validate="Password is required"
            >
              <div className="label-input100 ">Password</div>
              <div className="input">
                <i className="icon-lock"></i>
                <Form.Control
                  className="input100"
                  type={passwordShown ? "text" : "password"}
                  name="pass"
                  onClick={setpass}

                  onChange={(e) =>
                    setPassword(e.target.value)
                  }

                  placeholder="Enter the password"
                />
                <span onClick={togglePassword}>
                  {passwordShown ? (
                    <i className="eye-icon2"><Visibility /></i>
                  ) : (
                    <i className="eye-icon2"><VisibilityOff /></i>
                  )}

                </span>

              </div>
              {errorPassword && (
                <label className="label-input100 text-danger" >
                  {errorPassword}
                </label>
              )}
              <span className="focus-input100"></span>
            </div>

            <div className="flex-sb-m w-full row">
              <div className="contact100-form-checkbox col-6 d-flex align-items-center">
                <input
                  className="input-checkbox100"
                  id="ckb1"
                  type="checkbox"
                  name="remember-me"
                />
                <label className="label-checkbox100 mx-1" id="ckb1" >
                  Remember me
                </label>
              </div>

              <div className="col-6 text-end">
                <Link to={'ForgotPassword'} className="txt1">
                  Forgot Password?
                </Link>
              </div>
            </div>

            <div className="container-login100-form-btn mt-5">
              <button onClick={submitLoginData} className="btn btn-xl w-100">
                Login
              </button>
            </div>
            <div className="text-center p-t-46 p-b-20 ">
              <span className="txt2"> or continue with</span>
            </div>

            {/* <div className="login100-form-social flex-c-m text-center">
                  <a href="{url}" className="login100-form-social-item me-3">
                    <img src={face} className="img-fluid" alt="img" />
                  </a>

                  <a href="{url}" className="login100-form-social-item me-3">
                    <img src={apple} alt="img" className="img-fluid" />
                  </a>
                  <a
                    href="{url}"
                    className="login100-form-social-item flex-c-m bg2 m-r-5"
                  >
                    <img src={google} className="img-fluid" alt="img" />
                  </a>
                </div> */}
            < div className="parentdiv"><GoogleLogin /></div>
          </form>

          <div className="login100-more">
            <div className="logo m-4 ">
            <a href="/" >
              <img src={newlogo2} className="img-fluid"  width={"150px"} height = {"60px"} alt="img" />
              </a>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
