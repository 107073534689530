//  //import react from 'react';
//  import axios from 'axios';
//  import {Buffer} from 'buffer';
 
//  export const imageurl = (image) => {
//     console.log(image)
//     let token = localStorage.getItem("accessToken");
//    const imageurl = axios.get(`${image}`, { headers: { 'authorization': token }, responseType: 'arraybuffer' })
   
// //    .then((res) => {

// //       let data = `data:${res.headers["content-type"]
// //         };base64,${new Buffer(res.data, "binary").toString("base64")}`;
//      return imageurl;
//     // });
//   }

import React, { useEffect, useState, useContext } from "react";
import profile from "../assets/img/user.png";

import axios from 'axios'
import {Buffer} from 'buffer';

 const App = ({imageURL, width, height}) => {
  const [img, setImg] = useState();
  const fetchImage = async () => {
    console.log(imageURL)
    if(imageURL){
   let token = localStorage.getItem("accessToken");
   axios.get(`${imageURL}`, { headers: { 'authorization': token }, responseType: 'arraybuffer' }).then((res) => {

     let data = `data:${res.headers["content-type"]
       };base64,${new Buffer(res.data, "binary").toString("base64")}`;
       setImg(data)
   });
  }
  else{
    setImg(profile)
  }
}
  useEffect(() => {
    fetchImage();
  }, [imageURL]);

  
  return (
    <img src={img} alt="" width={width} height={height} style = {{backgroundColor : "#616666"}}/>
  )
}

export default App;
