import React, { useEffect, useState, useRef } from "react";
import Form from "react-bootstrap/Form";
import { call } from "../../../utils/apiCall";
import TablePaginationWrapper from "../../../components/TablePaginationWrapper";
import Select from "react-select";
import tableArrowUp from "../../../assets/img/table-up-arrow.png";
import tableArrowDown from "../../../assets/img/table-down-arrow.png";
import filterIcon from "../../../assets/img/filter.png";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


const TableRow = ({ match_profile, fullName, countryName, badge, locations, loginTime }) => {
  return (
    <tr>
      <td style={{display: "none"}}>
        <div className="input-box">
          <input type="checkbox" name="" id="" />
        </div>
      </td>
      <td>
        <div className="exp-name-val">{fullName}</div>
      </td>
      <td>{match_profile}</td>
      <td>{countryName}</td>
      <td>{loginTime}</td>
      <td>{badge}</td>
      <td>{locations}</td>
    </tr>
  );
};

const ExpertsList = ({expertListUpdater, currentUpdater, pager}) => {
  const [show, toggleShow] = useState(false);
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [locationList, setLocationList] = useState([]);
  const [optionsList, setOptionsList] = useState([]);
  const [selected , setSelected] = useState([]);
  const [update, setUpdate] = useState(false);
  const [badgeList, setBadgeList] = useState([]);
  const [selectedBadge, setSelectedBadge] = useState(null);

  const [sorting, setSorting] = useState({ key: "full_name", ascending: true });
  const [currentUsers, setCurrentUsers] = useState("");

  const node = useRef();

  useEffect(() => {
    const currentUsersCopy = [...currentUsers];

    const sortedCurrentUsers = currentUsersCopy.sort((a, b) => {
      return a[sorting.key].localeCompare(b[sorting.key]);
    });

    setList(
      sorting.ascending ? sortedCurrentUsers : sortedCurrentUsers.reverse()
    );
  }, [sorting]);

  function applySorting(key, ascending) {
    console.log("filter_check")
    setSorting({ key: key, ascending: ascending });
  }


  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    (async () => {
      try {
        const res = await call("get", "/get-locations", null, null, token);
        setLocationList(res.data.data);
        console.log(res.data.data, "locations");
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    (async () => {
      try {
        const res = await call("get", "/badge-list", null, null, token);
        setBadgeList(res.data.badgeList);
        console.log(res.data, 'badge');
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useEffect(() => {
    const options = locationList.map((item) => ({
      value: {
        country_id: item.country_id,
        state_id: item.state_id,
        city_id: item.city_id,
      },
      label: item.location_name,
    }));
    console.log(locationList);
    setOptionsList(options);
  }, [locationList]);


  useEffect(() => {
    let token = localStorage.getItem("accessToken");
    let payload = {
      page: page || 1,
      size,
      badge: parseInt(selectedBadge) || null,
      location:  selected.length > 0 ? selected.map((item) => item.value) : null,
    };
    (async () => {
      try {
        const res = await call("post", "/get-experts", null, payload, token);
        console.log(res.data);
        setTotalPages(res.data?.total_pages);
        console.log(res.data.list, "list data");
        setList(res.data.list);
        setCurrentUsers(res.data.list)
      } catch (err) {
        console.log(err);
      }
    })();
  }, [page, size, update, currentUpdater]);

  useEffect(() => {
    console.log(page, totalPages);
    if (page > totalPages) {
      setPage(totalPages);
    }
  }, [totalPages]);

  const onSizeChange = (e) => {
    setSize(parseInt(e.target.value));
  };

  const onClickNext = () => {
    setPage(page + 1);
  };

  const onClickBack = () => {
    setPage(page - 1);
  };

  const clickOutsideFilter = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }
    toggleShow(false)
  }

  const handleConnect = async (targetUser) => {
    let token = localStorage.getItem("accessToken");
    const payload = {
      requested_to : targetUser
    }
    const res = await call("post", "/send-request", null, payload, token);
    if(res.data.success === true){
      toast.configure()
      toast.success(res.data.message, {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
      setUpdate(!update)
      expertListUpdater(!currentUpdater)
      pager(1)
    }
    else if(res.data.success === false){
      toast.configure()
      toast.error(res.data.message, {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
    }
  }

  const handleCancel = async (targetUser) => {
    let token = localStorage.getItem("accessToken")
    const payload = {
      user_id : targetUser,
      status : "cancel"
    }
    const res = await call("post", '/request-actions', null, payload, token)
    if(res.data.success === true){
      toast.configure()
      toast.success(res.data.message, {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
      setUpdate(!update)
      expertListUpdater(!currentUpdater)
      pager(1)

    }
    else if(res.data.success === false){
      toast.configure()
      toast.error(res.data.message, {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
    }
  }

  const handleAction = (flag) => {
    toast.configure()
    if(flag === "accept"){
      toast.success("Already Connected.", {autoClose: 3000, position: toast.POSITION.TOP_CENTER})
    }
    else if(flag === "block"){
      toast.error("You have been blocked by this Expert.", {autoClose : 3000, position: toast.POSITION.TOP_CENTER})
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', clickOutsideFilter);

    return () => {
      document.removeEventListener('mousedown', clickOutsideFilter);
    }
  }, [show])
  return (
    <div>
      <div className="tabs_pannel" id="tab1">
        <div className="table-exp-main">
          <div className="experts-table-filter">
            <span ref={node} className="filter-logo" onClick={() => toggleShow(!show)}>
       
              <img src={filterIcon} style={{paddingRight:"10px"}}alt="" />
           
              Filters
            </span>
            {show && (
              <div ref={node}>
                <div className="filter-box question-filter-box text-start">
                  <div className="inner-filter">
                    <Form.Label className="label-input100  ">Badge</Form.Label>

                    <Form.Select
                      aria-label="Default select example"
                      value={selectedBadge}
                      onChange={e => setSelectedBadge(e.target.value)}
                    >
                      <option value="">Select</option>
                      {badgeList.map(item =><option value={parseInt(item.id)}>{item.badge_name}</option> )}
                    </Form.Select>
                    <Form.Label className="label-input100 mt-2 ">
                      Location
                    </Form.Label>
                    <Select
                        value={selected}
                        isMulti
                        options={optionsList}
                        onChange={(e) => setSelected(e)}
                        placeholder="Select"
                        theme={theme => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            neutral50: 'black',  // Placeholder color
                          },
                        })}
                    />
                    <div className="d-flex justify-content-between mt-4">
                      <button className="btn btn-outline" onClick={() => toggleShow(!show)} >Cancel</button>
                      <button className="btn btn-xl shadow-none" onClick={() => setUpdate(!update)} >Apply</button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="exp-table-container table-responsive">
            <table className="table list_experts_table">
              <thead>
                <tr>
                  <th style={{display : 'none'}}>
                    <div className="input-box">
                      <input type="checkbox" name="" id="" />
                    </div>
                  </th>
                  <th>
                    <span className="exp-name">
                      Name
                      <img src={tableArrowUp}  style={{paddingLeft: "5px"}}alt=""  onClick={() => applySorting("full_name", true)}/>
                      <img src={tableArrowDown}  style={{paddingLeft: "1px"}}alt=""  onClick={() => applySorting("full_name", false)}/>
                    </span>
                  </th>
                  <th>Profile Match</th>
                  <th>Location</th>
                  <th>Last Login</th>
                  <th>Badge</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {list && list.map((item, index) => {
                  let loginTime = item.loginTime
                  if(loginTime === null){
                    loginTime = "Not Logged in yet"
                  }
                  else if(loginTime != null){
                    let dt = new Date(loginTime)
                    let dateBreak = dt.toString().split(" ")
                    loginTime = `${dateBreak[2]}-${dateBreak[1]}-${dateBreak[3]} @ ${dateBreak[4]}`
                  }
                  let action_button
                  if(item.request_flag === "sent"){
                    action_button = (<button style={{padding:"4px 15px"}} onClick = {() => handleCancel(item.user_id)} className="btn btn-xl shadow-none request-action flag-cancel">Cancel</button>)
                  }
                  else if(item.request_flag === "NOT SENT" || item.request_flag === "reject"){
                    action_button = (<button style={{padding:"4px 15px"}} onClick = {() => handleConnect(item.user_id)} className="btn btn-xl shadow-none request-action" id="connect-btn">Connect - 100 pts</button>)
                  }
                  else if(item.request_flag === "block"){
                    action_button = (<button style={{padding:"4px 15px"}} onClick = {() => handleAction(item.request_flag)} className="btn btn-xl shadow-none request-action flag-block">Blocked</button>)
                  }
                  else if(item.request_flag === "accept"){
                    action_button = (<button style={{padding:"4px 15px"}} onClick = {() => handleAction(item.request_flag)} className="btn btn-xl shadow-none request-action flag-accept">Connected</button>)
                  }
                  else if(item.request_flag === "received"){
                    action_button = (<p>Request received.</p>)
                  }
                  return (
                    <TableRow
                      key={index}
                      fullName={item.screen_name}
                      match_profile={<span>{item.match_profile_percent}%</span>}
                      badge={item.badge_name === null ? 'N/A' : item.badge_name}
                      countryName={item.locations}
                      locations={action_button}
                      loginTime = {loginTime}
                    />
                  )
                }
                )}
              </tbody>
            </table>
          </div>
        </div>
        <TablePaginationWrapper
          onChange={onSizeChange}
          page={page}
          pagination={totalPages}
          onClickNext={onClickNext}
          onClickBack={onClickBack}
        />
      </div>
    </div>
  );
};

export default ExpertsList;
