import React from "react";
import newlogo1 from "../../assets/img/newlogo1.png";
import twitter from "../../assets/img/twitter.png";
import insta from "../../assets/img/insta.png";
import facebook from "../../assets/img/facebook.png";
import linkdin from "../../assets/img/linkdin.png";
import app from "../../assets/img/appstore.png";
import google from "../../assets/img/google-play.png";
import {Link} from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row row-cols-2 row-cols-sm-3 row-cols-md-3 row-cols-lg-5 row-cols-xl-5">
          <div className="col">
            <div className="logo">
              <img src={newlogo1} alt="img" width={"150px"} height = {"60px"} />
            </div>
          </div>
          <div className="col">
            <h4 className="footer-heading">About</h4>
            <ul className="list-unstyled">
              <li>
                <Link to='/about' className="py-1 d-block">
                  Our Story
                </Link>
              </li>
              <li>
                <Link to='/faq' className="py-1 d-block">
                  FAQs
                </Link>
              </li>
              <li>
                <a href="{url}" className="py-1 d-block">
                  Reviews
                </a>
              </li>
            </ul>
          </div>
          <div className="col">
            <h4 className="footer-heading">Contact</h4>
            <ul className="list-unstyled">
              <li>
                <Link to='/contact' className="py-1 d-block">
                  Contact Us
                </Link>
              </li>
              <li>
                <a href="{url}" className="py-1 d-block">
                  Site Feedback
                </a>
              </li>
            </ul>
          </div>
          <div className="col">
            <h4 className="footer-heading">Quick links</h4>
            <ul className="list-unstyled">
              <li>
                <Link to='/legal?tab=term_condition' className="py-1 d-block">
                  Terms of Service
                </Link>
              </li>
              <li>
                <Link to='/legal?tab=policy' className="py-1 d-block">
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
          <div className="col">
            <h4 className="footer-heading">Follow us</h4>
            <ul className="list-unstyled">
              <li>
                <a href="{url}" className="py-1 d-block">
                  <img src={twitter} alt="img" /> &nbsp;Twitter
                </a>
              </li>
              <li>
                <a href="{url}" className="py-1 d-block">
                  <img src={facebook} alt="img" /> &nbsp;Facebook
                </a>
              </li>
              <li>
                <a href="{url}" className="py-1 d-block">
                  <img src={insta} alt="img" /> &nbsp;Instagram
                </a>
              </li>
              <li>
                <a href="{url}" className="py-1 d-block">
                  <img src={linkdin} alt="img" /> &nbsp;LinkedIn
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="copy-right mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <p>@ 2022 Adventure Sports.</p>
            </div>
            <div className="col-lg-4 justify-content-end d-flex">
              <img src={app} alt="img" className="me-3" />
              <img src={google} alt="img" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
