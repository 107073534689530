import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {CometChat} from '@cometchat-pro/chat'

const appSetting = new CometChat.AppSettingsBuilder().subscribePresenceForAllUsers().setRegion("us").build()
CometChat.init("2520919b4a2cbad3", appSetting).then(
  (res) => {
    console.log("COMETCHAT START RES >>>>>>> ", res)
    console.log("Initialization Completed Successfully")
    ReactDOM.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
      document.getElementById('root')
    );
  },
  error => {
    console.log("Initialization Failed with error", error)
  }
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
