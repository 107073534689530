import React, { useState} from "react";
import logo from "../../assets/img/logo.png";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import { Button } from "@mui/material";
import { URL } from "../../constants/constants";
const Other = ({
  nextStep,
  handleFormData,
  handleCountryData,
  countrylist,
  prevStep,
  values,
  screendata,
  locationStateData,
  locationCityData,
  locationdata,
  locationdata2,
  handleDelete,
  handleAddPlace,
  handlesetscreen,
  travelHandle,
  travel,
  filterId,
}) => {
  const [errorScreenName, setErrorScreenName] = useState("");
  const [errorTravel, setErrorTravel] = useState("");
  const [errorCountry, setErrorCountry] = useState("");
  const [errorState, setErrorState] = useState("");
  const [errorCity, setErrorCity] = useState("");
  const [errorPlace, setErrorPlace] = useState("");

  const setTravel = () => {
    setErrorTravel("");
  };
  const setcountry = () => {
    setErrorCountry("");
  };
  const setstate = () => {
    setErrorState("");
  };
  const setcity = () => {
    setErrorCity("");
  };

  const AddPlaceValidation = () => {
    let formIsValid = true;
    if (
      !locationdata.country_id ||
      locationdata.country_id === "Select Country"
    ) {
      setErrorCountry("Please select country");
      formIsValid = false;
    }
    if (!locationdata.state_id) {
      setErrorState("Please select state");
      formIsValid = false;
    }
    if (!locationdata.city_id) {
      setErrorCity("Please select city");
      formIsValid = false;
    }
    return formIsValid;
  };

  const submitPlace = (e) => {
    e.preventDefault();
    if (AddPlaceValidation()) {
      setErrorPlace("");
      handleAddPlace();
    }
  };

  const checkValidation = () => {
    let firstvalid = /^[A-Za-z\d_.]+$/;
    let formIsValid = true;
    if (!screendata.success) {
      formIsValid = false;
    }
    if (!firstvalid.test(values.screen_name)) {
      setErrorScreenName(
        "Screen Name only contain letters, numbers and special charcters (dot and underscore)"
      );
      formIsValid = false;
    }
    if (!values.screen_name) {
      setErrorScreenName("Screen name should not empty");
      formIsValid = false;
    }
    if (!travel || travel === "Select") {
      setErrorTravel("Please select Yes or No");
      formIsValid = false;
    }
    if (travel === "1") {
      if (locationdata2.length === 0) {
        setErrorPlace(
          "Please add atleast one place if you are travelling somewhere. "
        );
        formIsValid = false;
      }
    }
    return formIsValid;
  };

  const screenNameCheck = () => {
    setErrorScreenName("");
  };

  const submitFormData = (e) => {
    e.preventDefault();
    if (checkValidation()) {
      nextStep();
      filterId();
    }
  };
  console.log(locationdata);
  console.log(locationdata2);
  return (
    <div>
      <div className="basic">
        <div className="sign-up">
          <div className="logo">
            <a href={URL} className="navbar-brand">
              <img src={logo} className="img-fluid" alt="img" />
            </a>
          </div>
          <div className="heading">
            <h1>Sign up now</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </div>
          <div className="process">
            <ul>
              <li>
                <div className="node ">
                  <div className="circle">
                    <span className="check"></span>
                  </div>
                </div>
                <p> Basic information</p>
              </li>
              <li>
                <div className="divider"></div>
              </li>
              <li>
                <div className="node ">2</div>
                <p className="active">Other details</p>
              </li>
              <li>
                <div className="divider"></div>
              </li>
              <li>
                <div className="node ">3</div>
                <p>Personal details</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="wrap details">
          <div>
            {" "}
            {/* <form>*/}
            <h2>Other details</h2>
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <div className="left">
                  <div className="label-input100 ">Screen Name*</div>
                  <div className="input">
                    <input
                      className="input100"
                      type="text"
                      name="screen_name"
                      onClick={screenNameCheck}
                      value={values.screen_name}
                      onChange={handleFormData("screen_name")}
                      placeholder="Enter the screen name"
                    />
                    {screendata.message === "Available." ? (
                      <span className="avi">
                        <CheckCircleOutlineRoundedIcon />
                        &nbsp; Available
                      </span>
                    ) : screendata.message === "Unavailable." ? (
                      <span>
                        <div className="close"></div> Not Available
                      </span>
                    ) : screendata.message === "Available." ? (
                      <div></div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  <div className="suggestion">
                    <p>
                      Suggestions:{" "}
                      {screendata.suggestions?.map((item, index) => {
                        return (
                          <span onClick={handlesetscreen(item)}>{item}</span>
                        );
                      })}
                    </p>
                  </div>
                  {errorScreenName && (
                    <label className="label-input100 text-danger">
                      {errorScreenName}
                    </label>
                  )}
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="left">
                  <div className="label-input100 ">
                    Are you traveling somewhere?*
                  </div>
                  <select
                    className=" classNameic"
                    aria-label="Default select example"
                    onClick={setTravel}
                    value={travel}
                    onChange={travelHandle}
                  >
                    <option selected>Select</option>
                    <option value="1">Yes</option>
                    <option value="2">No</option>
                  </select>
                  {errorTravel && (
                    <label className="label-input100 text-danger">
                      {errorTravel}
                    </label>
                  )}
                </div>
              </div>
            </div>
            <div className="row my-4"></div>
            <div className="row bg">
              <div className="col-lg-10 offset-lg-2 col-sm-12" id="info-label">
                <p>
                  <strong>
                    Places you wish to ask or answer questions/ you have been to
                  </strong>
                </p>
                <i title = "While not required, this information is used to better match you to experts near your preferred locations. For more info, see FAQs." className="fa fa-info-circle"></i>
              </div>
              <div className="col-lg-6 offset-lg-3 col-sm-12 mt-4">
                <div className="left">
                  {travel === "1" ? (
                    <div className="label-input100">Country Name*</div>
                  ) : (
                    <div className="label-input100">Country Name</div>
                  )}
                  <select
                    className=" classNameic"
                    aria-label="Default select example"
                    name="country_id"
                    value={JSON.stringify(locationdata.country_id)}
                    onClick={setcountry}
                    onChange={handleCountryData("country_id")}
                  >
                    <option value="null">Select Country</option>
                    {countrylist.countryList?.map((item, index) => {
                      return (
                        <option value={JSON.stringify(item)}>
                          {item.country_name}
                        </option>
                      );
                    })}
                  </select>
                  {errorCountry && (
                    <label className="label-input100 text-danger">
                      {errorCountry}
                    </label>
                  )}
                </div>
              </div>
              <div className="col-lg-6 offset-lg-3 col-sm-12 mt-3">
                <div className="left">
                  {travel === "1" ? (
                    <div className="label-input100">State Name*</div>
                  ) : (
                    <div className="label-input100">State Name</div>
                  )}
                  <select
                    className=" classNameic"
                    aria-label="Default select example"
                    name="state_id"
                    value={JSON.stringify(locationdata.state_id)}
                    onClick={setstate}
                    onChange={handleCountryData("state_id")}
                  >
                    <option value="null">Select State </option>
                    {locationStateData.stateList?.map((item, index) => {
                      return (
                        <option key={item.id} value={JSON.stringify(item)}>
                          {item.state_name}
                        </option>
                      );
                    })}
                  </select>
                  {errorState && (
                    <label className="label-input100 text-danger">
                      {errorState}
                    </label>
                  )}
                </div>
              </div>
              <div className="col-lg-6 offset-lg-3 col-sm-12 mt-3">
                {travel === "1" ? (
                  <div className="label-input100">City Name*</div>
                ) : (
                  <div className="label-input100">City Name</div>
                )}
                <select
                  className=" classNameic"
                  aria-label="Default select example"
                  name="city_id"
                  value={JSON.stringify(locationdata.city_id)}
                  onClick={setcity}
                  onChange={handleCountryData("city_id")}
                >
                  <option value="null">Select City</option>
                  {locationCityData.cityList?.map((item, index) => {
                    return (
                      <option key={index} value={JSON.stringify(item)}>
                        {item.city_name}
                      </option>
                    );
                  })}
                </select>
                {errorCity && (
                  <label className="label-input100 text-danger">
                    {errorCity}
                  </label>
                )}
              </div>
              <div className="col-lg-6 offset-lg-3 col-sm-12 mt-4">
                <div className="text-center">
                  <div className="">
                    <p>
                      <button className="btn-xl" onClick={submitPlace}>
                        Add
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              {errorPlace && (
                <label className="label-input100 text-danger">
                  {errorPlace}
                </label>
              )}
              <div className="col-sm-12 p-0 mt-2">
                <div className="d-flex flex-wrap">
                  {locationdata2?.map((item, index) => {
                    return (
                      <span key={item.country_id.country_code}>
                        <div
                          class="toast d-block m-1"
                          role="alert"
                          aria-live="assertive"
                          aria-atomic="true"
                        >
                          <div class="toast-header">
                            <strong>
                              {item.city_id.city_name},{" "}
                              {item.state_id.state_code},{" "}
                              {item.country_id.country_code}
                            </strong>

                            <Button
                              onClick={handleDelete(item.city_id.id)}
                              type="button"
                              class="btn-close"
                              data-bs-dismiss="toast"
                              aria-label="Close"
                            ></Button>
                          </div>
                        </div>
                      </span>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="text-end">
                <span className="btn btn-outline" onClick={prevStep}>
                  Back
                </span>
                <span onClick={submitFormData} className="btn btn-xl">
                  Next
                </span>
              </div>
            </div>
            {/* </form>*/}{" "}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Other;
