import React from 'react'
import { Box, styled } from '@mui/system'
import { CircularProgress } from '@mui/material'


const StyledLoading = styled('div')(() => ({
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  
    display: "flex",
    alignItems: "center",
   justifyContent:"center",
  
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    "& .circleProgress": {
        color:'#0d6efd',
        position: 'absolute',
        left: -7,
        right: 0,
        top: 'calc(50% - 25px)',
    }
}))

const Loader = () => {

    return (
        <StyledLoading>
            <div className={"loader-wrapper"}>
   <div className={"loader"}>Loading...</div>
 </div>
        </StyledLoading>
    )
}

export default Loader
//{loading && <Loading />}