import { useState, useEffect } from "react";
import Sidebar from "../../../components/layout/Sidebar";
import Header from "../../../components/layout/Header";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { call } from "../../../utils/apiCall";
import { Modal } from "react-bootstrap";
import TablePaginationWrapper from "../../../components/TablePaginationWrapper";
import Loading from "../../../components/Loaders/Loading";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

toast.configure()

function Points() {
  const [pointsList, setPointsList] = useState()
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [pagination, setPagination] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState();
  const [pointType, setPointType] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  let userData = localStorage.getItem("user")
  userData = JSON.parse(userData)

  useEffect(() => {
    setIsLoading(true)
    const token = localStorage.getItem("accessToken");
    (async () => {
      try {
        const res = await call("post", `/points-history`, null, {
          page:page,
          size:size,
          point_type:pointType
    }, token);
        setPointsList(res?.data);
        setPagination(res.data.page_count);
        console.log(res.data.data);
        setIsLoading(false)
      } catch (err) {
        console.log(err);
        setIsLoading(false)
      }
    })();
  }, [page, size, pointType]);

  const closeModal = () => {
    setIsOpen(false)
  }

  const pointsTypeHandle = (e) => {
    setPointType (e.target.value);
  }

  useEffect(() => {
    if (page > pagination) {
      setPage(1);
    }
  }, [pagination]);

  const onChange = (e) => {
    setSize(e.target.value);
  };

  const onClickNext = () => {
    setPage(page + 1);
  };

  const onClickBack = () => {
    setPage(page - 1);
  };

  const handleCopy = (code) => {
    navigator.clipboard.writeText(code);
    toast.success("Code copied to clipboard.", {autoClose : 1000, position : toast.POSITION.TOP_CENTER})
  }

  return (
    <main className="page_wrapper">
      <section className="dashboard_section d-flex">
        <div>
          <Sidebar />
        </div>

        <section className="dashboard_main_section">
          <Header title="Points" />
          <section className="dasboard_main_area list-experts-area questions-main-section points">
            {userData.data.referral_code !== null ? 
            <div className="coupon text-center">
              <div className="coupon-code">
                <div className="d-flex">
                  <h3>
                    {userData.data.referral_code}{" "}  
                  </h3>
                  <span title = "Copy Code">
                      <FileCopyOutlinedIcon sx={{ fontSize: 28 }} className = "copy-icon" onClick = {() => handleCopy(userData.data.referral_code)}/>
                  </span>
                </div>
                <span>Your referral code</span>
              </div>
              <div className="coupon-content">
                <p>
                  Share this referral code with your friends to earn 50 points
                  once they successfully register using your referral code.
                </p>
              </div>
            </div> :
            <></>
            }
            <div className="points-counter d-flex justify-content-between align-items-center">
              <div className="points-available">
                <label>Current Point Balance:</label>
                <span className="points-val">{pointsList?.pointBalance}</span>
              </div>
              <div className="points-btns">
                <Link to={'./buy'} className="btn btn-outline">Buy Points</Link>
                <Link to={'./reedem'} className="btn btn-xl shadow-none">
                  Redeem Points
                </Link>
              </div>
            </div>

            <div className="points-table-section">
              <div className="points-table-container">
                <div className="point-sort-filter">
                  <div className="sort-filter-wrap">
                  <div className="select_container">
                            <div className="dropholder">
                              <Form.Select
                                aria-label="Default select example"
                                className="dropdown"
                                onChange={pointsTypeHandle}
                              >
                                <option value="0">All</option>
                                <option value="1">Bought</option>
                                <option value="2">Gained</option>
                                <option value="3">Spent</option>
                              </Form.Select>
                            </div>
                          </div>
                  </div>
                </div>
                {isLoading ? <Loading/> :
                <div className="points-tb-wrapper table-responsive">
                  <table className="points-table">
                    <thead className="points-tb-header">
                      <tr>
                        <th>Date & Time</th>
                        <th>Points</th>
                        <th>Description</th>
                        <th>
                          <div className="action-cell">Action</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                    {pointsList?.data?.map((item, index) => {
                      let description = ""
                      if(item.point_sub_type == "distribute_extra_point"){
                        description = "Extra Point Distribution"
                      }
                      else if(item.point_sub_type == "view_response"){
                        description = "Viewed question responses"
                      }
                      else if(item.point_sub_type == "point_purchased"){
                        description = "Points Purchased"
                      }
                      else if(item.point_sub_type == "gain_response_point"){
                        description = "Points earned on response"
                      }
                      else if(item.point_sub_type == "purchase_question_ownership"){
                        description = "Purchased question ownership"
                      }
                      else if(item.point_sub_type == "redeem_points"){
                        description = "Points redeemed for gift card"
                      }
                      else if(item.point_sub_type == "asked_question"){
                        description = "Asked Question"
                      }
                      else if(item.point_sub_type == "cancel_request"){
                        description = "Connection Request Cancelled"
                      }
                      else if(item.point_sub_type == "send_request"){
                        description = "Connection Request Sent"
                      }
                      else if(item.point_sub_type == "reject_request"){
                        description = "Connection Request Rejected"
                      }
                      else if(item.point_sub_type == "apply_referral_gain"){
                        description = "New user registered using your referral"
                      }
                      else if(item.point_sub_type == "accept_request"){
                        description = "You accepted connection request"
                      }



                      return (
                    <tr key={index}>
                      <td>   {new Date(item.createdAt)
                                .toLocaleDateString('en-US', {
                                  day: 'numeric', month: 'short', year: 'numeric' , hour:"2-digit" , minute: "2-digit"
                                }).replace(/ /g, ' ')}</td>
                        <td>{item.point} {item.point_name}</td>
                        <td>{description}</td>
                        <td>
                          <div className="action-cell">
                            {item.point_type===1 || item.point_type===4 || (item.point_type===2 && item.question_id === null) || (item.point_type===3 && item.question_id === null) || item.point_type === 5 ? <span style={{color:"#dddddd"}} ><u>View</u></span> :
                           <span style={{cursor: "pointer", color:"#0d9edf"}} onClick={()=> {
                              setModalData(item)
                              setIsOpen(true)}}><u>View</u></span> }
                          </div>
                        </td>
                      </tr>
                    )
                      })}
                  
                    </tbody>
                  </table>
                </div>
}
              </div>
            </div>

            {/* <div className="table-pagination-wrapper">
              <div className="table-pagination-con d-flex align-items-center justify-content-lg-end justify-content-center">
                <span className="pagination-label">Items per page:</span>
                <select className="select-page-number" name="" id="">
                  <option value="">05</option>
                </select>
                <span className="pagination-label page-counter">1 of 3</span>
                <span className="pagination-controls">
                  <a className="prev" href={"url"}>
                    ‹
                  </a>
                  <a className="next" href={"url"}>
                    ›
                  </a>
                </span>
              </div>
            </div> */}
                <TablePaginationWrapper
                              onChange={onChange}
                              page={page}
                              pagination={pagination}
                              onClickNext={onClickNext}
                              onClickBack={onClickBack}
                            />
          </section>
        </section>
      </section>
      <Modal
  size="md" style={{ width: '100%' }}
  background
  show={isOpen}
  onHide={closeModal}
  centered
>
<Modal.Header closeButton  style={{ padding: '20px 30px 0px 30px' }}>
<h5>View Question</h5>
</Modal.Header>
  <Modal.Body style={{ padding: '20px 30px 20px 30px' }}>
    <div className="pointHistoryView">Question</div>
    <p>{modalData?.question_text}</p>
    <br/>
    <div className="pointHistoryView">Username</div>
    <p>{modalData?.screen_name}</p>
    <br/>
    <div className="pointHistoryView">Date</div>
    <p> {new Date(modalData?.createdAt)
                                .toLocaleDateString('en-US', {
                                  day: 'numeric', month: 'short', year: 'numeric'
                                }).replace(/ /g, ' ')} {new Date(modalData?.createdAt)
                                  .toLocaleTimeString('en-US', {
                                   hour:"2-digit" , minute: "2-digit"
                                  }).replace(/ /g, ' ')}
                                </p>
                                <br/>
    <div className="pointHistoryView">Status</div>
    {modalData?.question_status === "open" ? <p style={{color:"#0d9f36"}}>{modalData?.question_status}</p>: <p style={{color:"#ff0000"}}>{modalData?.question_status}</p>}
    
  </Modal.Body>
</Modal>
    </main>
  );
}

export default Points;
