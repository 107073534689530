import { useState, useEffect, useRef } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import filter from "../../../assets/img/filter.png";
import AddIcon from "@mui/icons-material/Add";
import Sidebar from "../../../components/layout/Sidebar";
import Header from "../../../components/layout/Header";
import { Link, useHistory } from "react-router-dom";
import { call } from "../../../utils/apiCall";
import QuestionItem from "./QuestionItem";
import TablePaginationWrapper from "../../../components/TablePaginationWrapper";
import QuestionsFilter from "./QuestionsFilter";
import Loading from "../../../components/Loaders/Loading";

export default function Question(props) {
  const [filterBox, setFilterBox] = useState(true);
  const [questionList, setQuestionList] = useState("");
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [pagination, setPagination] = useState("");
  const [tabName, setTabName] = useState("");
  const [locationList, setLocationList] = useState([]);
  const [filterData, setFilterData] = useState({
    question_status: null,
    question_from_date: null,
    question_to_date: null,
    last_response_from_date: null,
    last_response_to_date: null,
    location: null,
  });
  const [update, setUpdate] = useState(false);
  const [filterDataUpdate, setFilterDataUpdate] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState([]);

  const filterCheck = () => {
    setFilterBox(!filterBox);
  };

  const history = useHistory();
  const node = useRef();

  useEffect(() => {}, [tabName]);

  useEffect(() => {
    if (props.location && props.location.data) {
      setTabName(props.location.data.name);
      setSize(props.location.data.size);
      setFilterData(props.location.data.filterData);
      setFilterDataUpdate(props.location.data.filterData);
      setSelected (props.location.data.selected);
      console.log(props.location.data);
      history.replace({
        pathname: "/question",
        data: null,
      });
    } else setTabName("My Questions");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log(tabName);
  
  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    (async () => {
      try {
        const res = await call("get", "/get-locations", null, null, token);
        setLocationList(res.data.data);
        console.log(res.data.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if (tabName === "My Questions" || tabName === "Other Questions") {
      let token = localStorage.getItem("accessToken");
      let data = { ...filterData, page: page, size: size };
      (async () => {
        try {
          const res = await call(
            "post",
            tabName === "My Questions"
              ? "/get-my-questions-new"
              : "/get-other-questions",
            null,
            tabName === "My Questions"
              ? data
              : {
                  ...data,
                  my_responsed_question:
                    filterData?.my_responsed_question || null,
                  country_flag: filterData?.country_flag || "false",
                  state_flag: filterData?.state_flag || "false",
                  city_flag: filterData?.city_flag || "false",
                },
            token
          );
          setQuestionList(res.data);
          setPagination(res.data.page_count || 1);
          setIsLoading(false);
        } catch (err) {
          console.log(err);
          setIsLoading(false);
        }
      })();
    }
  }, [page, size, update, tabName, filterDataUpdate]);

  useEffect(() => {
    if (page > pagination) {
      if (props.location && props.location.data) {
        setPage(props.location.data.page);
      } else setPage(1);
    }
  }, [pagination]);

  // const { filterBox } = this.state;
  const onChange = (e) => {
    setSize(e.target.value);
  };

  const onClickNext = () => {
    setPage(page + 1);
  };

  const onClickBack = () => {
    setPage(page - 1);
  };

  const closeFilterBox = () => {
    setFilterBox(true);
  };

  const clickOutsideFilter = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }
    setFilterBox(true);
  };

  useEffect(() => {
    document.addEventListener("mousedown", clickOutsideFilter);

    return () => {
      document.removeEventListener("mousedown", clickOutsideFilter);
    };
  }, [filterBox]);

  const resetAllFilters = () => {
    setFilterData({
      question_status: null,
      question_from_date: null,
      question_to_date: null,
      last_response_from_date: null,
      last_response_to_date: null,
      location: null,
    });
    setFilterBox(true);
    setPage(1);
    setSize(size);
    setPagination(pagination);
    setSelected([]);
    //setQuestionList("");
    //setUpdate(!update);
  };

  return (
    <>
      <main className="page_wrapper">
        <section className="dashboard_section d-flex">
          <div>
            <Sidebar />
          </div>

          <section className="dashboard_main_section">
            <Header title="Questions" />
            <section className="dasboard_main_area list-experts-area">
              <div className="add-new-question text-end">
                <Link to={"/add"} className="btn btn-xl plus-btn p-3 px-4">
                  <AddIcon /> Add New Question
                </Link>
              </div>

              <div className="question list-experts-main">
                {tabName ? (
                  tabName === "My Questions" ? (
                    <Tabs
                      defaultActiveKey="My Questions"
                      id="uncontrolled-tab-example"
                      onSelect={(key) => setTabName(key)}
                      onClick={resetAllFilters}
                    >
                      <Tab eventKey="My Questions" title="My Questions">
                        <div>
                          <div className="tabs_pannel" id="tab1">
                            <div className="table-exp-main">
                              <div className="row">
                                <div className="col-sm-6">
                                  {/* <div className="allSearchBox">
                <div className="input">
                  <Form.Control type="text" placeholder="Search... " />
                  <i className="fa fa-search"></i>
                </div>
                </div> */}
                                </div>
                                <div className="col-sm-6">
                                  <div className="experts-table-filter ">
                                    <span
                                      className="filter-logo"
                                      onClick={() => {
                                        filterCheck();
                                      }}
                                    >
                                      <div ref={node}>
                                        <img src={filter} alt="" />
                                        &nbsp;Filters
                                      </div>
                                    </span>
                                    <div ref={node}>
                                      <QuestionsFilter
                                        filterData={filterData}
                                        setUpdate={setUpdate}
                                        update={update}
                                        setFilterData={setFilterData}
                                        filterBox={filterBox}
                                        showResponded={false}
                                        close={closeFilterBox}
                                        locationList={locationList}
                                        filtername="myQuesFilter"
                                        tabName={tabName}
                                        selected={selected}
                                        setSelected={setSelected}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="my-question-section">
                                {isLoading ? (
                                  <Loading />
                                ) : (
                                  <div className="my-question-container">
                                    {questionList?.data?.map((item, index) => (
                                      <QuestionItem
                                        key={item.id}
                                        item={item}
                                        id={item.id}
                                        ownerId={item.question_owner_id}
                                        name={tabName}
                                        size={size}
                                        page={page}
                                        filterData={filterData}
                                        selected={selected}
                                      />
                                    ))}
                                  </div>
                                )}
                              </div>
                            </div>
                            <TablePaginationWrapper
                              onChange={onChange}
                              page={page}
                              size={size}
                              pagination={pagination}
                              onClickNext={onClickNext}
                              onClickBack={onClickBack}
                            />
                          </div>
                        </div>
                      </Tab>
                      <Tab
                        eventKey="Other Questions"
                        title="Other Questions"
                      ></Tab>
                    </Tabs>
                  ) : (
                    <Tabs
                      defaultActiveKey="Other Questions"
                      id="uncontrolled-tab-example"
                      onSelect={(key) => setTabName(key)}
                      onClick={resetAllFilters}
                    >
                      <Tab eventKey="My Questions" title="My Questions"></Tab>
                      <Tab eventKey="Other Questions" title="Other Questions">
                        <div className="tabs_pannel">
                          <div className="table-exp-main">
                            <div className="experts-table-filter">
                              <span
                                className="filter-logo"
                                onClick={() => {
                                  filterCheck();
                                }}
                              >
                                <div ref={node}>
                                  <img src={filter} alt="" />
                                  &nbsp;Filters
                                </div>
                              </span>
                              <div ref={node}>
                                <QuestionsFilter
                                  filterData={filterData}
                                  setUpdate={setUpdate}
                                  update={update}
                                  setFilterData={setFilterData}
                                  filterBox={filterBox}
                                  showResponded={true}
                                  close={closeFilterBox}
                                  locationList={locationList}
                                  tabName={tabName}
                                  selected={selected}
                                  setSelected={setSelected}
                                />
                              </div>
                            </div>
                            <div className="my-question-section">
                              {isLoading ? (
                                <Loading />
                              ) : (
                                <div className="my-question-container">
                                  {questionList?.data === undefined ? (
                                    <div className="my-question-item">
                                      <h5
                                        style={{
                                          color: "#808080",
                                          textAlign: "center",
                                        }}
                                      >
                                        No Records Found!! Adjust the filters to
                                        fetch more questions list of location.{" "}
                                      </h5>
                                    </div>
                                  ) : (
                                    questionList?.data?.map((item, index) => (
                                      <QuestionItem
                                        key={item.id}
                                        item={item}
                                        id={item.id}
                                        ownerId={item.question_owner_id}
                                        name={tabName}
                                        size={size}
                                        page={page}
                                        filterData={filterData}
                                        selected={selected}
                                      />
                                    ))
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                          <TablePaginationWrapper
                            onChange={onChange}
                            page={page}
                            size={size}
                            pagination={pagination}
                            onClickNext={onClickNext}
                            onClickBack={onClickBack}
                          />
                        </div>
                      </Tab>
                    </Tabs>
                  )
                ) : (
                  "Loading..."
                )}
              </div>
            </section>
          </section>
        </section>
      </main>
    </>
  );
}
