import React, { useState, useEffect } from "react";
import {ThreeDots} from 'react-loader-spinner'
import Sidebar from "../../../components/layout/Sidebar";
import Header from "../../../components/layout/Header";
import { Link } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import addcircle from "../../../assets/img/add-icon.svg";
import Form from "react-bootstrap/Form";
import { Modal } from "react-bootstrap";
import { call } from '../../../utils/apiCall';
import {useParams} from 'react-router-dom';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export default function Add (props) {

const [isOpen, setIsOpen] = useState(false);
const [countryList, setCountryList] = useState('');
const [locationStateData, setLocationStateData] = useState("");
const [locationCityData, setLocationCityData] = useState("");
const [locationList, setLocationList] = useState('');
const [locationdata, setLocationdata] = useState({
		country_id:"",
		state_id:"",
		city_id:""
	});
const [questionData, setQuestionData] = useState("");
const [selectLocation, setSelectLocation]=useState("")
const [errorCountry, setErrorCountry] = useState('')
const [errorState, setErrorState] = useState('')
const [errorCity, setErrorCity] = useState('')
const [errorQuestion, setErrorQuestion] = useState('')
const [errorLocation, setErrorLocation] = useState('')
const [responseSuccess, setResponseSuccess] = useState('')
const [editDetails, setEditDetails] = useState('')
const [loader, setLoader] = useState(false)

const token = localStorage.getItem("accessToken");

const openModal = () => {
  setIsOpen(true)
}
 const closeModal = () => {
  setIsOpen(false)
  
}

const {quesid} = useParams();

useEffect ( () => {
  if (props.location && props.location.data) {
    (async () => {
      try {
          const res = await call(
              'get',
              `/question-details/${quesid}`,
              null,
              null,
              token
          )
          
          setEditDetails(res?.data?.data)
          setSelectLocation(res?.data?.data?.location)
      setQuestionData(res?.data?.data?.question_text)
      } catch (err) {
          console.log(err)
      }
  })()
  }
  },[])

  useEffect ( ()=> {
   
    (async () => {
      try {
          const res = await call(
              'get',
              '/country',
              null,
              null
          )
          setCountryList(res.data);
          
      } catch (err) {
          console.log(err)
      }
  })()
  }, [])

  // useEffect(() => {
  //   if (props.location && props.location.data) {
  //     // setEditDetails(props.location.data)
  //     // setQuestionData(props?.location?.data?.question_text)
  //   }
  // }, [])

  // console.log(editDetails)

  useEffect (()=> {
   console.log(locationdata.country_id)
    if(locationdata.country_id!==""){
    (async () => {
      try {
          const res = await call(
              'get',
              '/state/'+locationdata.country_id,
              null,
              null
          )
          console.log(res.data)
          setLocationStateData(res.data);       
      } catch (err) {
          console.log(err)
      }
  })()}
  }, [locationdata.country_id])

  useEffect (()=> {
    if(locationdata.state_id!==""){
    (async () => {
      try {
          const res = await call(
              'get',
              '/city/'+locationdata.state_id,
              null,
              null
          )
          console.log(res.data)
          setLocationCityData(res.data);         
      } catch (err) {
          console.log(err)
      }
  })()}
  }, [locationdata.state_id])

  const handleCountryData = input => e => {
    const {value } = e.target;
if(input==="country_id"){
  setLocationdata(prevState => ({
    ...prevState,
    "state_id": false,
    "city_id": false
}));

setLocationdata(prevState => ({
  ...prevState,
  [input]: value
}));
}
else if(input==="state_id"){
  setLocationdata(prevState => ({
    ...prevState,
    "city_id": false
}));

setLocationdata(prevState => ({
  ...prevState,
  [input]: value
}));
}
else
    setLocationdata(prevState => ({
      ...prevState,
      [input]: value
  }));
}

  useEffect ( ()=> {
    
    (async () => {
      try {
          const res = await call(
              'get',
              '/get-locations',
              null,
              null,
              token
          )
          setLocationList(res.data);
          
      } catch (err) {
          console.log(err)
      }
  })()
  }, [locationdata.city_id])

 const AddLocation =() => {
  if (AddPlaceValidation()) {
    (async () => {
      try {
          const res = await call(
              'post',
              '/add-location',
              null,
              locationdata,
              token
          )
          console.log(res.data)
          if(res.data.success === true){
            setLocationdata({
              ...locationdata,
              "country_id":  "",
              "state_id": "",
              "city_id":""
            });
            setIsOpen(false)
            toast.configure()
            toast.success(res.data.message, {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
          }
          else if(res.data.success ===  false){
            toast.configure()
            toast.error(res.data.message, {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
          }
       
      } catch (err) {
        if(err && err.response && err.response.data && err.response.data.success === false){
          toast.configure()
          toast.error(err.response.data.message, {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
        }
      }
  })()
  }
  }

  const setcountry = () => {
    setErrorCountry('')
  }
  const setstate = () => {
    setErrorState('')
  }
  const setcity = () => {
    setErrorCity('')
  }
  const errorQueStatus = () => {
    setErrorQuestion('')
    setResponseSuccess('')
  }
  const errorLocStatus = () => {
    setErrorLocation('')
    setResponseSuccess('')
  }

  const AddPlaceValidation = () => {
    let formIsValid = true;
    if(!locationdata.country_id || (locationdata.country_id==="Select Country")) {
      setErrorCountry("Please select country")
      formIsValid=false;
    }
    if(!locationdata.state_id) {
      setErrorState("Please select state")
      formIsValid=false;
    }
    if(!locationdata.city_id) {
      setErrorCity("Please select city")
      formIsValid=false;
    }
    return formIsValid;
  }

  const AddQuestionValidation = () => {
    let formIsValid = true;
    let firstSpace =/^[^\s].*/  

    if(!firstSpace.test(questionData)) {
      setErrorQuestion("Invalid reply text")
      formIsValid=false;
    }

    if(questionData.length===0 || questionData.length > 1000) {
      if(questionData.length === 0){
        setErrorQuestion("Please enter question you want to ask.")
      }
      else{
        setErrorQuestion("Question should not be greater than 1000 characters.")
      }
      formIsValid=false;
    }
    if(!selectLocation || (selectLocation===null)) {
     setErrorLocation("Please select country")
      formIsValid=false;
    }
    // if(!locationdata.city_id) {
    //   setErrorCity("Please select city")
    //   formIsValid=false;
    // }
    return formIsValid;
  }
  

  const PostQuestion = () => {
    setLoader(true)
    if (AddQuestionValidation()){
   let data ={question_text:questionData,
  country_id:selectLocation.country_id,
state_id:selectLocation.state_id,
city_id:selectLocation.city_id };
    (async () => {
      try {
          const res = await call(
              'post',
              '/add-question',
              null,
              data,
              token
          )
          setResponseSuccess(res.data.message)
          setLoader(false)
          setSelectLocation("")
          setQuestionData("")
      } catch (err) {
        setLoader(false)
          console.log(err)
      }
    })() }
    else{
      setLoader(false)
    }
  }

  const EditQuestion = () => {
    setLoader(true)
    if (AddQuestionValidation()){
   let data ={question_text:questionData,
  country_id:selectLocation.country_id,
state_id:selectLocation.state_id,
city_id:selectLocation.city_id };
    (async () => {
      try {
          const res = await call(
              'patch',
              `/update-question/${editDetails.id}`,
              null,
              data,
              token
          )
          setResponseSuccess(res.data.message)
          setLoader(false)
          setSelectLocation("")
      } catch (err) {
        setLoader(false)
          console.log(err)
      }
  })() }
    else{
      setLoader(false)
    }
  }

  const onChange = (e) => {
    setQuestionData(e.target.value);
}

const locationHandle = (e) => {
  console.log(e.target.value)
  if(e.target.value){
  let obj = JSON.parse(e.target.value)
  console.log(obj)
  setSelectLocation(obj) }
}

    return (
      <>
        <main className="page_wrapper">
          <section className="dashboard_section d-flex">
            <div>
              <Sidebar />
            </div>

            <section className="dashboard_main_section">
              <Header title="Questions" />
              <section className="dasboard_main_area list-experts-area add-question">
                <div className="col-8">
                  <div className="top mb-5">
                    <Link to={"../question"}>
                      <KeyboardBackspaceIcon /> Add New Question
                    </Link>
                  </div>
                  <div className="text-center">
                  {responseSuccess && (
                                     <label className="label-input100 text-success" >
                                         {responseSuccess}
                                        </label>
                                )}
                </div>  
                  <div className="account">
                    <Form>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Label className="label-input100">
                          Ask Question - 100 pts (1000 Characters Max.)
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder="Enter Text"
                          onChange={onChange}
                          value={questionData}
                          onClick={errorQueStatus}
                        />
                      </Form.Group>
                    </Form>
                    <div>{errorQuestion && (
                                     <label className="label-input100 text-danger" >
                                        {errorQuestion}
                                        </label>
                                )}</div>
                  </div>
                  <div className="position-relative">
                    <div className="location w-100">
                      <Form.Label className="label-input100">
                        Select Location
                      </Form.Label>
                      <Form.Select aria-label="Default select example" onClick={errorLocStatus} value={JSON.stringify(selectLocation)} onChange={locationHandle}>
                        <option value="null">See Added Locations</option>
                        {locationList.data?.map((item, index) => {
                          console.log(item)
                      return <option key={index} value={JSON.stringify(item)}>{item.location_name}</option>;
                    })}
                      </Form.Select>
                      <div>{errorLocation && (
                                     <label className="label-input100 text-danger" >
                                        {errorLocation}
                                        </label>
                                )}</div>
                    </div>
                  <span class="add-icon" onClick={openModal}>
                      <img src={addcircle} alt="" />
                    </span>
                  </div>
                  <div className="d-flex justify-content-end mt-4">
                  <Link to="/question">  <button className="btn btn-outline me-3">Cancel</button></Link>
                  {editDetails ? <button disabled = {loader} className="btn btn-xl" onClick={EditQuestion}>{loader ? <div className = "custom-loader-2">
                    <ThreeDots 
                      height="50" 
                      width="40" 
                      radius="9"
                      color="#ffffff" 
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}/>
                      </div>  : "Post"}</button> :
                    <button disabled = {loader} className="btn btn-xl" onClick={PostQuestion}>{loader ? <div className = "custom-loader-2">
                    <ThreeDots 
                      height="50" 
                      width="40" 
                      radius="9"
                      color="#ffffff" 
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}/>
                      </div>  : "Post"}</button>}
                  </div>
                </div>
              </section>
              <Modal
                show={isOpen}
                onHide={closeModal}
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>Add a Location</Modal.Title>
                </Modal.Header>

                <Modal.Body className="px-5">
                  <div className="">
                    <div className="add-location">
                      <Form.Label className="label-input100 ">
                        Country
                      </Form.Label>
                      <Form.Select aria-label="Default select example" onClick={setcountry} value={locationdata.country_id} onChange={handleCountryData("country_id")}>
                        <option value="">Select Country</option>
                        {countryList.countryList?.map((item, index) => {
                      return <option key={index} value={item.id}>{item.country_name}</option>;
                    })}
                      </Form.Select>
                    <div>  {errorCountry && (
                                     <label className="label-input100 text-danger" >
                                        {errorCountry}
                                        </label>
                                )}</div>
                      <Form.Label className="label-input100 ">State</Form.Label>
                      <Form.Select aria-label="Default select example" onClick={setstate} value={locationdata.state_id} onChange={handleCountryData("state_id")}>
                        <option value="">Select State</option>
                        {locationStateData.stateList?.map((item, index) => {
                      return <option key={index} value={item.id}>{item.state_name}</option>;
                    })}
                      </Form.Select>
                      <div>{errorState && (
                                     <label className="label-input100 text-danger" >
                                        {errorState}
                                        </label>
                                )}</div>
                      <Form.Label className="label-input100 ">City</Form.Label>
                      <Form.Select aria-label="Default select example" onClick={setcity} value={locationdata.city_id} onChange={handleCountryData("city_id")}>
                        <option value="">Select City</option>
                        {locationCityData.cityList?.map((item, index) => {
                      return <option key={index} value={item.id}>{item.city_name}</option>;
                    })}
                      </Form.Select>
                      <div>{errorCity && (
                                     <label className="label-input100 text-danger" >
                                        {errorCity}
                                        </label>
                                )}</div>
                      <button className="btn btn-xl w-100 h-42 p-2 my-4" onClick={AddLocation}>Add</button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </section>
          </section>
        </main>
      </>
    );
  }
