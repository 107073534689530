import { useHistory, useParams, useLocation } from "react-router-dom";
import logo from "../../../assets/img/logo.png";
import {useState, useEffect} from 'react'
import { call } from "../../../utils/apiCall";

function PaymentSuccess() {
    const history = useHistory()
    const {user_id, amount, no_of_points} = useParams()
     const id = window.location.search.split("?")[1];
    const dataArray = id.split("&")
    const paymentId = dataArray[0].split("=")[1]
    const PayerID = dataArray[2].split("=")[1]
    // const loginData = JSON.parse(localStorage.getItem("loginData"))    
    // let accessToken
    // if(loginData){
    //     accessToken = loginData.accessToken
    // }
    const [second, setSecond] = useState(3)
    const [showContent, setShowContent] = useState(false)
    const [payment, setPayment] = useState(true)
    if(showContent === true){
        setInterval(() => {
            setSecond(second - 1)
        }, 1000)
        if(second === 0){
            window.close()
        }
    }
    

    const makePaymentDb = () => {
      let accessToken = localStorage.getItem("accessToken");
        // const config = {
        //     headers: { authorization: `${accessToken}` },
         
        // };
        const payData = {
          paymentId : paymentId,
          PayerID : PayerID
      };
        (async () => {
              try {
                  const res = await call(
                      'get',
                      `/payment-successful/${user_id}/${amount}/${no_of_points}/web`,
                      payData,
                      null,
                      accessToken
                  )
                  console.log(res.data)
                  if(res.data.success === true){
                    setShowContent(true)
                    setPayment(true)
                }
                else{
                    history.push("/dashboard")
                    alert(res.data.message)
                }
                } catch (err) {
                  console.log(err)
                  history.push("/dashboard")
                  alert("Could not transfer amount. Please try again later.")
              }
          })()

        // apis.get(`/payment-successful/${user_id}/${amount}/${points}/web`, config)
        // .then((response) => {
        //     if(response.data.success === true){
        //         setShowContent(true)
        //         setPayment(true)
        //     }
        //     else{
        //         history.push("/dashboard")
        //         alert(response.data.message)
        //     }
        // })
        // .catch((error) => {
        //     history.push("/dashboard")
        //     alert("Could not transfer amount. Please try again later.")
        // })
    }

    
    //   console.log('FCM Client Token: ', currentToken);
    //   setTokenFound(true);
    //   let tokenData = {
    //     fcm_token: currentToken,
    //     device_os_type: "android"
    //   };
    //   (async () => {
    //     try {
    //         const res = await call(
    //             'post',
    //             '/update-fcm',
    //             null,
    //             tokenData,
    //             token
    //         )
    //         console.log(res.data)
    //       } catch (err) {
    //         console.log(err)
    //     }
    // })()

    useEffect(() => {
        makePaymentDb()
        console.log("shubham")
    }, [])

    
    return (
      showContent ? 
    <div className="limiter">
        <div className="container-login100">
          <div className="wrap-login100">
            <form className="login100-form validate-form">
            <span className="login100-form-title" style={{color:"#22bb33"}}>Transcation Successful!</span>
            <br/>
                  <br/>
                  <p style={{color:"#808080"}}>Amount Buy was successful. Closing the pop-up window...</p>
                  <br/>
                  <h6 style={{color:"#808080"}}>Please do not refresh this window.</h6>
                </form>
            <div className="login100-more">
              <div className="logo m-4 ">
                <img src={logo} className="img-fluid" alt="img" />
              </div>
            </div>
          </div>
        </div>
    </div> : <>Wait....</>
  );
  }

  export default PaymentSuccess;