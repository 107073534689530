import { useState, useEffect } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Form from "react-bootstrap/Form";
import AddIcon from "@mui/icons-material/Add";
import cards from "../../../assets/img/cards.png";
import trash from "../../../assets/img/delete.svg";
import Sidebar from "../../../components/layout/Sidebar";
import Header from "../../../components/layout/Header";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Icon from "@mui/material/Icon";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { call } from "../../../utils/apiCall";
import { Button } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import useAuth from "../../../hooks/useAuth";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import AccountNotifications from "./account-notifications";
import AccountSettings from "./AccountSettings";
import App from "../../../components/imageverify";
import Loading from '../../../components/Loaders/Loading'
import Loader from '../../../components/Loaders/ComponentLoader'
import deleteIcon from "../../../assets/img/deleteIcon.svg";
import userDp from "../../../assets/img/user.png"

const Account = () => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(true);
  const [countryList, setCountryList] = useState();
  const [stateList, setStateList] = useState();
  const [cityList, setCityList] = useState();
  const [dropdownData, setDropdownData] = useState();
  const [newStateList, setNewStateList] = useState();
  const [newCityList, setNewCityList] = useState();
  const [isOpenPass, setIsOpenPass] = useState(false);
  const [isOpenAccount, setIsOpenAccount] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [formData, setFormData] = useState({
    email: "null",
    firstname: "null",
    lastname: "null",
    oldpass: "",
    newpass: "",
    confirmNewpass:"",
    dob: "null",
    country: "null",
    state: "null",
    city: "null",
    profile_pic: "null",
    screen_name: "null",
    phone: "null",
    location: "null",
    language:"null",
    employer:"null",
    gender:"null",
    religion:"null",
    marital:"null",
    kids:"null",
    food:"null",
    currentstatus:"null",
    fb_link:"null",
    remove_image:false,
    email_verified:"null",
    username:"null"
  })
  const [locationdata, setLocationdata] = useState({
		country:"",
		state:"",
		city:""
	});
  const [locationdata2, setLocationdata2] =useState([])
  const [languageData, setLanguageData] =useState([])
  const [errorMessage, setErrorMessage] = useState('')
const [errorFirstName, setErrorFirstName] = useState('')
const [errorLastName, setErrorLastName] = useState('')
const [errorphone, setErrorphone] = useState('')
const [errorPass, setErrorPass] = useState('')
const [errorcountry, setErrorcountry] = useState('')
const [errorstate, setErrorstate] = useState('')
const [errorcity, setErrorcity] = useState('')
const [errorStatus, setErrorStatus] = useState('')
const [errorGender, setErrorGender] = useState('')
const [notificationData, setNotificationData] = useState("");
const [imgData, setImgData] = useState(null);
const [update, setUpdate] = useState(true);
const [loader, setLoader] = useState(false);

  const { updateDetails } = useAuth();
  const { logout } = useAuth()

  const openPassModal = () => setIsOpenPass(true);
  const closePassModal = () => {
    setFormData((prevState) => ({
      ...prevState,
      oldpass: "",
      newpass: "",
      confirmNewpass:""
    }));
    setErrorPass("");
    setIsOpenPass(false);}

  const setStatus = () => {
    setErrorMessage("");
  };

  const setfirstname = () => {
    setErrorFirstName("");
  };
  const setlastname = () => {
    setErrorLastName("");
  };

  const setphone = () => {
    setErrorphone("");
  };
  const setcountry = () => {
    setErrorcountry("");
  };
  const setstate = () => {
    setErrorstate("");
  };
  const setcity = () => {
    setErrorcity('')
  }
  const setcurrStatus = () => {
    setErrorStatus('')
  }
  
  const setGender = () => {
    setErrorGender('')
  }
  
  
  useEffect (() => {
    let token = localStorage.getItem("accessToken"); 
    (async () => {
      try {
          const res = await call(
              'get',
              `/user?for_browser=true`,
              null,
              null,
              token
          )
          setUserData(res.data.data)
          setLocationdata2(res.data.data.location)
          setLanguageData(res.data.data.language)
          setNotificationData(res?.data?.notificationSetting);
          setImgData(res?.data?.data?.profile_image)
          setLoading(false);
         
      } catch (err) {
          console.log(err)
      }
  })()
  }, [update, loading])

  useEffect(() => {
    if(userData){
      // if(/\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(userData.profile_image)){
    
       
      // }
    setFormData({
      ...formData,
      firstname: userData.first_name,
      lastname: userData.last_name,
      dob:userData.date_of_birth,
      phone:userData.mobile_no,
      email:userData.email_id,
      country:userData.country.id,
      state:userData.state.id,
      city:userData.city.id,
      currentstatus:userData.current_status.id,
      gender:userData.gender.id,
      employer:userData.employer,
      religion:userData.religion.religion_id,
      marital:userData.marital_status.id,
      kids:userData.kids,
      food:userData.food_preferences.id,
      fb_link:userData.fb_link,
      email_verified:userData.isEmailVerified,
      username:userData.username
    });
  }
  console.log(formData.profile_pic)
  }, [userData]);

  // Country Dropdown START//

  useEffect(() => {
    (async () => {
      try {
        const res = await call("get", "/country", null, null);
        setCountryList(res.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useEffect(() => {
    if (formData && formData.country) {
      (async () => {
        try {
          const res = await call(
            "get",
            "/state/" + formData.country,
            null,
            null
          );
          setStateList(res.data);
        } catch (err) {
          console.log(err);
        }
      })();
    }
  }, [formData.country]);

  useEffect(() => {
    if (formData && formData.state) {
      (async () => {
        try {
          const res = await call("get", "/city/" + formData.state, null, null);
          setCityList(res.data);
        } catch (err) {
          console.log(err);
        }
      })();
    }
  }, [formData.state]);

  ////////  Country second dropdown  END ////////////////

  useEffect(() => {
    if (locationdata.country !== "") {
      (async () => {
        try {
          const res = await call(
            "get",
            "/state/" + locationdata.country.id,
            null,
            null
          );
          console.log(res.data);
          setNewStateList(res.data);
        } catch (err) {
          console.log(err);
        }
      })();
    }
  }, [locationdata.country]);

  useEffect(() => {
    if (locationdata.state !== "") {
      (async () => {
        try {
          const res = await call(
            "get",
            "/city/" + locationdata.state.id,
            null,
            null
          );
          console.log(res.data);
          setNewCityList(res.data);
        } catch (err) {
          console.log(err);
        }
      })();
    }
  }, [locationdata.state]);

  ////////  SECONd country END ////////////////

  //USER FORM DATA //

  useEffect(() => {
    (async () => {
      try {
        const res = await call("get", "/user-form-data", null, null);
        setDropdownData(res.data);
      } catch (err) {}
    })();
  }, []);

  //USER FORM DATA//

  const onChangeData = (input) => (e) => {
    // input value from the form
    const { value } = e.target;
    if (input === "country") {
      setFormData((prevState) => ({
        ...prevState,
        state: false,
        city: false,
      }));

      setFormData((prevState) => ({
        ...prevState,
        [input]: value,
      }));
    } else if (input === "state") {
      setFormData((prevState) => ({
        ...prevState,
        city: false,
      }));

      setFormData((prevState) => ({
        ...prevState,
        [input]: value,
      }));
    } else
      setFormData((prevState) => ({
        ...prevState,
        [input]: value,
      }));
  };

  const countryData = (input) => (e) => {
    // input value from the form
    let obj = JSON.parse(e.target.value);
    console.log(obj);
    if (input === "country") {
      setLocationdata((prevState) => ({
        ...prevState,
        state: false,
        city: false,
      }));

      setLocationdata((prevState) => ({
        ...prevState,
        [input]: obj,
      }));
    } else if (input === "state") {
      setLocationdata((prevState) => ({
        ...prevState,
        city: false,
      }));

      setLocationdata((prevState) => ({
        ...prevState,
        [input]: obj,
      }));
    }
    // if(input==="state"){
    //   setFormData(prevState => ({
    //     ...prevState,
    //     ["input"]: value
    // }));
    // }
    //updating for data state taking previous state and then adding new value to create new object
    else
      setLocationdata((prevState) => ({
        ...prevState,
        [input]: obj,
      }));
  };

  // const AddPlaceValidation = () => {
  //   let formIsValid = true;
  //   if(!locationdata.country_id || (locationdata.country_id==="Select Country")) {
  //     setErrorCountry("Please select country")
  //     formIsValid=false;
  //   }
  //   if(!locationdata.state_id) {
  //     setErrorState("Please select state")
  //     formIsValid=false;
  //   }
  //   if(!locationdata.city_id) {
  //     setErrorCity("Please select city")
  //     formIsValid=false;
  //   }
  //   return formIsValid;
  // }

  const passValidation = () => {
    let passwordvalid =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    let formIsValid = true;

    if (!passwordvalid.test(formData.confirmNewpass)) {
      setErrorPass(
        " Confirm Password must atleast 8 characters containing one uppercase, lowercase, number and special character"
      );
      formIsValid = false;
    }

  

    if (!formData.newpass) {
      setErrorPass("New Password should not empty");
      formIsValid = false;
    }
   

    if (formData.newpass !== formData.confirmNewpass) {
      setErrorPass("New and confirm password must be same");
      formIsValid = false;
    }
    if (!formData.confirmNewpass) {
      setErrorPass("Confirm Password should not empty");
      formIsValid = false;
    }
    if (!passwordvalid.test(formData.newpass)) {
      setErrorPass(
        " New Password must atleast 8 characters containing one uppercase, lowercase, number and special character"
      );
      formIsValid = false;
    }

    if (formData.oldpass === formData.newpass) {
      setErrorPass("New Password must not be same as old password");
      formIsValid = false;
    }
    if (!passwordvalid.test(formData.oldpass)) {
      setErrorPass(
        " Current Password must atleast 8 characters containing one uppercase, lowercase, number and special character"
      );
      formIsValid = false;
    }
    if (!formData.oldpass) {
      setErrorPass("Current Password should not empty");
      formIsValid = false;
    }
    return formIsValid;
  };

  const emailValidation = () => {
    let emailvalid =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let formIsValid = true;
    if (!emailvalid.test(formData.email)) {
      setErrorMessage("Please enter valid email");
      formIsValid = false;
    }
    if (!formData.email) {
      setErrorMessage("Email is required");
      formIsValid = false;
    }
    return formIsValid;
  };

  const checkValidation = () => {
    let firstvalid = /^[a-zA-Z\s\.]+$/;
    let phonevalid = /^([+]\d{2})?\d{10}$/;
    let formIsValid = true;

    if (!firstvalid.test(formData.firstname)) {
      setErrorFirstName("Invalid first Name");
      formIsValid = false;
    }
    if (formData.firstname.length < 2) {
      setErrorFirstName(
        "Must be exactly greater than or equal to 2 characters"
      );
      formIsValid = false;
    }
    if (!firstvalid.test(formData.lastname)) {
      setErrorLastName("Invalid last Name");
      formIsValid = false;
    }
    if (formData.lastname.length < 2) {
      setErrorLastName("Must be exactly greater than or equal to 2 characters");
      formIsValid = false;
    }

if(!phonevalid.test(formData.phone)) {
  setErrorphone("Invalid Mobile Number")
  formIsValid=false;
}
if(!formData.phone) {
  setErrorphone("Phone Number is required")
  formIsValid=false;
}
if(!formData.country || (formData.country==="Select Country")) {
  setErrorcountry("Please select country")
  formIsValid=false;
}
if(!formData.state || (formData.state==="Select State")) {
  setErrorstate("Please select state")
  formIsValid=false;
}
if(!formData.city || (formData.city==="Select City")) {
  setErrorcity("Please select city")
  formIsValid=false;
}
if(!formData.currentstatus ) {
  setErrorStatus("Please select status")
  formIsValid=false;
}
if(!formData.gender) {
  setErrorGender("Please select gender")
  formIsValid=false;
}

    return formIsValid;
  };

  useEffect(() => {
    let filterId = locationdata2.map((item) => {
      return {
        country_id: item.country.id,
        state_id: item.state.id,
        city_id: item.city.id,
      };
    });
    setFormData({
      ...formData,
      location: filterId,
    });
  }, [locationdata2]);

  useEffect(() => {
    console.log(languageData);
    let filterId = languageData.map((item) => {
      return {
        language_id: item.id,
      };
    });
    setFormData({
      ...formData,
      language: filterId,
    });
    console.log(filterId);
  }, [languageData]);

  const submitPlace = (e) => {
    e.preventDefault();
    
if(locationdata2.some(user=>user.city.id === locationdata.city?.id))
{
  alert("Place already added. Chose another Place.")
   
} 
else if (!locationdata.city)
{
  alert("Please select all fields Country, State and City")
  
}

else setLocationdata2((prevState) => [...prevState, locationdata]);
setLocationdata({ country: "", state: "", city: "" });
  };

  const handleDeleteLocation = (input) => (e) => {
    const newTaskArray = locationdata2.filter((task) => task.city.id !== input);
    console.log(newTaskArray);
    setLocationdata2(newTaskArray);
  };

  const handleLanguageChange = (input) => (e) => {
    let obj = JSON.parse(e.target.value);

    setLanguageData((prevState) => [...prevState, obj]);
  };

  const handleDeleteLanguage = (input) => (e) => {
    const newTaskArray = languageData.filter((task) => task.id !== input);
    console.log(newTaskArray);
    setLanguageData(newTaskArray);
  };

  const handlePicture = input => e => {
    if (e.target.files[0]) {
      console.log("picture: ", e.target.files);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      setFormData(prevState => ({
        ...prevState,
        [input]: e.target.files[0]
    }));
  
      reader.readAsDataURL(e.target.files[0]);
      // console.log( imgData)
    }

     }

     const pictureStatus = (e) => {
      e.preventDefault();
      setFormData((prevState) => ({
        ...prevState,
        remove_image:true
      }));
      setImgData(null);
     }

  const handleFormSubmit = async (data1) => {
    setLoader(true);
    try {
      const res = await updateDetails(data1);
      setLoader(false);
      alert("Users Details Updated Successfully");
    window.location.reload()
    } catch (e) {
      console.log(e);
      alert(e.response.data.message);
      setLoader(false)
    }
  };

  const submitData = (type) => (e) => {
    e.preventDefault();

    if (type === "allData") {
   
      if (checkValidation()) {
        console.log(formData.language);
        const data1 = new FormData();
        data1.append("first_name", formData.firstname);
        // data1.append('username', formData.email)
        data1.append("last_name", formData.lastname);
        data1.append("mobile_no", formData.phone);
        data1.append("date_of_birth", formData.dob);
        //    data1.append('mobile_no', null)
        data1.append("country_id", formData.country);
        data1.append("state_id", formData.state);
        data1.append("city_id", formData.city);
        data1.append("occupation_id", "0");
        data1.append("current_status_id", formData.currentstatus);
        data1.append("religion_id", formData.religion);
        data1.append("marital_status_id", formData.marital);
        data1.append("gender_id", formData.gender);
        data1.append("food_preferences_id", formData.food);
        data1.append("kids", formData.kids);
        data1.append("employer", formData.employer);
        data1.append("fb_link", formData.fb_link);
        data1.append('profile_pic', formData.profile_pic)
        data1.append("location", JSON.stringify(formData.location));
        data1.append("language", JSON.stringify(formData.language));
        data1.append("remove_profile_pic", formData.remove_image);
        for (var pair of data1.entries()) {
          console.log(pair[0] + ", " + pair[1]);
        }
        handleFormSubmit(data1, "allData");
      }
    } else if (type === "email") {

      if (emailValidation()) {
        const data1 = new FormData();
        data1.append("email_id", formData.email);
        // data1.append('username', formData.email)
        const token = localStorage.getItem("accessToken");
        if(formData.email===formData.username){
          setErrorMessage("Email already Verified. Enter different email ID. ");
        }
        else {
        setLoader(true);
        (async () => {
          try {
            const res = await call("patch", "/update-user", null, data1, token);
            console.log(res.data);
            setLoader(false);
            setFormData((prevState) => ({
              ...prevState,
              email_verified:res.data?.data?.isEmailVerified
            }));
            setErrorMessage(res.data?.message);
          } catch (err) {
            console.log(err);
            setLoader(false);
          }
        })();
      }
    }
    } else if (type === "password") {
      if (passValidation()) {
        const data1 = new FormData();
        data1.append("password", formData.oldpass);
        data1.append("new_password", formData.newpass);
        const token = localStorage.getItem("accessToken");
        (async () => {
          try {
            const res = await call("patch", "/update-user", null, data1, token);
            console.log(res.data);
            setFormData((prevState) => ({
              ...prevState,
              oldpass: "",
              newpass: "",
              confirmNewpass:""
            }));
            setErrorPass(res.data.message);
          } catch (err) {
            console.log(err);
          }
        })();
      }
    }

    else if (type==="deleteProfile"){
      setLoader(true)
      const token = localStorage.getItem("accessToken");
      let userData= {user_id: ""};
      (async () => {
        try {
          const res = await call("patch", "/change-user-status", null, userData, token);
          console.log(res.data);
          setLoader(false);
          setIsOpenAccount(false);
          logout();
        } catch (err) {
          console.log(err);
          setLoader(false);
          setIsOpenAccount(false);
        }
      })();
    }

  };

  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const dateValid = formatDate(new Date(new Date().getFullYear() - 18, 11, 31));

  console.log(locationdata2);
  console.log(locationdata);
  console.log(formData.location);
  console.log(languageData);
  console.log(imgData);
  console.log(formData);

  return  (
    <>
      <main className="page_wrapper">
        <section className="dashboard_section d-flex">
          <div>
            <Sidebar />
          </div>

          <section className="dashboard_main_section">
            <Header title="Account Settings" />
            <section className="dasboard_main_area list-experts-area">
              <div className="account list-experts-main">
                <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example">
                  <Tab eventKey="profile" title="Profile">
                  {loader ? <Loader/>:null}
                    {loading ? (
                      <div style={{paddingTop:"20%"}}>
                      <Loading/>
                      </div>
                    ) : <form>
                    <div className="tabs_pannel " id="tab1">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="row">
                            <div className="col-lg-8 order-lg-1 order-2 ">
                              <div className="row">
                                <div className="col-lg-6">
                                  <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlInput1"
                                  >
                                    <Form.Label className="label-input100 ">
                                      First Name
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      value={formData.firstname}
                                      onClick={setfirstname}
                                      onChange={onChangeData("firstname")}
                                    />
                                  </Form.Group>
                                  {errorFirstName && (
                                   <label className="label-input100 text-danger" >
                                      {errorFirstName}
                                      </label>
                              )}
                                </div>
                                <div className="col-lg-6">
                                  <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlInput1"
                                  >
                                    <Form.Label className="label-input100 ">
                                      Last Name
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      value={formData.lastname}
                                      onClick={setlastname}
                                      onChange={onChangeData("lastname")}
                                    />
                                  </Form.Group>
                                  {errorLastName && (
                                   <label className="label-input100 text-danger" >
                                      {errorLastName}
                                      </label>
                              )}
                                </div>
                              
                              </div>
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="date mb-3">
                                    <Form.Group controlId="dob">
                                      <Form.Label className="label-input100 ">
                                        DOB
                                      </Form.Label>
                                      <Form.Control
                                        type="date"
                                        name="dob"
                                        min="1940-01-01" max={dateValid}
                                        value={formData.dob}
                                        onChange={onChangeData("dob")}
                                      />
                                    </Form.Group>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="date mb-3">
                                    <Form.Group
                                      className="mb-3"
                                      controlId="exampleForm.ControlInput1"
                                    >
                                      <Form.Label className="label-input100 ">
                                        Phone Number
                                      </Form.Label>
                                      <Form.Control
                                         value={formData.phone}
                                         onChange={onChangeData("phone")}
                                         onClick={setphone}
                                      />
                                      {/* <span>Change</span> */}
                                      {errorphone && (
                                   <label className="label-input100 text-danger" >
                                      {errorphone}
                                      </label>
                              )}
                                    </Form.Group>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="email">
                                    <Form.Group
                                      className="mb-2"
                                      controlId="exampleForm.ControlInput1"
                                    >
                                      <Form.Label className="label-input100 ">
                                        Email ID
                                      </Form.Label>
                                      <Form.Control
                                        type="email-id"
                                        value={formData.email}
                                        onClick={setStatus}
                                        onChange={onChangeData("email")}
                                      />
                                    <div className="suggestion"> {formData.email_verified===0 || formData.email_verified===false?<p><i>New Email is not verified yet. Click to Resend Link</i></p>:""}    <span onClick={submitData("email")}>Change</span></div>
                                  
                                    </Form.Group>
                                    {errorMessage && (
                                   <label className="label-input100 text-danger" >
                                      {errorMessage}
                                      </label>
                              )}
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="email">
                                    <Form.Group
                                      className="mb-2"
                                      controlId="exampleForm.ControlInput1"
                                    >
                                      <Form.Label className="label-input100 ">
                                        Password
                                      </Form.Label>
                                      <Form.Control
                                        type="password"
                                        placeholder="**********"
                                      />
                                      <span onClick={openPassModal}>Change</span>
                                    </Form.Group>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="country">
                                    <Form.Label className="label-input100 ">
                                      Country
                                    </Form.Label>
                                    <Form.Select aria-label="Default select example" value={formData.country} onClick={setcountry}  onChange={onChangeData("country")}>
                                    <option defaultValue>Select Country</option>
                      {countryList?.countryList?.map((item, index) => {
                    return <option key={index} value={item.id}>{item.country_name}</option>;
                  })}
                                    </Form.Select>
                                    {errorcountry && (
                                   <label className="label-input100 text-danger" >
                                      {errorcountry}
                                      </label>
                              )}
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <Form>
                                    <div className="country">
                                      <Form.Label className="label-input100 ">
                                        State
                                      </Form.Label>
                                      <Form.Select aria-label="Default select example" value={formData.state} onClick={setstate}  onChange={onChangeData("state")}>
                                      <option defaultValue>Select State</option>
                                      {stateList?.stateList?.map((item, index) => {
                    return <option key={index} value={item.id}>{item.state_name}</option>;
                  })}

                                      </Form.Select>
                                      
{errorstate && (
                                   <label className="label-input100 text-danger" >
                                      {errorstate}
                                      </label>
                              )}
                                    </div>
                                  </Form>
                                </div>
                                <div className="col-lg-12 my-2">
                                  <Form.Label className="label-input100 ">
                                    City
                                  </Form.Label>
                                  <Form.Select aria-label="Default select example" value={formData.city} onClick={setcity}  onChange={onChangeData("city")}>
                                  <option defaultValue>Select City</option>
                                  {cityList?.cityList?.map((item, index) => {
                    return <option key={index} value={item.id}>{item.city_name}</option>;
                  })}
                  
                                  </Form.Select>
                                  {errorcity && (
                                   <label className="label-input100 text-danger" >
                                      {errorcity}
                                      </label>
                              )}
                                </div>
                              
                                <div className="col-lg-12 my-4">
                                <div className="row bg" style={{backgroundColor:"#ffffff", padding:"30px"}}>
            <div className="col-lg-10 offset-lg-2 col-sm-12">
              <p>
                <strong>
                  Places you wish to ask or answer questions/ you have been to
                </strong>
              </p>
            </div>
            <div className="col-lg-6 offset-lg-3 col-sm-12 mt-4">
              <div className="left">
               <div className="label-input100">Country Name</div>
                <select
                  className=" classNameic"
                  aria-label="Default select example"
                  name="country_id"
                   value={JSON.stringify(locationdata.country)}
                  // // onClick={setcountry}
                    onChange={countryData("country")}
                >
                  <option value="null">Select Country</option>
                  {countryList?.countryList?.map((item, index) => {
                
                    return (
                      <option value={JSON.stringify(item)} >{item.country_name}</option>
                    );
                  })}
                </select>
                {/* {errorCountry && (
                                   <label className="label-input100 text-danger" >
                                      {errorCountry}
                                      </label>
                              )} */}
              </div>
            </div>
            <div className="col-lg-6 offset-lg-3 col-sm-12 mt-3">
              <div className="left">
             <div className="label-input100">State Name</div>
                <select
                  className=" classNameic"
                  aria-label="Default select example"
                  name="state_id"
                   value={JSON.stringify(locationdata.state)}
                  // onClick={setstate}
                   onChange={countryData("state")}
                >
                  <option value="null">Select State </option>
                  {newStateList?.stateList?.map((item, index) => {
                    return <option key={item.id} value={JSON.stringify(item)}>{item.state_name}</option>;
                  })}
                </select>
                {/* {errorState && (
                                   <label className="label-input100 text-danger" >
                                      {errorState}
                                      </label>
                              )} */}
              </div>
            </div>
            <div className="col-lg-6 offset-lg-3 col-sm-12 mt-3">
            <div className="label-input100">City Name</div>
              <select
                className=" classNameic"
                aria-label="Default select example"
                name="city_id"
                 value={JSON.stringify(locationdata.city)}
                  // onClick={setcity}
                  onChange={countryData("city")}
              >
                <option value="null">Select City</option>
                {newCityList?.cityList?.map((item, index) => {
                    return <option key={index} value={JSON.stringify(item)}>{item.city_name}</option>;
                  })}
              </select>
              {/* {errorCity && (
                                   <label className="label-input100 text-danger" >
                                      {errorCity}
                                      </label>
                              )} */}
            </div>
            <div className="col-lg-6 offset-lg-3 col-sm-12 mt-4">
              <div className="text-center">
                <div className="">
                  <p>
                  <button className="btn-xl" style={{padding: "5px 20px"}} onClick={submitPlace}>
                    Add 
                    </button>
                  </p>
                </div>

              </div>
            </div>
            <div className="row">
            
            
            {/* {errorPlace && (
                                   <label className="label-input100 text-danger" >
                                      {errorPlace}
                                      </label>
                              )} */}
            <div className="col-sm-12 p-0 mt-2">
              <div className="d-flex flex-wrap">
              {locationdata2?.map((item, index) => {
                    return   <span key={item.city.id}>
                <div 
                  class="toast d-block m-1"
                  role="alert"
                  aria-live="assertive"
                  aria-atomic="true"
                >
                  <div class="toast-header">
                  
                    <strong>{item.city.city_name}, {item.state.state_code}, {item.country.country_code}</strong>
                    {item.city.id!==formData.city?
                    <Button
                    onClick={handleDeleteLocation(item.city.id)}
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="toast"
                      aria-label="Close"
                    ></Button>  : ""}
                  
                  </div>
                </div>
             
              </span>
                  })
                  }
              
               {/* <span>
                <div
                  class="toast d-block mx-2"
                  role="alert"
                  aria-live="assertive"
                  aria-atomic="true"
                >
                  <div class="toast-header">
                    <strong>tdyhtghBootstrap</strong>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="toast"
                      aria-label="Close"
                    ></button>
                  </div>
                </div>
              </span>  */}
              </div>
            </div>
          </div>
          </div>
                                </div>
                                <div className="col-lg-6">
                                <div className="country mb-2">
                                  <Form.Label className="label-input100 ">
                                    Current Status
                                  </Form.Label>
                                  <Form.Select aria-label="Default select example" value={formData.currentstatus} onClick={setcurrStatus} onChange={onChangeData("currentstatus")}>
                                  <option value="">Select Status</option>
                                  {dropdownData?.currentStatusData?.map((item, index) => {
                    return <option key={index} value={item.id}>{item.name}</option>;
                  })}
                                  </Form.Select>
                                  </div>
                                  {errorStatus && (
                                   <label className="label-input100 text-danger" >
                                      {errorStatus}
                                      </label>
                              )}
                                </div>
                                <div className="col-lg-6">
                                <div className="country mb-2">
                                  <Form.Label className="label-input100 ">
                                    Occupation
                                  </Form.Label>
                                  <Form.Select aria-label="Default select example" value={userData.occupation.id}>
                                  <option value="">Select Occupation</option>
                  <option value="1">Teacher</option>
                  <option value="2">Psychologist</option>
                  <option value="3">Nurse</option>
                  <option value="4">Engineer</option>
                  <option value="5">Technician</option>
                  <option value="6">Architect</option>
                  <option value="7">Pharmacist</option>
                  <option value="8">Software Developer</option>
                  <option value="9">Veterinarian</option>
                  <option value="10">Scientist</option>
                                  </Form.Select>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="country mb-2">
                                    <Form.Label className="label-input100 ">
                                      Gender
                                    </Form.Label>
                                    <Form.Select aria-label="Default select example" value={formData.gender} onClick={setGender} onChange={onChangeData("gender")}>
                                    <option value="">Select Status</option>
                                    {dropdownData?.genderData?.map((item, index) => {
                    return <option key={index} value={item.id}>{item.name}</option>;
                  })}
                                    </Form.Select>
                                  </div>
                                  {errorGender && (
                                   <label className="label-input100 text-danger" >
                                      {errorGender}
                                      </label>
                              )}
                                </div>
                                <div className="col-lg-6">
                                  <div className="country mb-2">
                                    <Form.Label className="label-input100 ">
                                      Language
                                    </Form.Label>
                                    <Form.Select aria-label="Default select example" value=""
                  onChange={handleLanguageChange("language")}>
                                   <option selected value="0">Select one/multiple language</option>
                                    {dropdownData?.languageData?.map((item, index) => {
                    return <option key={index} value={JSON.stringify(item)}>{item.name}</option>;
                  })}
             
              
                                    </Form.Select>
                                    <div className="wrap details">
                                    <div className="suggestion">
                  <p>
                    {languageData?.map((item, index) => {
                   return  <span >
                       {item.name}
                       
                        <ClearIcon onClick={handleDeleteLanguage(item.id)} sx={{ fontSize: 17 }}/></span>
                     })} 
                  </p>
                  </div>
                </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="country mb-2">
                                  <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlInput1"
                                  >
                                    <Form.Label className="label-input100 ">
                                      Employer
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      value={formData.employer==="null"?"":formData.employer}
                                      placeholder="Select Employer"
                                      onChange={onChangeData("employer")}
                                    />
                                  </Form.Group>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="country mb-2">
                                    <Form.Label className="label-input100 ">
                                      Religion
                                    </Form.Label>
                                    <Form.Select aria-label="Default select example" value={formData.religion} onChange={onChangeData("religion")}>
                                    {/* <option value="null">Select Religion</option> */}
                                    {dropdownData?.religionData?.map((item, index) => {
                    return <option key={index} value={item.id}>{item.religion_name}</option>;
                  })}
                                    </Form.Select>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="country mb-2">
                                    <Form.Label className="label-input100 ">
                                     Marital Status
                                    </Form.Label>
                                    <Form.Select aria-label="Default select example" value={formData.marital} onChange={onChangeData("marital")}>
                                    {/* <option value="null">Select Marital Status</option> */}
                                    {dropdownData?.maritalStatusData?.map((item, index) => {
                    return <option key={index} value={item.id}>{item.name}</option>;
                  })}
                                    </Form.Select>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="country mb-2">
                                    <Form.Label className="label-input100 ">
                                      Kids
                                    </Form.Label>
                                    <Form.Select aria-label="Default select example" value={formData.kids} onChange={onChangeData("kids")}>
                                    <option value="null">No. of kids</option>
                                    <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                                    </Form.Select>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="country mb-2">
                                    <Form.Label className="label-input100 ">
                                      Food Preferences
                                    </Form.Label>
                                    <Form.Select aria-label="Default select example" value={formData.food} onChange={onChangeData("food")}>
                                    {/* <option value="null">Select Food Preferences</option> */}
                                    {dropdownData?.foodPreferencesData?.map((item, index) => {
                    return <option key={index} value={item.id}>{item.fp_name}</option>;
                  })}
                                    </Form.Select>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <Form.Group
                                    className="mb-2"
                                    controlId="exampleForm.ControlInput1"
                                  >
                                    <Form.Label className="label-input100 ">
                                      FB Link
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="https://jjvpi9..axshare.com/#id=y7jcmd&p=account y7jcmd_settings&g=12"
                                      value={formData.fb_link==="null"?"":formData.fb_link}
                                      onChange={onChangeData("fb_link")}
                                    />
                                  </Form.Group>
                                </div>
                                <div className="row mt-5">
                                <div className="text-end">
                                <Link style={{paddingRight:"10px"}}  to="/dashboard">
          <button className="btn btn-outline ">Cancel </button></Link>
          <button className="btn btn-xl " onClick={submitData("allData")}>Save Changes</button>
        </div>
          </div>
                              </div>
                              
                            </div>
                            <Modal
      show={isOpenPass}
      onHide={closePassModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
      </Modal.Header>

      <Modal.Body className="px-5 terms-1">
      <h3>Change Password</h3>
      <div className="row">
            <div className="col-lg-12 position-relative">
            <div className="label-input100 mt-3">Current Password</div>
            {/* {values.registration==="google"||values.registration==="facebook"||values.registration==="apple" ? <input className="input100" type={passwordShown ? "text" : "password"} name="password" value={values.password} placeholder="**********" disabled/> : */}
            <input className="input100"  type={passwordShown ? "text" : "password"}   value={formData.oldpass} name="password" onChange={onChangeData("oldpass")} placeholder="**********"/>
              <span className='eye-icon' onClick={togglePassword}>
              {passwordShown ? (
                                                          <Visibility color="action"/>
                                                      ) : (
                                                          <VisibilityOff color="action"/>
                                                      )}
                                          </span>
            
                               
            </div>
            <div className="col-lg-12  position-relative">
            <div className="label-input100 mt-3">New Password</div>
            {/* {values.registration==="google"||values.registration==="facebook"||values.registration==="apple" ?  <input className="input100" type={passwordShown ? "text" : "password"} name="confirmpassword" value={values.confirmpassword} placeholder="**********" disabled/> :  */}
            <input className="input100" type={passwordShown ? "text" : "password"} value={formData.newpass} onChange={onChangeData("newpass")} name="newpassword" placeholder="**********"/>
             <span className='eye-icon' onClick={togglePassword}>
              {passwordShown ? (
                                                          <Visibility color="action"/>
                                                      ) : (
                                                          <VisibilityOff color="action"/>
                                                      )}

                                          </span>
              {/* {errorconfirmpassword && (
                                   <label className="label-input100 text-danger" >
                                      {errorconfirmpassword}
                                      </label>
                              )} */}
            </div>
            <div className="col-lg-12  position-relative">
            <div className="label-input100 mt-3">Confirm Password</div>
            {/* {values.registration==="google"||values.registration==="facebook"||values.registration==="apple" ?  <input className="input100" type={passwordShown ? "text" : "password"} name="confirmpassword" value={values.confirmpassword} placeholder="**********" disabled/> :  */}
            <input className="input100" type={passwordShown ? "text" : "password"} value={formData.confirmNewpass} onChange={onChangeData("confirmNewpass")} name="confirmNewpass" placeholder="**********"/>
             <span className='eye-icon' onClick={togglePassword}>
              {passwordShown ? (
                                                          <Visibility color="action"/>
                                                      ) : (
                                                          <VisibilityOff color="action"/>
                                                      )}

                                          </span>
              {/* {errorconfirmpassword && (
                                   <label className="label-input100 text-danger" >
                                      {errorconfirmpassword}
                                      </label>
                              )} */}
            </div>
          </div>
          {errorPass && (
                                   <label className="label-input100 text-danger" >
                                      {errorPass}
                                      </label>
                              )}
        {/* <h5 style={{ color: "#000000", textAlign: "center" }}>Are you sure you want to mark this response as Spam?</h5> */}
      </Modal.Body>
      <div style={{ display: "flex", justifyContent: "center", paddingBottom: "20px" }}>
      
        <div className="text-left small">
          <button className="btn btn-outline mx-3 " onClick={closePassModal}>Cancel </button>
          <button className="btn btn-xl shadow-none " onClick={submitData("password")}>Change</button>
        </div>

      </div>
     
    </Modal>
                            <div className="col-lg-4 order-lg-2 order-1">
                              <div className="profile-pic">
                                <div>
                                  {imgData!==null ? 
                                  <div className="img">
                                    {/\.(jpg|jpeg|png|webp|avif|gif|jfif|svg)$/.test(imgData)?
                                  <App imageURL={imgData} width="100%" />: <img src={imgData} alt="" width="100%"/>}
                                   <button  onClick={pictureStatus} className="shubh"> <img src={deleteIcon} /></button>
                                  </div>:   <div className="img"><img src={userDp} alt="" width="100%"/></div>}
                                
                                </div>
                                <input type="file" id="profile_pic" name="profile_pic" style={{display: "none"}}   onChange={handlePicture("profile_pic")}
            /> 
                                <div className="mt-2">
                                <span className="btn-outline" >
                                <label style={{display:"block"}} htmlFor="profile_pic" >
                                
                                    Change Picture
                                
                                  </label>
                                  </span>
                                </div>
                           
                              </div>
                            </div>
                            

                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  }

                  </Tab>
                  <Tab eventKey="Cards" tabClassName="d-none" title="Cards">
                    <div>
                      <div class="tabs_pannel" id="tab2">
                        <div className="row mb-4">
                          <div className="col-6 addcard ">
                            <p>Attached Card</p>
                          </div>
                          <div className="col-6">
                            <div className="text-end">
                              <button
                                className="btn btn-xl"
                                onClick={openModal}
                              >
                                <AddIcon /> Add Card
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="table-exp-main request-area">
                          <div class="exp-table-container table-responsive">
                            <table class="table list_experts_table">
                              <thead>
                                <tr>
                                  <th>NAME ON CARD</th>
                                  <th>CARD NUMBER</th>
                                  <th>EXPIRY DATE</th>
                                  <th>Type</th>
                                  <th>CVV</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Marvin McKinney</td>
                                  <td>***********6432</td>
                                  <td>08/25</td>
                                  <td>Credit</td>
                                  <td>***</td>
                                  <td>
                                    <img src={trash} alt="img" />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Marvin McKinney</td>
                                  <td>***********6432</td>
                                  <td>08/25</td>
                                  <td>Credit</td>
                                  <td>***</td>
                                  <td>
                                    <img src={trash} alt="img" />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Marvin McKinney</td>
                                  <td>***********6432</td>
                                  <td>08/25</td>
                                  <td>Credit</td>
                                  <td>***</td>
                                  <td>
                                    <img src={trash} alt="img" />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="Notifications" title="Notifications">
                    <AccountNotifications data={notificationData} update={() => setUpdate(!update)} />
                  </Tab>
                  <Tab eventKey="Account" title="Account">
                    <AccountSettings submitData={submitData("deleteProfile")}/>
                  </Tab>
                </Tabs>
              </div>
            </section>
          </section>
        </section>
        <Modal
          show={isOpen}
          onHide={closeModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body className="p-5">
            <div className="">
              <div className="cards-details">
                <div className="cards">
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="Credit"
                        control={<Radio />}
                        label="Credit"
                        className="me-5"
                      />
                      <FormControlLabel
                        value="Debit"
                        control={<Radio />}
                        label="Debit"
                      />
                    </RadioGroup>
                  </FormControl>
                  <Form.Group
                    className="my-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="label-input100 ">
                      NAME ON CARD
                    </Form.Label>
                    <Form.Control type="text" placeholder="" />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="label-input100 ">
                      CARD NUMBER
                    </Form.Label>
                    <Form.Control type="text" placeholder="" />
                  </Form.Group>
                  <div className="row">
                    <div className="col-sm-6">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="label-input100 ">
                          EXPIRY DATE
                        </Form.Label>
                        <Form.Control type="text" placeholder="" />
                      </Form.Group>
                    </div>
                    <div className="col-sm-6">
                      <Form.Group
                        className="mb-3 cvv"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="label-input100 ">CVV</Form.Label>
                        <Form.Control type="text" placeholder="" />
                        <div className="cards-img">
                          <img src={cards} alt="img" />
                        </div>
                      </Form.Group>
                    </div>
                    <div className="mt-3">
                      <a
                        className="btn btn-outline"
                        href="/account/account/account"
                      >
                        Cancel
                      </a>
                      <a className="btn btn-xl" href="{}">
                        Add
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    </>
  );
};

export default Account;
