import React from "react";
import { Link } from "react-router-dom";
import img from "../../assets/img/img1.png";
import img2 from "../../assets/img/img2.png";
import logo from "../../assets/img/logo.png";
import newlogo from "../../assets/img/newlogo1.png"
import Footer from "./Footer";
import { URL } from '../../constants/constants'
function Home() {
    return (
      <div className="home">
        <div className="instructions">
          <nav className="navbar navbar-expand-lg navbar-light  py-3" id="mainNav">
            <div className="container">
              <a href={URL} className="navbar-brand" >
                <img src={newlogo} alt="img" width={"150px"} height = {"60px"}/>
              </a>
              <button
                className="navbar-toggler collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#mynavbar"
              >
                <span className="toggler-icon top-bar"></span>
                <span className="toggler-icon middle-bar"></span>
                <span className="toggler-icon bottom-bar"></span>
              </button>
              <div className="collapse navbar-collapse" id="mynavbar">
                <ul className="navbar-nav me-auto"></ul>
                <ul className="navbar-nav d-flex">
                  <li className="nav-item">
                    <Link to={'/signup'} className="btn btn-outline-white" href="{url}">
                      Register
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/login" className="btn btn-xl">Sign in</Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
        <header className="masthead">
          <div className="container px-4 px-lg-5 h-100">
            <div className="row h-100 align-items-center justify-content-center text-center">
              <div className="col-lg-8 align-self-end">
                <h1 className="text-white font-weight-bold">
                  Find Your Next
                  <br />
                  Adventure Sports Here.
                </h1>
              </div>
              <div className="col-lg-8 align-self-baseline">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, oa
                  aliqua.
                </p>
                <button className="btn btn-xl">Explore More Now</button>
              </div>
            </div>
          </div>
        </header>
        <section className="about">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="wrap">
                  <div className="img">
                    <img src={img} className="img-fluid" alt="img" />
                  </div>
                  <div className="img ">
                    <img src={img2} className="img-fluid" alt="img" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <span>Our Story</span>
                <h2>
                  About Adventure
                  <br /> Sports.
                </h2>
                <p>
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam, eaque
                  ipsa quae ab illo inventore veritatis et quasi architecto
                  beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem
                  quia voluptas sit aspernatur aut odit aut fugit, sed quia
                  consequuntur magni dolores eos qui ratione voluptatem sequi
                  nesciunt. Neque porro quisquam est, qui dolorem ipsum quia
                  dolor sit adipisci velit, sed quia non numquam eius modi
                  tempora incidunt ut labore et.
                </p>
                <button className="btn btn-xl">Register Now</button>
              </div>
            </div>
          </div>
        </section>
        <section className="expert">
          <div className="container ">
            <div className="row justify-content-center text-center">
              <div className="col-lg-8">
                <h3>Ask An Expert For Help</h3>
                <p>
                  Quis autem vel eum iure reprehenderit qui in ea voluptate
                  velit esse quam nihilenim ipsam voluptatem quia vnsequatur,
                  vel illum qui dolorem eum.
                </p>
                <button className="btn btn-xl">Contact us Now!</button>
              </div>
            </div>
          </div>
        </section>
        <Footer/>
      </div>
    );
  
}
export default Home;