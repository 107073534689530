import { CometChatUI } from "../../../cometchat-pro-react-ui-kit/CometChatWorkspace/src";

const CometChatAdv = () => {
    return(
      <div style={{width: 'auto', height:'500px'}}>
      	<CometChatUI />
      </div>
    )
}

export default CometChatAdv