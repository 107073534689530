import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import Sidebar from "../../../components/layout/Sidebar";
import Header from "../../../components/layout/Header";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import info from "../../../assets/img/info-symbol.svg";
import green from "../../../assets/img/green.png";
import { call } from "../../../utils/apiCall";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

toast.configure()

const Reedem = () => {
  const inputRegEx = /^[0-9\b]+$/
  const token = localStorage.getItem("accessToken");
  const userData = localStorage.getItem("user");
  let email = JSON.parse(userData).data.email_id
  const [show, setShow] = useState(false);
  const [shows, setShows] = useState(false);
  const [pointBalance, setPointBalance] = useState("")
  const [errMessage, setErrMessage] = useState("")
  const [redeemPoint, setRedeemPoints] = useState("")
  const [amount, setAmount] = useState("")
  const [point, setPoint] = useState("")
  const [transferAmount, setTransferAmount] = useState(null)
  const [awaitResult, setAwaitResult] = useState(false)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClosed = () => setShows(false);

  const handleAgree = async () => {
    setAwaitResult(true)
    const payload = {
      points : redeemPoint
    }
    try{
      const res = await call("post", "/generate-giftcard", null, payload, token);
      if(res.data.success === true){
        setAwaitResult(false)
        setShows(true);
        setShow(false);
      }
      else{
        setAwaitResult(false)
        toast.error("Something went wrong. Please try again later.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
      }
    }
    catch(error){
      setAwaitResult(false)
      setShow(false);
      toast.error("Something went wrong. Please try again later.", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
    }
    
  }
  
  const handlePointChange = (e) => {
    if(inputRegEx.test(e.target.value) || e.target.value === ""){
      setRedeemPoints(e.target.value)
      const amt = parseInt(amount)
      const pts = parseInt(point)
      const converted_amount = (amt/pts) * parseInt(e.target.value)
      if(e.target.value > pointBalance){
        setErrMessage("Points to be redeemed can't be greater than balance points.")
        setTransferAmount(null)
      }
      else{
        if(e.target.value === ""){
          setTransferAmount(null)
        }
        else{
          setTransferAmount(converted_amount)
        }
        setErrMessage("")
      }
    }
  }

  useEffect(() => {
    
    (async () => {
      try {
        const res = await call("get", "/admin/get-point", null, null, token);
        if(res.data.success === true){
          setPointBalance(res.data.data[0].balance_point)
          setAmount(res.data.data[0].amount)
          setPoint(res.data.data[0].point)
        }
        else{
          toast.error("Could not get balance points. Please try again later", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
        }
      } catch (err) {
        toast.error("Could not get balance points. Please try again later", {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
      }
    })();
  }, [shows])
  return (
    <>
      <main className="page_wrapper">
        <section className="dashboard_section d-flex">
          <div>
            <Sidebar />
          </div>

          <section className="dashboard_main_section">
            <Header title="Points" />

            <section class="new-question-section dasboard_main_area points-area">
              <div class="dashboard_container">
                <div class="back-btn-main mb-5">
                  <Link to={"./points"}>
                    <KeyboardBackspaceIcon /> Redeem Points
                  </Link>
                </div>
                <div class="points-available">
                  <label>Points Available:</label>
                  <span class="points-val">{pointBalance}</span>
                </div>
                <div class="new-question-from-wrapper redeem">
                  <form class="new-question-from" action="">
                    <div class="field-wrapper position-relative">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="label-input100 ">
                          Enter Points
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Points to Redeem" value={redeemPoint} onChange = {(e) => handlePointChange(e)} />
                        {errMessage === "" ? <></> : <Form.Label className="error-label">{errMessage}</Form.Label>}
                        <Form.Text id="info-text">{`Amount: ${transferAmount === null ? "N/A" : "$ " + transferAmount} (Based On Points conversion rate)`}</Form.Text>
                      </Form.Group>
                      <span class="info-tooltip-main">
                        <span class="tool-tip-inner">
                          <img src={info} alt="" />
                          <span class="tool-tip-box">
                            Points to be redeemed should be greater than or equal to 1$ upon conversion.
                          </span>
                        </span>
                      </span>
                    </div>
                    <div class="submit-wrapper submit-main row mt-5">
                      <div class="text-left ">
                        <button class="btn btn-outline">Cancel</button>
                        <Button
                          className="btn btn-xl text-center h-42"
                          onClick={handleShow}
                          disabled = {errMessage !== "" || transferAmount < 1 || transferAmount === null ? true : false}
                        >
                          Redeem
                        </Button>
                
                      </div>
                    </div>
                  </form>
                  <Modal
                    show={show}
                    onHide={handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    className="redeem"
                    centered
                  >
                    <Modal.Header closeButton>
                      {/* <Modal.Title>Modal heading</Modal.Title> */}
                    </Modal.Header>
                    <Modal.Body>
                      <div className="text-center txt2">
                        <p>
                          Do you want to redeem points in return of gift card of worth ${transferAmount} ?
                        </p>
                        <div className="row my-4">
                          <div className="text-left submit-wrapper">
                            <button className="btn btn-outline me-3" onClick={() => setShow(false)}>No</button>
                            <button className="btn btn-xl" onClick={() => handleAgree()} disabled = {awaitResult} >Yes</button>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>

                  <Modal
                    show={shows}
                    onHide={handleClosed}
                    aria-labelledby="contained-modal-title-vcenter"
                    className="redeem"
                    centered
                  >
                    <Modal.Body>
                      <div className="text-center txt2 p-4">
                        <img src={green} alt=""/>
                        <p className="mt-4">Points redeemed successfully. Gift card details sent on: {email}</p>
                        <div className="row my-4">
                          <div className="text-left submit-wrapper">
                            <button className="btn btn-xl me-3 w-100" onClick={() => setShows(false)}>Okay</button>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
 

                  
                </div>
              </div>
            </section>
          </section>
        </section>
      </main>
    </>
  );
};



export default Reedem;
