import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Sidebar from "../components/layout/Sidebar";
import Header from "../components/layout/Header";
import useAuth from "../hooks/useAuth";
import { Button } from "react-bootstrap";
import { call } from "../utils/apiCall";

const Content = () => {
  const { isAuthenticated } = useAuth();
  const [formValue, setFormValue] = useState({
    name: "",
    email: "",
    message: "",
  });
  const handleContactFormSubmit = async (e) => {
    const token = localStorage.getItem("accessToken");
    e.preventDefault();
    try {
      const res = await call("POST", "/contac-us", null, formValue, null);
      console.log(res.data);
      alert('Sent Successfully');
      setFormValue({
        name: "",
        email: "",
        message: "",
      })
    } catch (e) {
      console.log(e);
    }
  };

  return (
      <section className="dasboard_main_area list-experts-area contact-us">
        {!isAuthenticated && <h2>Contact Us</h2>}
        <Form onSubmit={handleContactFormSubmit}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="label-input100 ">Name</Form.Label>
            <Form.Control
                type="text"
                placeholder="Enter Name"
                required
                name="name"
                value={formValue.name}
                onChange={(e) =>
                    setFormValue({ ...formValue, name: e.target.value })
                }
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="label-input100 ">Email address</Form.Label>
            <Form.Control
                type="email"
                placeholder="Enter Email"
                required
                name="email"
                value={formValue.email}
                onChange={(e) =>
                setFormValue({ ...formValue, email: e.target.value })
                }
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label className="label-input100 ">Message</Form.Label>
            <Form.Control
                as="textarea"
                rows={3}
                required
                name="message"
                value={formValue.message}
                onChange={(e) =>
                    setFormValue({ ...formValue, message: e.target.value })
                }
            />
          </Form.Group>
          <Button type="submit">Submit</Button>
        </Form>
      </section>
  );
};

const Contact = () => {
  const { isAuthenticated } = useAuth();
  return (
      <>
        <main className="page_wrapper">
          {isAuthenticated ? (
              <section className="dashboard_section d-flex">
                <div>
                  <Sidebar />
                </div>

                <section className="dashboard_main_section">
                  <Header title="Contact us" />
                  <Content />
                </section>
              </section>
          ) : (
              <Content />
          )}
        </main>
      </>
  );
};

export default Contact;
