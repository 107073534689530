import React, { useState } from "react";
import { Link } from "react-router-dom";
import like from '../assets/img/like.png';
import logo from "../assets/img/logo.png";
import { Modal, Button, Form } from "react-bootstrap";
import { call } from '../utils/apiCall'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { useParams } from 'react-router-dom';

export default function Reset() {

  const [isOpen, setIsOpen] = useState(false)
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [password, setPassword] = useState('')
  const [confirmpassword, setconfirmPassword] = useState('')
  const [errorPassword, setErrorPassword] = useState('')
  const [errorcPassword, setErrorcPassword] = useState('')
  const [passwordShown, setPasswordShown] = useState(false);

  const { token } = useParams();
  const setpassword = () => {
    setErrorPassword('')
    setResponseMessage('')
  }
  const setcpassword = () => {
    setErrorcPassword('')
  }

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };


  const checkValidation = () => {
    let formIsValid = true;

    if (!password) {
      setErrorPassword("Password should not be empty")
      formIsValid = false;
    }
    if (!(password === confirmpassword)) {
      setErrorcPassword("It must be same as password")
      formIsValid = false;
    }
    if (!confirmpassword) {
      setErrorcPassword("Comfirm Password should not be empty")
      formIsValid = false;
    }
    return formIsValid;
  }

  const submitLoginData = (e) => {
    e.preventDefault();
    if (checkValidation()) {
      let passwordData = { new_password: password };
      if (passwordData) {
        (async () => {
          try {
            const res = await call(
              'patch',
              '/change-password',
              null,
              passwordData,
              token
            )
            console.log(res.data)
            if (res.data.success === true && res.data.message === "Password updated successfully.") {
              openModal();
            }

          } catch (err) {
            setResponseMessage(err.response.data.message)
          }
        })()
      } else { }
    }
  };


  return (
    <div>
      <div className="limiter">
        <div className="container-login100">
          <div className="wrap-login100">
            <form className="login100-form validate-form">
              <div>
                <span className="login100-form-title">
                  {" "}
                  Reset your password{" "}
                </span>
                <p className="mt-3">Please reset your password below</p>
              </div>
              <div className="text-center">
                {responseMessage && (
                  <label className="label-input100 text-danger" >
                    {responseMessage}
                  </label>
                )}
              </div>
              <div
                className="wrap-input100 validate-input mt-5"
                data-validate="Valid email is required: ex@abc.xyz"
              >
                <div className="label-input100 ">Password</div>
                <div className="input">
                  <i className="icon-lock"></i>
                  <Form.Control
                    className="input100"
                    type={passwordShown ? "text" : "password"}
                    name="pass"
                    placeholder="Enter your password"
                    onClick={setpassword}
                    onChange={(e) =>
                      setPassword(e.target.value)}
                  />
                  <span onClick={togglePassword}>
                    {passwordShown ? (
                      <i className="eye-icon2"><Visibility /></i>
                    ) : (
                      <i className="eye-icon2"><VisibilityOff /></i>
                    )}

                  </span>
                </div>
                {errorPassword && (
                  <label className="label-input100 text-danger" >
                    {errorPassword}
                  </label>
                )}


                <div className="label-input100 mt-4 ">Re-enter Password</div>
                <div className="input">
                  <i className="icon-lock"></i>
                  <Form.Control
                    className="input100"
                    type={passwordShown ? "text" : "password"}
                    name="pass"
                    placeholder="Re-enter your password"
                    onClick={setcpassword}
                    onChange={(e) =>
                      setconfirmPassword(e.target.value)}
                  />
                  <span onClick={togglePassword}>
                    {passwordShown ? (
                      <i className="eye-icon2"><Visibility /></i>
                    ) : (
                      <i className="eye-icon2"><VisibilityOff /></i>
                    )}

                  </span>
                </div>
                {errorcPassword && (
                  <label className="label-input100 text-danger" >
                    {errorcPassword}
                  </label>
                )}
              </div>
              <div
                className="container-login100-form-btn mt-5"
              >
                <Button className="btn btn-xl w-100" onClick={submitLoginData}>
                  Send
                </Button>
              </div>
              <div className="text-center mt-4">
                <Link to={"/login"} className="back">
                  Back to Login
                </Link>
              </div>
            </form>

            <div className="login100-more">
              <div className="logo m-4 ">
                <a href="/" >
                  <img src={logo} className="img-fluid" alt="img" />
                </a>
              </div>
            </div>
          </div>

          <Modal
            show={isOpen}
            onHide={closeModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body className="p-5">
              <div className="text-center">
                <img src={like} alt="img" />
                <p className="mt-3">
                  <strong>
                    Password updated successfully!
                    <br />
                    Please login again</strong>
                </p>
                <Link to={'/login'} className="btn btn-xl w-100 mt-5">Back to login</Link>
              </div>

            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}
