import Sidebar from "../../../components/layout/Sidebar";
import Header from "../../../components/layout/Header";
import { Link } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import AddIcon from "@mui/icons-material/Add";
import {useEffect, useState} from "react"
import Loading from "../../../components/Loaders/Loading";
import { call } from "../../../utils/apiCall";
import TablePaginationWrapper from "../../../components/TablePaginationWrapper";

const Bank = () => {

  const [cardList, setCardList] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [pagination, setPagination] = useState(false)
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);

  useEffect(() => {
    setIsLoading(true)
    const token = localStorage.getItem("accessToken");
    (async () => {
      try {
        const res = await call("get", `/get-user-card-list?page=${page}&size=${size}`, null, null, token);
        setCardList(res?.data);
        setPagination(res.data.page_count);
        console.log(res.data.data);
        setIsLoading(false)
      } catch (err) {
        console.log(err);
        setIsLoading(false)
      }
    })();
  }, [page, size]);

  useEffect(() => {
    if (page > pagination) {
      setPage(1);
    }
  }, [pagination]);

  const onChange = (e) => {
    setSize(e.target.value);
  };

  const onClickNext = () => {
    setPage(page + 1);
  };

  const onClickBack = () => {
    setPage(page - 1);
  };

  return (
    <>
      <main className="page_wrapper">
        <section className="dashboard_section d-flex">
          <div>
            <Sidebar />
          </div>

          <section className="dashboard_main_section">
            <Header title="Points" />

            <section class="new-question-section dasboard_main_area points-area">
              <div class="">
                <div className="d-flex justify-content-between mb-3">
                <div class="back-btn-main mb-5">
                  <Link to={"./points"}>
                    <KeyboardBackspaceIcon /> Select Bank
                  </Link>
                </div>
                <div class="add-new-question text-end">
                  <Link to="/card"> <button class="btn btn-xl plus-btn p-3 px-4">
                    <AddIcon /> Add New Card
                  </button>
                  </Link>
                </div>

                </div>
                <div class="points-table-section banks-table-section select-banks-table">
                  <div class="points-table-container">
                    <div class="points-tb-wrapper table-responsive">
                      <table class="points-table">
                        <thead class="points-tb-header">
                          <tr>
                            <th></th>
                            <th>NAME ON CARD</th>
                            <th>CARD NUMBER</th>
                            <th>EXPIRY DATE</th>
                            <th>Type</th>
                            <th>CVV</th>
                          </tr>
                        </thead>
                        {isLoading ? <tr><td style={{height:"60px"}} colspan="6"><Loading/></td> </tr>:
                        <tbody>
                        {cardList?.data?.map((item, index) => {
                      return (
                    <tr key={index}>
 <td>
                              <div class="custom-radio-btn">
                                <input type="radio" name="radiobtn"  />
                              </div>
                            </td>
                            <td>{item.name}</td>
                            <td>***********8425</td>
                            <td>{item.expiry_date}</td>
                            <td>{item.payment_mode_name}</td>
                            <td>***</td>
                      </tr>
                    )
                      })}
                          {/* <tr>
                            <td>
                              <div class="custom-radio-btn">
                                <input type="radio" name="radiobtn" checked />
                              </div>
                            </td>
                            <td>Savannah Nguyen</td>
                            <td>***********8425</td>
                            <td>12/27</td>
                            <td>Debit</td>
                            <td>***</td>
                          </tr>
                          <tr>
                            <td>
                              <div class="custom-radio-btn">
                                <input type="radio" name="radiobtn" checked />
                              </div>
                            </td>
                            <td>Brooklyn Simmons</td>
                            <td>***********4852</td>
                            <td>12/28</td>
                            <td>Credit</td>
                            <td>***</td>
                          </tr> */}
                        </tbody>
                         }
                      </table>
                    </div>
                  </div>
                </div>
                <TablePaginationWrapper
                              onChange={onChange}
                              page={page}
                              pagination={pagination}
                              onClickNext={onClickNext}
                              onClickBack={onClickBack}
                            />
                <div class="submit-wrapper submit-main row mt-5">
                  <div class="text-left ">
                    <button class="btn btn-outline">Cancel</button>
                    <button class="btn btn-xl text-center py-3">Pay</button>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </section>
      </main>
    </>
  );
};

export default Bank;
