import React, { useState, useEffect } from "react";
import logo from "../../assets/img/logo.png";
import { call } from "../../utils/apiCall";
import { URL } from "../../constants/constants";
import useAuth from "../../hooks/useAuth";
import { Redirect } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

toast.configure()
const Personal = ({
  handleFormData,
  prevStep,
  values,
  handleLanguageChange,
  languageData,
  deleteLanguage,
  dropdownData,
  updateReferral
}) => {
  const [userFormData, setUserFormData] = useState("");
  const [checked, setChecked] = useState(false);
  const [errorPolicy, setErrorPolicy] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [responsePath, setResponsePath] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [errorStatus, setErrorStatus] = useState("");
  const [errorgender, setErrorGender] = useState("");
  const [appliedReferral, setAppliedReferral] = useState("")
  const [validateReferral, setValidateReferral] = useState(false)


  const { register } = useAuth();

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await call("get", "/user-form-data", null, null);
        setUserFormData(res.data);
        dropdownData(
          res.data?.maritalStatusData[0]?.id,
          res.data?.foodPreferencesData[0]?.id,
          res.data?.religionData[0]?.id
        );
      } catch (err) {}
    })();
  }, []);

  const handleChecked = () => {
    console.log(checked);
    setChecked(!checked);
  };

  const setAgree = () => {
    setChecked(true);
    setIsOpen(false);
  };

  const setError = () => {
    setErrorPolicy("");
  };

  const statuserror = () => {
    setErrorStatus("");
  };
  const gendererror = () => {
    setErrorGender("");
  };

  const checkValidation = () => {
    let formIsValid = true;
    console.log(checked);
    if (!values.currentstatus) {
      setErrorStatus("Please select status");
      formIsValid = false;
    }
    if (!values.gender) {
      setErrorGender("Please select gender");
      formIsValid = false;
    }
    if (!checked) {
      setErrorPolicy("Please agree terms and policy.");
      formIsValid = false;
    }
    return formIsValid;
  };

  const handleReferralChange = (e) => {
    if(e.target.value.length <= 8){
      setAppliedReferral(e.target.value.toUpperCase())
    }
  }

  const handleReferralRemove = () => {
    setValidateReferral(false)
    setAppliedReferral("")
    updateReferral(null)
  }

  const handleReferralApply = async(e) => {
    e.preventDefault()
    const bodyData = {
      referralCode : appliedReferral
    }

    const validation_result = await call("post", '/validate-referral', null, bodyData)
    if(validation_result.data.success === true){
      setValidateReferral(true)
      toast.success(validation_result.data.message, {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
      updateReferral(validation_result.data.referral_data)
    }
    else{
      toast.error(validation_result.data.message, {autoClose : 3000, position : toast.POSITION.TOP_CENTER})
    }
  }

  const handleFormSubmit = async (data1) => {
    console.log(values.language);
    try {
      await register(data1);
      alert("Users Registered Successfully");
      setResponsePath(true);
    } catch (e) {
      console.log(e);
      alert(e.response.data.message);
    }
  };
  console.log(values);
  if(values.referral_data !== null && appliedReferral === ""){
    setAppliedReferral(values.referral_data.code)
    setValidateReferral(true)
  }
  const submitData = (e) => {
    e.preventDefault();
    if (checkValidation()) {
      console.log(typeof values.referral_data)
      const data1 = new FormData();
      data1.append("password", values.password);
      data1.append("first_name", values.firstname);
      data1.append("username", values.email);
      data1.append("last_name", values.lastname);
      data1.append("email_id", values.confirmemail);
      data1.append("mobile_no", values.phone);
      data1.append("screen_name", values.screen_name);
      data1.append("registration_type", values.registration);
      data1.append("google_token", values.googleToken);
      data1.append("facebook_token", "null");
      data1.append("apple_token", "null");
      data1.append("date_of_birth", values.dob);
      data1.append("phone_number", "null");
      data1.append("country_id", values.country);
      data1.append("state_id", values.state);
      data1.append("city_id", values.city);
      data1.append("occupation_id", "1");
      data1.append("current_status_id", values.currentstatus);
      data1.append("religion_id", values.religion);
      data1.append("marital_status_id", values.marital);
      data1.append("gender_id", values.gender);
      data1.append("food_preferences_id", values.food);
      data1.append("kids", values.kids);
      data1.append("employer", values.employer);
      data1.append("fb_link", values.fb_link);
      data1.append("profile_pic", values.profile_pic);
      data1.append("location", JSON.stringify(values.location));
      data1.append("language", JSON.stringify(values.language));
      data1.append("referral_data", JSON.stringify(values.referral_data))

      handleFormSubmit(data1);
    }
  };

  return responsePath ? (
    <Redirect to="/login" />
  ) : (
    <div>
      <div className="basic">
        <div className="sign-up">
          <div className="logo">
            <a href={URL} className="navbar-brand">
              <img src={logo} className="img-fluid" alt="img" />
            </a>
          </div>
          <div className="heading">
            <h1>Sign up now</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </div>
          <div className="process">
            <ul>
              <li>
                <div className="node ">
                  <div className="circle">
                    <span className="check"></span>
                  </div>
                </div>
                <p> Basic information</p>
              </li>
              <li>
                <div className="divider"></div>
              </li>
              <li>
                <div className="node ">
                  <div className="circle">
                    <span className="check"></span>
                  </div>
                </div>
                <p>Other details</p>
              </li>
              <li>
                <div className="divider"></div>
              </li>
              <li>
                <div className="node ">3</div>
                <p className="active">Personal details</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="wrap details">
          <form>
            <h2>Personal details</h2>
            <div className="row">
              <div className="col-lg-6">
                <div className="label-input100 mt-3">
                  Gender*
                  <i title = "While not required, this information is used to better match you to experts near your preferred locations. For more info, see FAQs." className="fa fa-info-circle" id="info-thirdPage"></i>
                </div>
                <select
                  className=" classNameic"
                  aria-label="Default select example"
                  onClick={gendererror}
                  name="gender"
                  value={values.gender}
                  onChange={handleFormData("gender")}
                >
                  <option value="">Select Gender</option>
                  {userFormData.genderData?.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
                {errorgender && (
                  <label className="label-input100 text-danger">
                    {errorgender}
                  </label>
                )}
              </div>
              <div className="col-lg-6">
                <div className="label-input100 mt-3">
                  Current Status*
                  <i title = "While not required, this information is used to better match you to experts near your preferred locations. For more info, see FAQs." className="fa fa-info-circle" id="info-thirdPage"></i>
                </div>
                <select
                  className=" classNameic"
                  aria-label="Default select example"
                  name="currentstatus"
                  onClick={statuserror}
                  value={values.currentstatus}
                  onChange={handleFormData("currentstatus")}
                >
                  <option value="">Select status</option>
                  {userFormData.currentStatusData?.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
                {errorStatus && (
                  <label className="label-input100 text-danger">
                    {errorStatus}
                  </label>
                )}
              </div>
              <div className="col-lg-6">
                <div className="label-input100 mt-3">
                  Occupation
                  <i title = "While not required, this information is used to better match you to experts near your preferred locations. For more info, see FAQs." className="fa fa-info-circle" id="info-thirdPage"></i>
                </div>
                <select
                  className=" classNameic"
                  aria-label="Default select example"
                  name="occupation"
                  value={values.occupation}
                  onChange={handleFormData("occupation")}
                >
                  <option value="">Select Occupation</option>
                  {userFormData.occupationData?.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}

                  {/* {userFormData.occupationData?.map((item, index) => {
                      return <option key={index} value={item.id}>{item.name}</option>;
                    })} */}
                </select>
              </div>

              <div className="col-lg-6">
                <div className="">
                  <div className="label-input100 mt-3">Employer</div>
                  <input
                    type="text"
                    placeholder="Select Employer"
                    name="employer"
                    value={values.employer}
                    onChange={handleFormData("employer")}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="label-input100 mt-3">
                  Language
                  <i title = "While not required, this information is used to better match you to experts near your preferred locations. For more info, see FAQs." className="fa fa-info-circle" id="info-thirdPage"></i>
                </div>
                <select
                  className=" classNameic"
                  aria-label="Default select example"
                  name="language"
                  value=""
                  onChange={handleLanguageChange("language")}
                >
                  <option selected value="0">
                    Select one/multiple language
                  </option>
                  {userFormData.languageData?.map((item, index) => {
                    return (
                      <option key={index} value={JSON.stringify(item)}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
                <div className="suggestion">
                  <p>
                    {languageData?.map((item, index) => {
                      return (
                        <span>
                          {item.name}
                          <ClearIcon
                            onClick={deleteLanguage(item.id)}
                            sx={{ fontSize: 17 }}
                          />
                        </span>
                      );
                    })}
                  </p>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="label-input100 mt-3">
                  Religion
                  <i title = "While not required, this information is used to better match you to experts near your preferred locations. For more info, see FAQs." className="fa fa-info-circle" id="info-thirdPage"></i>
                </div>
                <select
                  className=" classNameic"
                  aria-label="Default select example"
                  name="religion"
                  value={values.religion}
                  onChange={handleFormData("religion")}
                >
                  {userFormData.religionData?.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.religion_name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-lg-6">
                <div className="label-input100 mt-3">
                  Marital Status
                  <i title = "While not required, this information is used to better match you to experts near your preferred locations. For more info, see FAQs." className="fa fa-info-circle" id="info-thirdPage"></i>
                </div>
                <select
                  className=" classNameic"
                  aria-label="Default select example"
                  name="marital"
                  value={values.marital}
                  onChange={handleFormData("marital")}
                >
                  {/* <option selected>Select Marital Status</option> */}
                  {userFormData.maritalStatusData?.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="col-lg-6">
                <div className="label-input100 mt-3">
                  Kids
                  <i title = "While not required, this information is used to better match you to experts near your preferred locations. For more info, see FAQs." className="fa fa-info-circle" id="info-thirdPage"></i>
                </div>
                <select
                  className=" classNameic"
                  aria-label="Default select example"
                  name="kids"
                  value={values.kids}
                  onChange={handleFormData("kids")}
                >
                  <option value="null">No. of kids</option>
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="label-input100 mt-3">
                  Food Preferences
                  <i title = "While not required, this information is used to better match you to experts near your preferred locations. For more info, see FAQs." className="fa fa-info-circle" id="info-thirdPage"></i>
                </div>
                <select
                  className=" classNameic"
                  aria-label="Default select example"
                  name="food"
                  value={values.food}
                  onChange={handleFormData("food")}
                >
                  {/* <option selected>Select Food Preferences</option> */}
                  {userFormData.foodPreferencesData?.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.fp_name}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="col-lg-6">
                <div className="">
                  <div className="label-input100 mt-3">FB Link</div>
                  <input
                    type="text"
                    placeholder="Enter FB Link"
                    name="fb_link"
                    value={values.fb_link}
                    onChange={handleFormData("fb_link")}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="align-items-center mt-4">
                  <div className="label-input100 mt-3">Have a referral code? Apply here and win 50 pts.</div>
                  <div className="d-flex">
                  <input
                    type="text"
                    placeholder="Enter Referral Code"
                    name="referral_code"
                    value = {appliedReferral}
                    onChange={(e) => handleReferralChange(e)}
                    disabled = {validateReferral ? true : false}
                  />
                  <button className={validateReferral ? "referral-btn-app" : "referral-btn-check"} disabled = {appliedReferral.length < 8 ?  true : false} onClick={validateReferral ? () => handleReferralRemove() : (e) => handleReferralApply(e)}><span>{validateReferral ? "Applied" : "Apply"}</span></button>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="d-flex align-items-center mt-4">
                  <input
                    className=" "
                    id="ckb1"
                    type="checkbox"
                    name="remember-me"
                    checked={checked}
                    onChange={handleChecked}
                    onClick={setError}
                  />
                  <label className="label-checkbox100 mx-1">
                    Agree to the{" "}
                    <a href="#" onClick={openModal}>
                      terms and conditions
                    </a>
                  </label>
                </div>
                <Modal
                  show={isOpen}
                  onHide={closeModal}
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      <h4>Terms and Conditions</h4>
                    </Modal.Title>
                  </Modal.Header>

                  <Modal.Body className="px-5 terms-1">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Aenean euismod bibendum laoreet. Proin gravida dolor sit
                    amet lacus accumsan.Aenean euismod bibendum laoreet. Proin
                    gravida dolor sit amet lacus accumsan.Aenean euismod
                    bibendum laoreet. Proin gravida dolor sit amet lacus
                    accumsan.Aenean euismod bibendum laoreet. Proin gravida
                    dolor sit amet lacus accumsan.
                    <br />
                    Aenean euismod bibendum laoreet. Proin gravida dolor sit
                    amet lacus accumsan.Aenean euismod bibendum laoreet. Proin
                    gravida dolor sit amet lacus accumsan.Aenean euismod
                    bibendum laoreet. Proin gravida dolor sit amet lacus
                    accumsan.Aenean euismod bibendum laoreet. Proin gravida
                    dolor sit amet lacus accumsan.
                  </Modal.Body>
                  <button
                    className="btn btn-xl mt-3 mb-5 mx-4"
                    onClick={setAgree}
                  >
                    Agree
                  </button>
                </Modal>
                {errorPolicy && (
                  <label className="label-input100 text-danger">
                    {errorPolicy}
                  </label>
                )}
              </div>
            </div>
            {responseMessage && (
              <label className="label-input100 text-danger">
                {responseMessage}
              </label>
            )}
            <div className="row mt-5">
              <div className="text-end">
                <span onClick={prevStep} className="btn btn-outline">
                  Back
                </span>

                <span onClick={submitData} className="btn btn-xl">
                  Submit
                </span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Personal;
