import {useState} from 'react';
import Layout from './components/layout/Layout.js';
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from './contexts/AuthContext'


function App() {
  

  return (
    <div className="App">
      <BrowserRouter>
      <AuthProvider>
    <Layout />
    </AuthProvider>
  </BrowserRouter>
  
    </div>
    
  );
}

export default App;
