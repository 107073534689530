import React, { Fragment, useState, useContext, useEffect} from 'react'
import {ThreeDots} from 'react-loader-spinner'
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Form from "react-bootstrap/Form";
import gptPic from "../../../assets/img/gpt-pic.jpg"
import { call } from '../../../utils/apiCall'
import { Modal } from "react-bootstrap";
import App from "../../../components/imageverify";
import delIcon from "../../../assets/img/delete-icon.svg";
import respond from "../../../assets/img/respond-icon.svg";
import { Link } from "react-router-dom";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import likee from "../../../assets/img/like-icon.svg";
import upvote from "../../../assets/img/up-vote-icon.svg";
import spam from "../../../assets/img/spam-icon.svg";
import spammed from "../../../assets/img/spammed-icon.svg";
import respondDis from "../../../assets/img/respond-disabled.svg";
import upvoteDis from "../../../assets/img/upvote-disabled.svg";
import likeDis from "../../../assets/img/like-disabled.svg";
import likeYes from "../../../assets/img/like-true.svg";
import downvote from "../../../assets/img/downvote-icon.svg";
import AuthContext from "../../../contexts/AuthContext";
import Loader from "../../../components/Loaders/ComponentLoader";


const Respond = ({item, NquestionResponse, OtherQuesResponse1, OtherQuesResponse2, OtherQuesResponse3, upvoteMyQues, removeVoteMyQues, type, setUpdatedPoints, status, totalDistributedPoints, updateList, setUpdateList}) => {

const [isOpen, setIsOpen] = useState(false);
const [responseofResponse, setResponseofResponse] = useState("")
const [responseData, setResponseData] = useState("")
const [image, setImage] = useState(null)
const [filename, setFilename] = useState(null)
const [errorResponse, setErrorResponse] = useState('')
const [isOpenPoints, setIsOpenPoints] = useState(false);
const [imageAnswer, setImageAnswer] = useState(null)
const [fileAnswer, setFileAnswer] = useState(null)
const [isSpam, setIsSpam] = useState(false);
const [spamType, setSpamType] = useState(null)
const [spamId, setspamId] = useState("")
const [pointsValue, setPointsValue] = useState()
const [pointsError, setpointsError] = useState()
const [isLoader, setIsLoader] = useState(false)
const [warningMessage, setWarningMessage] = useState()
const [reservedPoints, setReservedPoints] = useState()
const [update, setUpdate] = useState(false)
const [targetResponse, setTargetResponse] = useState("")
const [gptLoader, setGptLoader] = useState(false)

const userData = useContext(AuthContext);

const openModal = () => {
    if (totalDistributedPoints < 50){
      var value = 50-totalDistributedPoints;
      setReservedPoints(value);
    }
    else if (totalDistributedPoints >=50)
    {
      setReservedPoints(0);
    }
    setIsOpenPoints(true)
  }

  const closeModalPoints = () => {
    setIsOpenPoints(false);
    setWarningMessage();
    setpointsError()

  }

const closeModal = () => {
    setIsOpen(false)
    setImage(null)
    setFilename("")
  }

  const openSpamModal = () => {
    setIsSpam(true)
  }

  const closeSpamModal = () => {
    setIsSpam(false)
  }

  const resetError = () => {
    setErrorResponse("")

  }

  const spamhandle = (value, quesType) => {
    setspamId(value)
    setSpamType(quesType)
    openSpamModal();
  }


  const truncate = (str) => {
    var dotIndex = str.lastIndexOf('.');
    return str.length > 12 ? str.substring(0, 10) + "..." + str.substring(dotIndex) : str;
  }

  const onChange = (e) => {
    setResponseData(e.target.value);

  }

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(event.target.files[0]);
      setFilename(event.target.files[0].name)
      event.target.value=''
    }
  }

  const clearImage = () => {
    setImage(null)
    setFilename("")
    setImageAnswer(null)
    setFileAnswer("")
  }


  const checkValidation = () => {
    let formIsValid = true;
    let firstSpace = /^[^\s].*/
    if (!firstSpace.test(responseData)) {
      setErrorResponse("Invalid reply text")
      formIsValid = false;
    }
    if (responseData.length === 0) {
      setErrorResponse("Please enter your reply.")
      formIsValid = false;
    }

    return formIsValid;
  }

  const responofrespond = (responseId) => {

    setIsOpen(true)
    setUpdate(false)
    let token = localStorage.getItem("accessToken");
    (async () => {
      try {
        const res = await call(
          'get',
          `/get-respond-of-response?question_response_id=${responseId}&page=1&size=50`,
          null,
          null,
          token
        )
        setGptLoader(false)
        setResponseofResponse(res.data)
        if (res?.data?.data === null) {
          setResponseofResponse(prevState => ({
            ...prevState,
            data: [] 
      
          }))
        }
        setResponseData("")
      } catch (err) {
        console.log(err)
      }
    })()

  }

  useEffect(() => {
    if(update){
      responofrespond(targetResponse)
    }
  }, [update, targetResponse])

  const handleSubmit = (data, feedbackType, responseId, count, quesType) => {
    setIsLoader(true)
    let token = localStorage.getItem("accessToken");
    (async () => {
      try {
        const res = await call(
          'post',
          '/add-question-response-feedback',
          null,
          data,
          token
        )
        console.log(res.data)
        setIsLoader(false)
        if(res.data.success===true) {
        setUpdateList(!updateList)
        if (feedbackType === "spam" && quesType==="my_question") {
            NquestionResponse(responseId);
          closeSpamModal();
        }
       else if (feedbackType === "spam" && quesType==="other_question") {
        
       OtherQuesResponse1(responseId)
      //  setOtherQuestion(updatedList)
          closeSpamModal();
        }
   
        else if (feedbackType === "respond") {
          if(res.data.data.question_response_respond){
            setImage(null)
            setFilename("")
            setGptLoader(false)
            setResponseofResponse(prevState => ({
                  ...prevState,
                  data: [...prevState.data, res.data.data.question_response_respond] 
              
                }))
                setResponseData("")
                console.log(responseofResponse.data)
          }
          else{
            setTargetResponse(responseId)
            setUpdate(true)
          }
          
          
        //  console.log(res.data.data?.question_response[0].question_response_id);
        //  responofrespond(res.data.data?.question_response[0].question_response_id)
        }

        else if (feedbackType === "upvote" && quesType==="my_question") {
          // setQuestionResponse(prevState => ({
          //   ...prevState,
          //   data: {
          //     ...prevState.data,
          //     question_response: prevState.data.question_response.map((item) =>
          //       item.question_response_id === responseId
          //         ? {
          //           ...item,
          //           upvote_flage: 1,
          //           upvote_count: count
          //         }
          //         : item

          //     )
          //   }
          // }))
          upvoteMyQues(responseId, count);
        }

        else if (feedbackType === "upvote" && quesType==="other_question") {
       
        OtherQuesResponse2(responseId, count)
        }

        else if (feedbackType === "remove_upvote" && quesType==="my_question") {
          removeVoteMyQues(responseId, count);
        }
        else if (feedbackType === "remove_upvote" && quesType==="other_question") {
       
        OtherQuesResponse3(responseId, count)
        }
      }
      else{
        setGptLoader(false)
        (alert(res.data.message))
      }
    
      } catch (err) {
        setGptLoader(false)
        console.log(err)
      }
    })()

  }

  const responsefeedback = (responseId, feedbackType, count, quesType) => {
    
    const data1 = new FormData()
    data1.append('response_id', responseId)
    data1.append('feedback_type', feedbackType)

    if (feedbackType === "spam") {
      handleSubmit(data1, feedbackType, responseId, null, quesType)
    }

    else if (feedbackType === "respond") {
      setGptLoader(true)
      data1.append('feedback_text', responseData)
      data1.append('attachment_file', image)
      if (checkValidation()) {
        handleSubmit(data1, feedbackType, responseId, null, null)
      }
      else{
        setGptLoader(false)
      }
    }

    else if (feedbackType === "upvote") {
      let upVote_count = count + 1;
      handleSubmit(data1, feedbackType, responseId, upVote_count, quesType)
    }

    else if (feedbackType === "remove_upvote") {
      let upVote_count = count - 1;
      handleSubmit(data1, feedbackType, responseId, upVote_count, quesType)
    }
  }

  const pointsHandle = (e) => {
    if(e.target.value){
    setPointsValue(e.target.value)
    if (totalDistributedPoints<50 && e.target.value>50 ){
      var balance = (parseInt(e.target.value) + totalDistributedPoints)-50;
      setWarningMessage(balance);
    }
    else if (totalDistributedPoints<50 && e.target.value<=50 ){
      var balance = (parseInt(e.target.value) + totalDistributedPoints)
      if (balance>50){
        var newbal=balance-50;
      setWarningMessage(newbal);
      }
    }
    else if(totalDistributedPoints >= 50) {
      setWarningMessage(parseInt(e.target.value));
    }
  }
  else setWarningMessage();
  }

  const pointDistribution = (e) => {
    e.preventDefault();
    setUpdatedPoints(false);
  let pointsData ={
    point: pointsValue,
    point_type: "2",
    question_id: item.question_id,
    response_id: item.question_response_id,
    point_sub_type: "gain_response_point",
    user_id: item.response_user_id
    }
    let token = localStorage.getItem("accessToken");
    (async () => {
      try {
        const res = await call(
          'post',
          '/add-user-points',
          null,
          pointsData,
          token
        )
        console.log(res.data)
        if(res?.data?.success===true){
        alert("Points Rewarded Successfully")
        closeModalPoints();
        setPointsValue();
        setUpdatedPoints(true);
        }
        else 
        setpointsError(res?.data?.message)
      } catch (err) {
        console.log(err)
      }
    })()
  }
console.log(item)
console.log(responseofResponse)
console.log(status)
console.log(totalDistributedPoints)
    return (
        <Fragment>
          {type==="myQuestion" ?
          isLoader ? <Loader/> :
          item.response_user_id === null ?
          <ul className="response-list">
                            <li className="response-list-item respond">
                            { item.spam_flage === 0 ?
                                  <span
                                  onClick={() => responofrespond(item.question_response_id)}
                                  >
                                  <img src={respond} alt="" />
                                  Respond
                                  </span> : <span style={{ color: "#dddddd" }}>
                                  <img src={respondDis} alt="" />
                                  Respond
                                </span>}
                            </li>
                          </ul>
          :
          <ul className="response-list">
                            <li className="response-list-item comment">
                              {status==="closed"? item.point===null?<span style={{ color: "#dddddd" }}>Points</span>:<span style={{ background: "#dddddd", color: "#ffffff" }}>{item.point}</span>:
                              (item.response_user_id===userData?.user?.data?.id) ? <span style={{ color: "#dddddd" }}>Points</span> : item.spam_flage === 0 ? item.point===null ?<span style={{ background: "#0d9edf", color: "#ffffff" }} onClick={openModal}>Points</span> : <span style={{ background: "#0d9edf", color: "#ffffff" }}>{item.point}</span>
                                
                                : item.point===null?<span style={{ color: "#dddddd" }}>Points</span>:<span style={{ background: "#dddddd", color: "#ffffff" }}>{item.point}</span>}
                            </li>
                            <li className="response-list-item like" style={{width:"55px"}}>
                            {status==="closed"? <span style={{ color: "#dddddd" }}>
                                  {item.upvote_count}
                                  <img src={likeDis} alt="" />
                                </span> : (item.response_user_id===userData?.user?.data?.id)? <span style={{ color: "#dddddd" }}>
                                  {item.upvote_count}
                                  <img src={likeDis} alt="" />
                                </span> : item.spam_flage === 0 ? item.upvote_flage === 0 ?
                                <span>
                                  {item.upvote_count}
                                  <img src={likee} alt="" />
                                </span> :
                                <span>
                                  {item.upvote_count}
                                  <img src={likeYes} alt="" />
                                </span> :
                                <span style={{ color: "#dddddd" }}>
                                  {item.upvote_count}
                                  <img src={likeDis} alt="" />
                                </span>}
                            </li>
                            <li className="response-list-item upvote">
                             {status==="closed"? <span style={{ color: "#dddddd" }}>
                                  <img src={upvoteDis} alt="" />
                                  Upvote
                                </span> : (item.response_user_id===userData?.user?.data?.id)?  <span style={{ color: "#dddddd" }}>
                                  <img src={upvoteDis} alt="" />
                                  Upvote </span> : item.spam_flage === 0 ?
                                item.upvote_flage === 0 ?
                                  <span onClick={() => responsefeedback(item.question_response_id, "upvote", item.upvote_count, "my_question")}>
                                    <img src={upvote} alt="" />
                                    Upvote
                                  </span> :
                                  <span style={{ color: "#E32227" }} onClick={() => responsefeedback(item.question_response_id, "remove_upvote", item.upvote_count, "my_question")}>
                                    <img src={downvote} alt="" />
                                    Devote
                                  </span> : <span style={{ color: "#dddddd" }}>
                                  <img src={upvoteDis} alt="" />
                                  Upvote
                                </span>}
                            </li>
                            <li className="response-list-item respond">
                            { item.spam_flage === 0 ?
                                  <span
                                  onClick={() => responofrespond(item.question_response_id)}
                                  >
                                  <img src={respond} alt="" />
                                  Respond
                                  </span> : <span style={{ color: "#dddddd" }}>
                                  <img src={respondDis} alt="" />
                                  Respond
                                </span>}
                            </li>
                            <li className="response-list-item upvote" style={{width:"80px"}}>
                            {status==="closed" || item.point!==null ? item.spam_flage === 0?<span style={{ color: "#dddddd" }}>
                                  {/* <img src={spam} alt="" /> */}
                                  Spam
                                </span>:<span style={{ color: "#dddddd", display:"flex" }}>
                                {/* <img style={{paddingTop:"4px"}} src={spammed} alt="" /> */}
                                Spammed
                              </span> : (item.response_user_id===userData?.user?.data?.id)? item.spam_flage === 0?<span style={{ color: "#dddddd" }}>
                                  {/* <img src={spam} alt="" /> */}
                                  Spam
                                </span>:<span style={{ color: "#dddddd", display:"flex" }}>
                                {/* <img style={{paddingTop:"4px"}} src={spammed} alt="" /> */}
                                Spammed
                              </span> : item.spam_flage === 0 ? item.upvote_flage === 0 ?
                                <span onClick={() => spamhandle(item.question_response_id, "my_question")}>
                                  <img src={spam} alt="" />
                                  Spam
                                </span> : <span style={{ color: "#dddddd" }}>
                                  {/* <img src={spam} alt="" /> */}
                                  Spam
                                </span> : <span style={{ color: "#FF0000", display:"flex" }}>
                                <img style={{paddingTop:"4px"}} src={spammed} alt="" />
                                Spammed
                              </span>}
                            </li>
                          </ul>
            :
                          isLoader ? <Loader/> :
                           item.response_user_id === null ?
                           <></>
                           :
                           <ul className="response-list">
                           {/* <li className="response-list-item comment">
                       {item.spam_flage===0?
                       <span onClick={openModal}>Points</span>
                       :  <span style={{color:"#dddddd"}}>Points</span>}
                     </li> */}
                           <li className="response-list-item like">
                            
                           {status==="closed"? <span style={{ color: "#dddddd" }}>
                                  {item.upvote_count}
                                  <img src={likeDis} alt="" />
                                </span> :item.spam_flage === 0 ? item.upvote_flage === 0 ?
                               <span>
                                 {item.upvote_count}
                                 <img src={likee} alt="" />
                               </span> :
                               <span>
                                 {item.upvote_count}
                                 <img src={likeYes} alt="" />
                               </span> :
                               <span style={{ color: "#dddddd" }}>
                                 {item.upvote_count}
                                 <img src={likeDis} alt="" />
                               </span>}
                           </li>
                           <li className="response-list-item upvote">
                          
                           {status==="closed"? <span style={{ color: "#dddddd" }}>
                                  <img src={upvoteDis} alt="" />
                                  Upvote
                                </span>: item.spam_flage === 0 && (item.response_user_id!==userData?.user?.data?.id)?
                               item.upvote_flage === 0 ?
                                 <span onClick={() => responsefeedback(item.question_response_id, "upvote", item.upvote_count, "other_question")}>
                                   <img src={upvote} alt="" />
                                   Upvote
                                 </span> :
                                 <span style={{ color: "#E32227" }} onClick={() => responsefeedback(item.question_response_id, "remove_upvote", item.upvote_count, "other_question")}>
                                   <img src={downvote} alt="" />
                                   Devote
                                 </span> : <span style={{ color: "#dddddd" }}>
                                 <img src={upvoteDis} alt="" />
                                 Upvote
                               </span>}
                           </li>
                           <li className="response-list-item respond">
                             {item.spam_flage === 0 ?
                               <span
                                 onClick={() => responofrespond(item.question_response_id)}
                               >
                                 <img src={respond} alt="" />
                                 Respond
                               </span> : <span style={{ color: "#dddddd" }}>
                                 <img src={respondDis} alt="" />
                                 Respond
                               </span>}
                           </li>
                           <li className="response-list-item upvote">
                           {status==="closed"? item.spam_flage === 0?<span style={{ color: "#dddddd" }}>
                                  {/* <img src={spam} alt="" /> */}
                                  Spam
                                </span>:<span style={{ color: "#dddddd", display:"flex" }}>
                                {/* <img style={{paddingTop:"4px"}} src={spammed} alt="" /> */}
                                Spammed
                              </span>: item.response_user_id!==userData?.user?.data?.id ?
                             item.spam_flage === 0 ? item.upvote_flage === 0 ?
                               <span onClick={() => spamhandle(item.question_response_id, "other_question")}>
                                 <img src={spam} alt="" />
                                 Spam
                               </span> : <span style={{ color: "#dddddd" }}>
                                 {/* <img src={spam} alt="" /> */}
                                 Spam
                               </span> : <span style={{ color: "#FF0000" }}>
                               <img src={spammed} alt="" />
                               Spammed
                             </span>:
                             <span style={{ color: "#dddddd" }}>
                             {/* <img src={spam} alt="" /> */}
                             Spam
                           </span> }
                           </li>
                          </ul>
}

            <Modal
        size="lg" style={{ width: '100%' }}
        background
        show={isOpen}
        onHide={closeModal}

        dialogClassName="modal-90w"
        classname="modal-dialog2"
        aria-labelledby="example-custom-modal-styling-title"
        //  aria-labelledby="contained-modal-title-vcenter"
        centered

      >

        <Modal.Body style={{ backgroundColor: "#f4f7fc" }}>
          <section className="dasboard_main_area list-experts-area questions-main-section single-question">
            <div className="back-btn-main">
              <span style={{ cursor: "pointer" }} onClick={closeModal}>
                <KeyboardBackspaceIcon /> Back
              </span>
            </div>
            {responseofResponse && responseofResponse?.data?.map((item, index) => {
              return <div className="my-question-section single-question-section" key={index}>
                <div className="my-question-container">
                  <div className="my-question-item">
                    <div className="response-item">
                      <div className="response-content">
                        <p>
                          {item.feedback_text}
                        </p>
                        <br />
                        {/* <p><b><i>Attached Picture:</i></b></p> */}
                        {item.feedback_attached_url &&
                           <div style={{}}>
                           <App imageURL={item.feedback_attached_url} width="100%" />
                         </div>}
                       


                      </div> 
                      <div className="response-details row">
                        <div className="response-user col-12">
                          <span className="response-img">
                            {
                              item.screen_name ? <App imageURL={item.profile_image} width="25px" height="25px"/> :
                              <img src={gptPic} alt="" width= "25px" height="25px"/>
                            }
                          
                          </span>
                          <span style={{paddingLeft:"8px"}} className="response-user-name">
                            {item.screen_name ? <Link>{item.screen_name}</Link> : <Link>{"Chat GPT"}</Link>}
                          </span>
                          {
                            item.screen_name ? <span className="response-user-name">
                            <Link>{item.badge_name ? item.badge_name : "N/A"}</Link>
                          </span> : <></>
                          }
                          <span className="response-data"><span className="response-date">{new Date(item.createdAt)
                             .toLocaleDateString('en-US', {
                              day: 'numeric', month: 'short', year: 'numeric'
                            }).replace(/ /g, ' ')}</span></span>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>

              </div>
            })}
            <section className="responses-wrapper">
            {status==="closed" ? <div style={{color:"#ff0000"}}>A new response is not allowed in the close questions</div> :
              <div className="respond-text-area account ">
                <Form>
                  <Form.Group
                    className="mt-3 position-relative"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <span>{item.response_user_id === null ? "" : "Only one sub-response per user is allowed"}</span>
                    <Form.Control as="textarea" rows={3} onChange={onChange} onClick={resetError}
                      value={responseData} />

                    {
                      item.response_user_id === null ? <></> :
                      <span className="up-load-btn">
                      <form >
                        <label
                          className="d-flex justify-content-end">
                          <AttachFileIcon sx={{ fontSize: 20 }} />Add Picture

                          <input
                            type="file"
                            size="lg"

                            style={{ display: "none" }}
                            onChange={onImageChange}
                          />
                        </label>
                      </form>

                      {filename && (
                        <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {truncate(filename)}<img src={delIcon} onClick={clearImage}/>

                        </span>
                      )}


                    </span>
                    }
                  </Form.Group>
                  {errorResponse && (
                    <label className="label-input100 text-danger" >
                      {errorResponse}
                    </label>
                  )}
                </Form>

                <div className="row submit-btn single-question-submit">
                  <div className="text-left small">
                    <button className="btn btn-outline " onClick={closeModal}>Cancel </button>
                    <button type = "button" disabled = {gptLoader} className="btn btn-xl shadow-none" onClick={() => responsefeedback(responseofResponse.question_response_id, "respond",null,null)}>{gptLoader ? 
                    <div className = "custom-loader">
                      <ThreeDots 
                    height="50" 
                    width="40" 
                    radius="9"
                    color="#ffffff" 
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}/>
                    </div> : "Send"}</button>
                  </div>
                </div>
              </div>}

            </section>
          </section>
        </Modal.Body>
      </Modal>
      <Modal
        show={isSpam}
        onHide={closeSpamModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>

        </Modal.Header>

        <Modal.Body className="px-5 terms-1">
          <h5 style={{ color: "#000000", textAlign: "center" }}>Are you sure you want to mark this response as Spam?</h5>
        </Modal.Body>
        <div style={{ display: "flex", justifyContent: "center", paddingBottom: "20px" }}>

          <div className="text-left small">
            <button className="btn btn-outline mx-3 " onClick={closeSpamModal}>No </button>
            <button className="btn btn-xl shadow-none " onClick={() => responsefeedback(spamId, "spam", null, spamType)}>Yes</button>
          </div>

        </div>
       
      </Modal>
      <Modal
        show={isOpenPoints}
        onHide={closeModalPoints}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>

        </Modal.Header>

        <Modal.Body className="px-5 terms-1">
          <h5 style={{ color: "#000000", textAlign: "center" }}>Enter the point you want to give this response.</h5>
        </Modal.Body>
        <form id="myForm" onSubmit={pointDistribution}>
        <div style={{ display: "flex", justifyContent: "center", paddingBottom: "20px" }}>
        
          <div style={{ display: "flex", justifyContent: "center", paddingLeft: "30px", paddingRight: "30px", height: "50px", width: "120px", border: "2px solid black" }}>
<input style={{ textAlign: "center", border: "none" }} id="myNumber" name="myNumber" type="number" min="1" max="200" onChange={pointsHandle} required ></input></div>
        </div>
        <div style={{ display: "flex", justifyContent: "center", paddingBottom: "20px" }}>
        <i>Note: Enter the points in range between 1 to 200. </i></div>
        <div style={{ display: "flex", justifyContent: "space-evenly", paddingBottom: "20px" }}>
        <div style={{color:"#0d9edf"}}>Total Distributed Points:{totalDistributedPoints}</div><div style={{color:"#0d9edf"}}>Reserved Points: {reservedPoints}</div></div>
        {warningMessage && (
                    <div style={{ display: "flex", justifyContent: "center", paddingBottom: "5px", color: "#ff0000"}}>
                    <i>Warning: The extra points {warningMessage} will be deducted from wallet.</i></div>
                  )}
        <div style={{ display: "flex", justifyContent: "center", paddingBottom: "20px", color: "#ff0000"}}>
       
        <b>{pointsError}</b></div>

      
        <div style={{ display: "flex", justifyContent: "center", paddingBottom: "20px" }}>
     

          <button style={{ padding: "15px", borderRadius: "5px", width: "300px" }} type="submit" className="btn-xl" >Submit</button></div>
          </form>
      </Modal>
        </Fragment>
    )
}

export default Respond;
