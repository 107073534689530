import React, { useState, useEffect } from "react";
import ReactGoogleLogin from "react-google-login";
import apple from "../assets/img/apple.png";
import face from "../assets/img/face.png";
// import twitter from "../assets/img/twitter.png";
import google from "../assets/img/google.png";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { Redirect } from 'react-router-dom';
import { call } from '../utils/apiCall'
// import { Email } from "@mui/icons-material";
import useAuth from '../hooks/useAuth'
// import { gapi } from 'gapi-script';
// import env from 'react-dotenv';


export default function GoogleLogin(props) {
  const [aute, setAute] = useState(null);
  const [passData, setPassData] = useState();
  const { login } = useAuth()

  // useEffect(() => {
  //   function start() {
  //     gapi.client.init({
  //      // clientId: env.REACT_PUBLIC_GOOGLE_CLIENT_ID,
  //       scope: 'email',
  //     });
  //   }

  //   gapi.load('client:auth2', start);
  // }, []);

  const signup = (res, type) => {
    let postData;
   console.log(res)
    if (type === 'google' && res.googleId) {
      postData = {
        registration_type: type,
        username: res.profileObj.email,
        password: res.googleId
      };
      setPassData(postData)
    }
    if (postData) {
    //   (async () => {
    //     try {
    //         const res = await call(
    //             'post',
    //             '/login',
    //             null,
    //             postData
    //         )
    //         if(res.data.success===false && res.data.message==="Username or password is incorrect."){
    //         setAute("newuser")
            
    //       }
          
            
    //     } catch (err) {
    //         console.log(err)
    //     }
    // })()
    (async () => {
      try {
          await login(postData.username, postData.password, postData.registration_type);
          //setResponsePath("loginSuccess")
          setAute("loginSuccess")
      } catch (e) {
          console.log(e)
          console.log(e.data)
          if (e.data===undefined){
            setAute("newuser")
          }
          else if (e.data!==undefined) {
            if (window.confirm('The user already exists. Are you the same user or not?')) {
            // Save it!
            let updateData={social_token:postData.password, registration_type: postData.registration_type};
            let token=e.data.token;
            (async () => {
              try {
                  const res = await call(
                      'patch',
                      '/update-social-token',
                      null,
                      updateData,
                    token
                  )
               if(res.data.success===true){
                 console.log(res.data.message)
                 setAute("loginSuccess")
                 
               }
                  
              } catch (err) {
                  console.log(err)
              }
          })()
          } else {
            // Do nothing!
            console.log('Thing was not saved to the database.');
            setAute("newuser")
          }
         
         
      }
    }
  })()
      } else {}
      console.log(aute)
    console.log(postData)
  }

  const GoogleResponse = resp => {
    signup(resp, 'google');
    console.log(resp);
  };

  const FacebookResponse = resp => {
    console.log(resp);
  };

  // const TwitterResponse = (error, data) => {
  //   if (error) return console.error(error);
  //   console.log(data);
  // };

  useEffect(() => {
    // if(aute){
    //   return <Redirect to="/login" />
    // } else {
    //   return (
    //   console.log()
    // );
    // }
  });
  return aute ==="newuser" ? (
    <Redirect to={{ pathname: '/signup', data:  passData  }}/>
  ) : aute ==="loginSuccess" ?  <Redirect to={{ pathname: '/dashboard', data:  passData  }}/> : (


    <div style={{display:"flex"}}>
      <div style={{padding:"8px"}}>
        <ReactGoogleLogin
          clientId="877361901509-hm58nqrssq47jq0ra8q2ri1gfv48p3fn.apps.googleusercontent.com" // We created this earlier, remember?
          render={(renderProps) => (
            <button style={{ border: "none", background: "none" }} onClick={renderProps.onClick} disabled={renderProps.disabled}>
              <img src={google} alt="img" className="img-fluid" />
            </button>
          )}

          buttonText="Login with Google"
          onSuccess={GoogleResponse}
          onFailure={GoogleResponse}
       
        //       uxMode='redirect'
        // redirectUri="http://localhost:3000/basic"
        />
      </div>

      <div style={{padding:"8px"}}>
        <FacebookLogin
          render={(renderProps) => (
            <span style={{ border: "none", background: "none" }} onClick={renderProps.onClick} disabled={renderProps.disabled}>
              <img src={face} alt="img" className="img-fluid" />
            </span>
          )}
          appId="373539901205426" // we created this, remember?
          autoLoad={false}
          fields="name,email,picture"
          callback={FacebookResponse}
          onFailure={FacebookResponse}

        />
      </div>
      <div style={{padding:"8px"}}>
      <FacebookLogin
          render={(renderProps) => (
            <span style={{ border: "none", background: "none" }} onClick={renderProps.onClick} disabled={renderProps.disabled}>
              <img src={apple} alt="img" className="img-fluid" />
            </span>
          )}
          appId="373539901205426" // we created this, remember?
          autoLoad={false}
          fields="name,email,picture"
          callback={FacebookResponse}
          onFailure={FacebookResponse}

        />
      </div>
      {/* <div style={{padding:"8px"}} >
        <FacebookLogin
          render={(renderProps) => (
            <button style={{ border: "none", background: "none" }} onClick={renderProps.onClick} disabled={renderProps.disabled}>
              <img src={face} alt="img" className="img-fluid" />
            </button>
          )}
          appId="373539901205426" // we created this, remember?
          autoLoad={true}
          fields="name,email,picture"
          callback={FacebookResponse}
          onFailure={FacebookResponse}

        />
      </div> */}
      {/* <div style={{padding:"8px"}} >
        <FacebookLogin
          render={(renderProps) => (
            <button style={{ border: "none", background: "none" }} onClick={renderProps.onClick} disabled={renderProps.disabled}>
              <img src={face} alt="img" className="img-fluid" />
            </button>
          )}
          appId="373539901205426" // we created this, remember?
          autoLoad={true}
          fields="name,email,picture"
          callback={FacebookResponse}
          onFailure={FacebookResponse}

        />
      </div> */}
    </div>



  );
}