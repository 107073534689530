import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { call } from '../utils/apiCall';


const firebaseConfig = {
  apiKey: "AIzaSyBBwWCwqtCRMm0vkQQnmb1PHG3oKxnpckk",
  authDomain: "andverturesportsapp.firebaseapp.com",
  projectId: "andverturesportsapp",
  storageBucket: "andverturesportsapp.appspot.com",
  messagingSenderId: "828465177905",
  appId: "1:828465177905:web:69dd2463fe79ed8351fc10",
  measurementId: "G-1ETPB3K9D4"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const fetchToken = (setTokenFound) => {
  return getToken(messaging, {vapidKey: 'BDjM1oSt3ZoeCDMXqvIgq0V3oVKrht6Xkp1be0AH3jDZC_e4HzKf8JGy_7ysP30IJxQGoAdPIUUuxN8FoC-3XXc'}).then((currentToken) => {
    if (currentToken) {
      let token = localStorage.getItem("accessToken");
      console.log('FCM Client Token: ', currentToken);
      setTokenFound(true);
      let tokenData = {
        fcm_token: currentToken,
        device_os_type: "android"
      };
      (async () => {
        try {
            const res = await call(
                'post',
                '/update-fcm',
                null,
                tokenData,
                token
            )
            console.log(res.data)
          } catch (err) {
            console.log(err)
        }
    })()
    } else {
      console.log('No regtoken available and req permission to generate one.');
      setTokenFound(false);
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log(' Unable to retrieve token. ', err);
    // catch error while creating client token
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});