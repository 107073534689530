import axios from 'axios'
import { API_URL } from '../constants/constants'
//const accessToken = localStorage.getItem('accessToken');
export const call = async (method, url, params = {}, data = {}, token) => {
    console.log(token)
    // if (accessToken) {
    //     axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
        const result = await axios({
            method,
            url: API_URL + url,
            params,
            data,
            headers: {
                'Content-Type': 'application/json',
                'authorization':token ? `${token}` : '',
            },
           // timeout: 60000,
            responseType: 'json',
        })
console.log(url)
        return result
    // }
   // return null

}
