import React, { useState } from "react";
import logo from "../../assets/img/logo.png";
import newlogo2 from "../../assets/img/newlogo2.jpg"
import hamburger from "../../assets/img/mob-btn-icon.svg";
import { NavLink } from "react-router-dom";

const Sidebar = () => {
  const [close, setClose] = useState(true);

  const menuToggle = () => setClose(!close);
  return (
    <div>
      <main className="page_wrapper">
        <section className="dashboard_section d-flex">
          <div className={close ? "side_bar_wrapper" : "side-bar-active"}>
            <div className="logo-main">
              <a href="/dashboard">
                <img className="img-fluid" src={newlogo2} width={"150px"} height = {"60px"} alt="" />
              </a>
              <span className="close-btn" onClick={menuToggle}>
                ⤫
              </span>
            </div>
            <nav className="side_navigation">
              <ul className="side_nav">
                <li className="side_nav_item dasboard_item ">
                  <NavLink to={"/dashboard"} exact activeClassName="selected">
                    Dashboard
                  </NavLink>
                </li>
                <li className="side_nav_item question_item">
                  <NavLink to={"/question"} exact activeClassName="selected">
                    Questions
                  </NavLink>
                </li>
                <li className="side_nav_item points_item">
                  <NavLink to={"/points"} exact activeClassName="selected">
                    Points
                  </NavLink>
                </li>
                <li className="side_nav_item experts_list_item">
                  <NavLink to={"/list"} exact activeClassName="selected">
                    List of Experts
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>
          <span className="mob-btn" onClick={menuToggle}>
            <img className="img-fluid" src={hamburger} alt="img" />
          </span>
        </section>
      </main>
    </div>
  );
};

export default Sidebar;
