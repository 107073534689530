import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import MaskedInput from 'react-maskedinput';
import moment from 'moment';
const QuestionsFilter = ({
  filterBox,
  showResponded,
  filterData,
  setFilterData,
  update,
  setUpdate,
  close,
  locationList,
  filtername,
  tabName,
  selected,
  setSelected
}) => {

  
  const [optionsList, setOptionsList] = useState([]);
  const [checked, setChecked] = useState({ city: false, state: false, country:false });

  const handleDateChange = async (date, text) => {
    if(date === null){
      console.log(date)
      setFilterData({
        ...filterData,
          [text] : "",
      })
    }
    else{
      const selected = moment(date).toDate();
    //   var mydate = new Date(date);
    // console.log(mydate)
    var dateString = new Date(selected.getTime() - (selected.getTimezoneOffset() * 60000 ))
                        .toISOString()
                        .split("T")[0];
    
    const finalDate = dateString.replace(/-/g, '/')
      
    setFilterData({
      ...filterData,
      [text]: finalDate,
    })
     
    }
    
  }

  useEffect( () => {
   // setSelected([]);
    setChecked({city: false, state: false, country:false  })
  }, [tabName])

  useEffect(() => {
    const options = locationList.map((item) => ({
      value: {
        country_id: item.country_id,
        state_id: item.state_id,
        city_id: item.city_id,
      },
      label: item.location_name,
    }));
   // console.log(locationList);
    setOptionsList(options);
  }, [locationList]);

  console.log(filterData)
  useEffect(() => {
    if (selected.length === 0) {     
      setFilterData({...filterData, location: null})
      setChecked(() => {
        return {
          city: false,
          state: false,
          country: false
        };
      });
    } else {
      let newData = selected.map((item) => item.value);
      setFilterData({...filterData, location: newData})
    }
  }, [selected]);

  const resetFilterData = () => {
    setSelected([]);
    setFilterData({
      question_status: null,
      question_from_date: null,
      question_to_date: null,
      last_response_from_date: null,
      last_response_to_date: null,
      location: null,
      ...(showResponded && { my_responsed_question: null }),
    });
    setChecked(() => ({ city: false, state: false, country: false }))
  };
  const handleCheckedCity = (e) => {
    setFilterData({
      ...filterData,
     city_flag: e.target.checked.toString(),
     country_flag: "false",
     state_flag: "false",})
     setChecked(() => {
      return {
        city: true,
        state: false,
        country: false
      };
    });
  };
 
  const handleCheckedState = (e) => {
    setFilterData({
      ...filterData,
     state_flag: e.target.checked.toString(),
     city_flag: "false",
     country_flag: "false"})
     setChecked(() => {
      return {
        city: false,
        state: true,
        country: false
      };
    });
  };

  const handleCheckedCountry = (e) => {
   
    setFilterData({
      ...filterData,
     country_flag: e.target.checked.toString(),
     state_flag: "false",
     city_flag: "false",})
     setChecked(() => {
      return {
        city: false,
        state: false,
        country: true
      };
    });
  };

  const handlelocationdata = (e) => {
    setSelected(e);
    setFilterData({
      ...filterData,
     city_flag: "true",
     country_flag: "false",
     state_flag: "false",})
     if(checked.state===false && checked.country===false){
     setChecked(() => {
      return {
        city: true,
        state: false,
        country: false
      };
    });}
  };
  
  return (
    <div className={filterBox ? "d-none" : "d-block"}>
      <div className="filter-box question-filter-box text-start">
        <div className="inner-filter">
          {showResponded ? (
            <>
              <Form.Label className="label-input100  ">
                My Responded Ques
              </Form.Label>
              <Form.Select
                value={filterData.my_responsed_question || ""}
                onChange={(e) =>
                  setFilterData({
                    ...filterData,
                    my_responsed_question: e.target.value,
                  })
                }
                // onChange={e => console.log(e.target.value)}
                aria-label="Default select example"
              >
                <option value={""}>Select</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </Form.Select>
            </>
          ) : null}
          <Form.Label className="label-input100  ">Status</Form.Label>
          <Form.Select
            value={filterData.question_status || ""}
            onChange={(e) =>
              setFilterData({ ...filterData, question_status: e.target.value })
            }
            aria-label="Select Status"
          >
            <option value={""}>Select</option>
            <option value="open">Open</option>
            <option value="closed">Closed</option>
          </Form.Select>
          <div className="row account my-2">
            <div className="field-label">
              <label>Question Added Date</label>
            </div>
            <div className="date-range-field d-flex">
              <div className="date-range-start date-range">
                <Form.Group controlId="Question Date">
                  {/* <Form.Control
                    value={filterData.question_from_date || ""}
                    type="date"
                    name="QDFrom"
                    placeholder="Question Date"
                    onChange={(e) =>
                      setFilterData({
                        ...filterData,
                        question_from_date: e.target.value,
                      })
                    }
                  /> */}
                  <DatePicker 
                          selected={filterData.question_from_date === "" || !filterData.question_from_date ? filterData.question_from_date : new Date(filterData.question_from_date)} 
                          dateFormat = "yyyy/MM/dd"
                          minDate = {new Date('1940-01-01')}
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown={true}
                          showYearDropdown
                          showMonthDropdown
                          onChange={(date) => handleDateChange(date, "question_from_date")} 
                          customInput = {
                            <MaskedInput mask="1111/11/11"></MaskedInput>
                          }
                    />
                </Form.Group>
              </div>
              <div className="range-text">
                <span>to</span>
              </div>
              <div className="date-range-start date-range">
                <Form.Group controlId="Question Date">
                  {/* <Form.Control
                    value={filterData.question_to_date || ""}
                    type="date"
                    name="QDTo"
                    placeholder="Question Date"
                    onChange={(e) =>
                      setFilterData({
                        ...filterData,
                        question_to_date: e.target.value,
                      })
                    }
                  /> */}
                  <DatePicker 
                          selected={filterData.question_to_date === "" || !filterData.question_to_date ? filterData.question_to_date : new Date(filterData.question_to_date)} 
                          dateFormat = "yyyy/MM/dd"
                          minDate = {new Date('1940-01-01')}
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown={true}
                          showYearDropdown
                          showMonthDropdown
                          onChange={(date) => handleDateChange(date, "question_to_date")} 
                          customInput = {
                            <MaskedInput mask="1111/11/11"></MaskedInput>
                          }
                    /> 
                </Form.Group>
              </div>
            </div>
          </div>
          <div className="row account ">
            <div className="field-label">
              <label>Last Response Date</label>
            </div>
            <div className="date-range-field d-flex">
              <div className="date-range-start date-range">
                <Form.Group controlId="Question Date">
                  {/* <Form.Control
                    value={filterData.last_response_from_date || ""}
                    type="date"
                    name="LRFrom"
                    placeholder="Date of Last Response"
                    onChange={(e) =>
                      setFilterData({
                        ...filterData,
                        last_response_from_date: e.target.value,
                      })
                    }
                  /> */}
                  <DatePicker 
                          selected={filterData.last_response_from_date === "" || !filterData.last_response_from_date ? filterData.last_response_from_date : new Date(filterData.last_response_from_date)} 
                          dateFormat = "yyyy/MM/dd"
                          minDate = {new Date('1940-01-01')}
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown={true}
                          showYearDropdown
                          showMonthDropdown
                          onChange={(date) => handleDateChange(date, "last_response_from_date")} 
                          customInput = {
                            <MaskedInput mask="1111/11/11"></MaskedInput>
                          }
                    /> 
                </Form.Group>
              </div>
              <div className="range-text">
                <span>to</span>
              </div>
              <div className="date-range-start date-range">
                <Form.Group controlId="Question Date">
                  {/* <Form.Control
                    value={filterData.last_response_to_date || ""}
                    type="date"
                    name="LRTo"
                    placeholder="Date of Last Response"
                    onChange={(e) =>
                      setFilterData({
                        ...filterData,
                        last_response_to_date: e.target.value,
                      })
                    }
                  /> */}

                    <DatePicker 
                          selected={filterData.last_response_to_date === "" || !filterData.last_response_to_date ? filterData.last_response_to_date : new Date(filterData.last_response_to_date)} 
                          dateFormat = "yyyy/MM/dd"
                          onChange={(date) => handleDateChange(date, "last_response_to_date")}
                          minDate = {new Date('1940-01-01')}
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown={true}
                          showYearDropdown
                          showMonthDropdown
                          customInput = {
                            <MaskedInput mask="1111/11/11"></MaskedInput>
                          }
                    />  
                </Form.Group>
              </div>
            </div>
          </div>
{/*        
                    <div style={{ display: "flex",
                justifyContent: "space-around"}}> 
 <div className="input-box1"> <input type="radio" id="" name="filter" value="City"  onChange={handleCheckedCity}/>
 City</div>
 <div className="input-box1">
  <input type="radio" id="state" name="filter" value="State"  onChange={handleCheckedState}/>
State</div>
<div className="input-box1">
  <input type="radio" id="country" name="filter" value="Country" onChange={handleCheckedCountry}/>
 Country</div></div> */}
          <Form.Label className="label-input100 mt-2 ">Location</Form.Label>
          <Select
              value={selected}
              isMulti
              options={optionsList}
              onChange={handlelocationdata}
              placeholder="Select"
              theme={theme => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  neutral50: 'black',  // Placeholder color
                },
              })}
          />
 <div style={{paddingTop:"10px"}}>   <div className="field-label">
        <label>Filter by</label> 
            </div></div>
          
        
                    <div style={{ display: "flex",
                justifyContent: "space-around"}}> 
            <div class="flex-items"><div className="input-box1">
                      <input type="radio" name="filter" id="city" checked={checked.city}
                      onChange={handleCheckedCity}
                    />City
                      </div></div> 
            <div class="flex-items"> <div className="input-box1">
                      <input type="radio" name="filter" id="state"  checked={checked.state}onChange={handleCheckedState}/>State
                      </div></div> 
            <div class="flex-items"> <div className="input-box1">
                      <input type="radio" name="filter" id="country"  checked={checked.country} onChange={handleCheckedCountry}/>Country
                    </div></div> 
        </div> 
          <div className="d-flex justify-content-center mt-2">
            <button
              onClick={resetFilterData}
              className=" bg-transparent border-0 text-primary text-decoration-underline"
            >
              Reset All Filters
            </button>
          </div>
          <div className="d-flex justify-content-between mt-4">
            <button className="btn btn-outline " onClick={() => close()}>
              Cancel
            </button>
            <Button
              className="btn btn-xl shadow-none"
              onClick={() => {
                setUpdate(!update);
                close();
              }}
            >
              Apply
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionsFilter;
