import axios from 'axios'
import { call } from '../utils/apiCall'


const setSession = (accessToken) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken)
       // axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else {
        localStorage.removeItem('accessToken')
        delete axios.defaults.headers.common.Authorization
    }
}

class AuthApi {
    login(email, password, type) {
        return new Promise(async (resolve, reject) => {
            let loginData= {
                registration_type: type,
                username: email,
                password: password
              };

              (async () => {
                try {
                    const res = await call(
                        'post',
                        '/login',
                        null,
                        loginData
                    )
                    if(res.data.success===true){
                        const accessToken = res.data.data.token;
                        setSession(accessToken)
                        resolve(res.data)
                  }
                  if(res.data.success===false){
                    reject(res.data)
                  }
                    
                } catch (err) {
                    console.log(err)
                    reject(err)
                }
            })()
        })
    }

    register(formdata) {
        return new Promise(async (resolve, reject) => {
            (async () => {
                try {
                    const res = await call(
                        'post',
                        '/add-user',
                        null,
                        formdata
                    )
                    console.log(res.data)
                    resolve(res.data)
                   
                 
                } catch (err) {
      
                    console.log(err)
                    reject(err)
                    
                }
            })()   
        })
    }

    updateDetails(formdata) {
        return new Promise(async (resolve, reject) => {
            const token = localStorage.getItem("accessToken");
            (async () => {
                try {
                    const res = await call(
                        'patch',
                        '/update-user',
                        null,
                        formdata,
                        token
                    )
                    console.log(res.data)
                    resolve(res.data)
                   
                 
                } catch (err) {
      
                    console.log(err)
                    reject(err)
                    
                }
            })()   
        })
    }
    // forgotPassword(email) {
    //     return new Promise(async (resolve, reject) => {
    //         let pool = getCognitoUserPool()
    //         let poolUser = getCognitoUser(email, pool)

    //         poolUser.forgotPassword({
    //             onSuccess: function (data) {
    //                 resolve(data);
    //             },
    //             onFailure: function (err) {
    //                 reject(err);
    //             },
    //             inputVerificationCode: function (data) {
    //                 resolve(data)
    //             },
    //         })
    //     })
    // }

    // resetPassword(email,code,password) {
    //     return new Promise(async (resolve,reject) => {
    //         let pool = getCognitoUserPool()
    //         let poolUser = getCognitoUser(email, pool)

    //         poolUser.confirmPassword(code,password, {
    //             onSuccess: (data) => {
    //                 resolve(data);
    //             },
    //             onFailure: (err) => {
    //                 reject(err);
    //             }
    //         }) 
    //     })
    // }
}

export const authApi = new AuthApi()
