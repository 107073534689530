import React, { useEffect, useState } from "react";
import Sidebar from "../components/layout/Sidebar";
import Header from "../components/layout/Header";
import about from "../assets/img/about.png";
import { call } from "../utils/apiCall";
import useAuth from "../hooks/useAuth";

const About = () => {
  const [data, setData] = useState("");

  useEffect(() => {
    (async () => {
      try {
        const res = await call(
          "get",
          "/get-footer-content?search=about_us",
          null,
          null,
          null
        );
        setData(res?.data?.data[0].footer_text);
        console.log(res.data.data, "about us Data");
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const { isAuthenticated } = useAuth();
  return (
    <>
      {isAuthenticated ? (
        <main className="page_wrapper">
          <section className="dashboard_section d-flex">
              <div>
                <Sidebar />
              </div>
            <section className="dashboard_main_section">
              {isAuthenticated && <Header title="About us" />}
              <section className="dasboard_main_area list-experts-area">
                <div className="about p-3 bg-white">
                  <img src={about} alt="img" width={"100%"} />
                  <p className="mt-2 text-align-justify">{data}</p>
                </div>
              </section>
            </section>
          </section>
        </main>
      ) : (
        <section className="dasboard_main_area list-experts-area">
          {!isAuthenticated && <h1>About us</h1>}
          <div className="about p-3 bg-white">
            <img src={about} alt="img" width={"100%"} />
            <p className="mt-2 text-align-justify">{data}</p>
          </div>
        </section>
      )}
    </>
  );
};

export default About;
