import React, {useContext, useEffect} from 'react'
import useAuth from '../hooks/useAuth'
import { Route, useLocation, Redirect } from "react-router-dom";
import AuthContext from "../contexts/AuthContext";
import jwtDecode from 'jwt-decode'
import Loader from '../components/Loaders/ComponentLoader'


const PrivateRoute = (props) => {

   const checkToken = () => {
    const accessToken = window.localStorage.getItem('accessToken')
    // console.log(accessToken)
    // console.log((jwtDecode(accessToken).exp < Date.now() / 1000))
    if (accessToken ){
      if((jwtDecode(accessToken).exp < Date.now() / 1000)){
        expirytoken();
    return false }
      else {
       return true}
    }
    expirytoken();
    return false;
   }
    const count = useContext(AuthContext);
    const location = useLocation();
    const { isAuthenticated } = useAuth()
    const { expirytoken } = useAuth()
    let authenticated = isAuthenticated;
    console.log("authLogin", authenticated);
    const s=checkToken()
   console.log(s)
    return authenticated ? (
      <Route {...props} />
    ) : (authenticated===false)?(
      <Redirect
        to={{
          pathname: "/login",
          state: { from: location }
        }}
      />
    ):<div><Loader/></div>
  };

  export default PrivateRoute;