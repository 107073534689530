import { useState, useEffect } from "react";
import Basic from "./BasicDetails";
import Other from "./OtherDetails";
import Personal from "./PersonalDetails";
import { call } from "../../utils/apiCall";
import user from "../../assets/img/user.png";
import { Redirect, useLocation, useHistory } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import moment from 'moment';

const SignUp = (props) => {
  const { isAuthenticated } = useAuth();

  const location = useLocation();
  const history = useHistory();

  if (isAuthenticated) {
    const { from } = location.state || { from: { pathname: "/dashboard" } };
    history.replace(from);
  }

  const [step, setstep] = useState(1);
  const [countrylist, setCountrylist] = useState("");
  const [statedata, setStatedata] = useState("");
  const [locationStateData, setLocationStateData] = useState("");
  const [locationCityData, setLocationCityData] = useState("");
  const [citydata, setCitydata] = useState("");
  const [imgData, setImgData] = useState(user);
  const [screendata, setScreendata] = useState("");
  const [emailCheck, setEmailCheck] = useState("");
  const [referralData, setReferralData] = useState(null)
  const [locationdata, setLocationdata] = useState({
    country_id: "",
    state_id: "",
    city_id: "",
  });
  const [travel, setTravel] = useState("");
  const [formData, setFormData] = useState({
    email: "",
    confirmemail: "",
    firstname: "",
    lastname: "",
    password: "",
    confirmpassword: "",
    dob: "",
    country: "",
    state: "",
    city: "",
    profile_pic: "",
    screen_name: "",
    phone: "",
    location: "",
    language: "",
    employer: "",
    gender: "",
    occupation : "",
    religion: "",
    marital: "",
    kids: "null",
    food: "",
    fb_link: "",
    registration: "email",
    googleToken: "",
    currentstatus: "",
    referral_data: null
  });

  const [locationdata2, setLocationdata2] = useState([]);
  const [languageData, setLanguageData] = useState([]);

  useEffect(() => {
    if (props.location && props.location.data) {
      setFormData({
        ...formData,
        googleToken: props.location.data.password,
        registration: props.location.data.registration_type,
        email: props.location.data.username,
        confirmemail: props.location.data.username,
      });
    }
  }, [props.location]);

  useEffect(() => {
    (async () => {
      try {
        const res = await call(
          "get",
          "/email-exist/" + `${formData.email}`,
          null,
          null
        );
        setEmailCheck(res.data);
      } catch (err) {
        console.log(err);
        setEmailCheck("notValid");
      }
    })();
  }, [formData.email]);

  useEffect(() => {
    (async () => {
      try {
        const res = await call("get", "/country", null, null);
        setCountrylist(res.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useEffect(() => {
    if (formData.country !== "") {
      (async () => {
        try {
          const res = await call(
            "get",
            "/state/" + formData.country,
            null,
            null
          );
          setStatedata(res.data);
        } catch (err) {
          console.log(err);
        }
      })();
    }
  }, [formData.country]);

  useEffect(() => {
    if (formData.state !== "") {
      (async () => {
        try {
          const res = await call("get", "/city/" + formData.state, null, null);
          setCitydata(res.data);
        } catch (err) {
          console.log(err);
        }
      })();
    }
  }, [formData.state]);

  useEffect(() => {
    (async () => {
      try {
        const res = await call("post", "/check-screen-name", null, {
          screen_name: formData.screen_name,
          fname: formData.firstname,
          lname: formData.lastname,
          suggestions: [],
        });
        setScreendata(res.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [formData.screen_name]);

  useEffect(() => {
    if (locationdata.country_id !== "") {
      (async () => {
        try {
          const res = await call(
            "get",
            "/state/" + locationdata.country_id.id,
            null,
            null
          );
          console.log(res.data);
          setLocationStateData(res.data);
        } catch (err) {
          console.log(err);
        }
      })();
    }
  }, [locationdata.country_id]);

  useEffect(() => {
    if (locationdata.state_id !== "") {
      (async () => {
        try {
          const res = await call(
            "get",
            "/city/" + locationdata.state_id.id,
            null,
            null
          );
          console.log(res.data);
          setLocationCityData(res.data);
        } catch (err) {
          console.log(err);
        }
      })();
    }
  }, [locationdata.state_id]);

  const AddLocationList = () => {
    let filterId = locationdata2.map((item) => {
      return {
        country_id: item.country_id.id,
        state_id: item.state_id.id,
        city_id: item.city_id.id,
      };
    });
    console.log(filterId);
    setFormData({
      ...formData,
      location: filterId,
    });
  };

  useEffect(() => {
    let filterId = languageData.map((item) => {
      return {
        language_id: item.id,
      };
    });
    setFormData({
      ...formData,
      language: filterId,
    });
  }, [languageData]);

  console.log(locationdata2)
  console.log(locationdata)
const AddPlace = () => {
    if(locationdata2.some(user=>user.city_id.id === locationdata.city_id?.id))
{
  alert("Place already added. Chose another Place.")
   
}

else {
    setLocationdata2((prevState) => [...prevState, locationdata]);
    setLocationdata({
      country_id: "",
      state_id: "",
      city_id: "",
    });
  }
  };

  const nextStep = () => {
    setstep(step + 1);
    setLocationdata({
      country_id: "",
      state_id: "",
      city_id: "",
    });
  };

  const prevStep = () => {
    setstep(step - 1);
    setLocationdata({
      country_id: "",
      state_id: "",
      city_id: "",
    });
  };

  const handleInputData = (input) => (e) => {
    if(input === "dob"){
      if(e === null){
        console.log("HELLOOO")
        console.log(e)
        setFormData((prevState) => ({
          ...prevState,
          [input]: "",
        }));
      }
      else{
        const selected = moment(e).toDate();
      //   var mydate = new Date(date);
      // console.log(mydate)
      var dateString = new Date(selected.getTime() - (selected.getTimezoneOffset() * 60000 ))
                          .toISOString()
                          .split("T")[0];
      const finalDate = dateString.replace(/-/g, '/')
      console.log(finalDate)
      setFormData((prevState) => ({
        ...prevState,
        [input]: finalDate,
      }));
      
       
      }
    }
    else{
      const { value } = e.target;
      if (input === "country") {
        setFormData((prevState) => ({
          ...prevState,
          state: false,
          city: false,
        }));

        setFormData((prevState) => ({
          ...prevState,
          [input]: value,
        }));
      }else if (input === "state") {
        setFormData((prevState) => ({
          ...prevState,
          city: false,
        }));

        setFormData((prevState) => ({
          ...prevState,
          [input]: value,
        }));
      } else
        setFormData((prevState) => ({
          ...prevState,
          [input]: value,
        }));
    }
    
    
  };

  const dropdownData = (maritalValue, foodValue, religionValue) => {
   
      setFormData((prevState) => ({
        ...prevState,
        marital:maritalValue,
        food: foodValue,
        religion: religionValue
      }));
    }

  const setscreen = (input) => (e) => {
    setFormData({
      ...formData,
      screen_name: input,
    });
  };
  const countryData = (input) => (e) => {
    let obj = JSON.parse(e.target.value);
    console.log(obj);
    if (input === "country_id") {
      setLocationdata((prevState) => ({
        ...prevState,
        state_id: false,
        city_id: false,
      }));

      setLocationdata((prevState) => ({
        ...prevState,
        [input]: obj,
      }));
    } else if (input === "state_id") {
      setLocationdata((prevState) => ({
        ...prevState,
        city_id: false,
      }));

      setLocationdata((prevState) => ({
        ...prevState,
        [input]: obj,
      }));
    } else
      setLocationdata((prevState) => ({
        ...prevState,
        [input]: obj,
      }));
  };

  const handleLanguage = (input) => (e) => {
    let obj = JSON.parse(e.target.value);
    setLanguageData((prevState) => [...prevState, obj]);
  };

  const travelHandleChange = (e) => {
    const { value } = e.target;
    setTravel(value);
  };

  const onChangePicture = (input) => (e) => {
    if (e.target.files[0]) {
      console.log("picture: ", e.target.files);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      setFormData((prevState) => ({
        ...prevState,
        [input]: e.target.files[0],
      }));

      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleDeleteClick = (input) => (e) => {
    const newTaskArray = locationdata2.filter(
      (task) => task.city_id.id !== input
    );
    console.log(newTaskArray);
    setLocationdata2(newTaskArray);
  };

  const handleDeleteLanguage = (input) => (e) => {
    const newTaskArray = languageData.filter((task) => task.id !== input);
    console.log(newTaskArray);
    setLanguageData(newTaskArray);
  };

  const updateReferral = (data) => {
    setFormData((prevState) => ({
      ...prevState,
      referral_data : data
    }))
  }

  switch (step) {
    case 1:
      return (
        <Basic
          nextStep={nextStep}
          handleFormData={handleInputData}
          handlepicture={onChangePicture}
          values={formData}
          image={imgData}
          countrylist={countrylist}
          statedata3={statedata}
          citydata={citydata}
          emailCheck={emailCheck}
        />
      );
    case 2:
      return (
        <Other
          nextStep={nextStep}
          prevStep={prevStep}
          handleFormData={handleInputData}
          handleCountryData={countryData}
          handleDelete={handleDeleteClick}
          handleAddPlace={AddPlace}
          handlesetscreen={setscreen}
          countrylist={countrylist}
          locationStateData={locationStateData}
          values={formData}
          screendata={screendata}
          locationCityData={locationCityData}
          locationdata={locationdata}
          locationdata2={locationdata2}
          travelHandle={travelHandleChange}
          travel={travel}
          filterId={AddLocationList}
        />
      );
    case 3:
      return (
        <Personal
          nextStep={nextStep}
          prevStep={prevStep}
          handleFormData={handleInputData}
          dropdownData={dropdownData}
          handleLanguageChange={handleLanguage}
          languageData={languageData}
          values={formData}
          deleteLanguage={handleDeleteLanguage}
          updateReferral = {updateReferral}
        />
      );
    case 4:
      return (
        <Redirect
          to={{
            pathname: "/login",
          }}
        />
      );
    default:
      return <div className="App"></div>;
  }
};

export default SignUp;
