import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from '../components/layout/Home';
import SignUp from "../views/Signup";
import Login from "../views/login";
import Forgot from "../views/ForgotPassword";
import EmailActivation from "../views/EmailActivation";
import Reset from "../views/ResetPassword";
import Dashboard from "../views/pages/Dashboard";
import Question from "../views/pages/Questions";
import Legal from "../views/Legal";
import About from "../views/AboutUs";
import Contact from "../views/ContactUs";
import Faq from "../views/Faq";
import List  from "../views/pages/ExpertsList";
import Account from "../views/pages/Settings";
import Add from "../views/pages/Questions/add-new-question";
import User from "../views/pages/Questions/user-list-question";
import Points from "../views/pages/point/points";
import PaymentSuccess from "../views/pages/point/paymentSuccessful";
import PaymentCancel from "../views/pages/point/paymentCancel";
import Reedem from "../views/pages/point/reedem";
import Buy from "../views/pages/point/buy-points";
import Card from "../views/pages/point/card";
import Bank from "../views/pages/point/bank";
import NotificationsList from "../views/NotificationList";
import UserVerification from "../views/UserVerification";
import PrivateRoute from "./PrivateRoute";
const Router = () => (
 
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/signup" component={SignUp} />
      <Route exact path="/login" component={Login} />
      <Route exact path='/reset-password/:id/:token' component={Reset} />
      <Route exact path="/ForgotPassword" component={Forgot} />
      <PrivateRoute exact path="/dashboard" component={Dashboard} />
      <PrivateRoute exact path="/list" component={List} />
      <PrivateRoute exact path="/add" component={Add} />
     <PrivateRoute exact path="/edit/:quesid" component={Add} />
      <PrivateRoute exact path="/question" component={Question} />
      <PrivateRoute exact path="/user/:quesid/:id" component={User} />
      <PrivateRoute exact path="/account" component={Account} />
      <Route exact path="/paymentSuccess/:user_id/:amount/:no_of_points" component={PaymentSuccess} />
      <Route exact path="/payment-cancelled/:user_id" component={PaymentCancel} />
      <Route exact path="/Legal" component={Legal} />
      <Route exact path="/about" component={About} />
      <Route exact path="/contact" component={Contact} />
      <Route exact path="/faq" component={Faq} />
      <PrivateRoute exact path="/points" component={Points} />
      <PrivateRoute exact path="/reedem" component={Reedem} />
      <PrivateRoute exact path="/buy" component={Buy} />
      <PrivateRoute exact path="/card" component={Card} />
      <PrivateRoute exact path="/bank" component={Bank} />
      <Route exact path="/verify-email/:id/:token" component={EmailActivation} />
      <Route exact path="/update-user-status/:id/:token" component={UserVerification} />
      <PrivateRoute exact path="/notificationsList" component={NotificationsList} />
    </Switch>

);

export default Router;
