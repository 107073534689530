import React, { useState } from 'react';
import logo from '../assets/img/logo.png';
import { call } from '../utils/apiCall'
import Loading from '../components/Loaders/Loading'

export default function Forgot() {

  const [responsePath, setResponsePath] = useState('');
  const [email, setEmail] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false);

  const setStatus = () => {
    setErrorMessage('')
    setResponsePath('')
  }

  const checkValidation = () => {
    let emailvalid = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    let formIsValid = true;

    if (!emailvalid.test(email)) {
      setErrorMessage("Email id is not valid")
      formIsValid = false;
    }
    if (!email) {
      setErrorMessage("Email should not empty")
      formIsValid = false;
    }
    return formIsValid;
  }

  const submitLoginData = (e) => {
    e.preventDefault();
    if (checkValidation()) {
      setLoading(true)
      let emailData = { username: email };
      if (emailData) {
        (async () => {
          try {
            const res = await call(
              'post',
              '/reset-password',
              null,
              emailData
            )
            if (res.data.success === true) {
              setResponsePath(res.data.message)
              console.log(res.data.message)
              setLoading(false)
            }
            if (res.data.success === false) {
              setResponsePath(res.data.message)
              console.log(res.data.message)
              setLoading(false)
            }


          } catch (err) {
            console.log(err)
          }
        })()
      } else { }
    }
  };

  return (
    <div>
      <div className="limiter">
        <div className="container-login100">

          <div className="wrap-login100">
            <form className="login100-form validate-form">
              <div >
                <span className="login100-form-title"> Forgot your password? </span>
                <p className="mt-3">Please enter your email address below. We will send you the link to reset password on provided  email address.</p>

              </div>
              <div className="text-center mt-4 p-b-20">
                {responsePath && (
                  <label className="label-input100 text-danger" >
                    {responsePath}
                  </label>
                )}
              </div>
              <div className="wrap-input100 validate-input mt-4" data-validate="Valid email is required: ex@abc.xyz" >
                <div className="label-input100 ">Email</div>
                <div className="input">
                  <i className="icon-message"></i>
                  <input className="input100" type="text" name="email" placeholder="Enter your email address" onClick={setStatus} onChange={(e) =>
                    setEmail(e.target.value)
                  } />

                </div>
                {errorMessage && (
                  <label className="label-input100 text-danger" >
                    {errorMessage}
                  </label>
                )}
                <span className="focus-input100"></span>
              </div>
              <div className="container-login100-form-btn mt-5">
                <button onClick={submitLoginData} className="btn btn-xl w-100"> {loading ? (
                  <div style={{ paddingTop: "16px", paddingBottom: "8px" }}>
                    <Loading type={"button"} />
                  </div>
                ) : "Send"}</button>
              </div>
            </form>

            <div className="login100-more" >
              <div className="logo m-4 ">
                <a href="/" >
                  <img src={logo} className="img-fluid" alt='img' />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
