import React, { useState, useEffect } from "react";
import logo from "../../assets/img/logo.png";
import newlogo2 from "../../assets/img/newlogo2.jpg"
import camera from "../../assets/img/camera.png";
import { call } from "../../utils/apiCall";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { URL } from "../../constants/constants";
import DatePicker from "react-datepicker";
import MaskedInput from 'react-maskedinput';
import moment from 'moment';

import "react-datepicker/dist/react-datepicker.css";
import 'font-awesome/css/font-awesome.min.css';

const Basic = ({
  nextStep,
  handleFormData,
  handlepicture,
  values,
  image,
  countrylist,
  statedata3,
  citydata,
  emailCheck,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [errorconfirmEmail, setErrorconfirmEmail] = useState("");
  const [errorFirstName, setErrorFirstName] = useState("");
  const [errorLastName, setErrorLastName] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [errordob, setErrordob] = useState("");
  const [errorphone, setErrorphone] = useState("");
  const [errorconfirmpassword, setErrorconfirmpassword] = useState("");
  const [errorcountry, setErrorcountry] = useState("");
  const [errorstate, setErrorstate] = useState("");
  const [errorcity, setErrorcity] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [checkemailname, setCheckemailname] = useState("");
  const [startDate, setStartDate] = useState(new Date())

  function subtractYears(date, years) {
    date.setFullYear(date.getFullYear() - years);
  
    return date;
  }

  const setStatus = () => {
    setErrorMessage("");
  };
  const setconfirmemail = () => {
    setErrorconfirmEmail("");
  };
  const setfirstname = () => {
    setErrorFirstName("");
  };
  const setlastname = () => {
    setErrorLastName("");
  };
  const setpassword = () => {
    setErrorPassword("");
  };
  const setconfirmpassword = () => {
    setErrorconfirmpassword("");
  };
  const setdateofbirth = () => {
    setErrordob("");
  };
  const setphone = () => {
    setErrorphone("");
  };
  const setcountry = () => {
    setErrorcountry("");
  };
  const setstate = () => {
    setErrorstate("");
  };
  const setcity = () => {
    setErrorcity("");
  };

  const handleDateChange = async (date) => {
    console.log(date)
    if(date === null){
      console.log("HELLOOO")
      console.log(date)
      setStartDate("")
    }
    else{
      const selected = moment(date).toDate();
    //   var mydate = new Date(date);
    // console.log(mydate)
    var dateString = new Date(selected.getTime() - (selected.getTimezoneOffset() * 60000 ))
                        .toISOString()
                        .split("T")[0];
      
    
     setStartDate(new Date(dateString))
    }
    
  }

  useEffect(() => {
    (async () => {
      try {
        const res = await call("get", "/user-form-data", null, null);
        console.log(res);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const checkmore = () => {
    console.log(values.dob)
    let emailvalid =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let firstvalid = /^[a-zA-Z\s\.]+$/;  
    let passwordvalid =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/;
    let phonevalid = /^([+]\d{2})?\d{10}$/;
    let formIsValid = true;
    if (!emailvalid.test(values.email)) {
      setErrorMessage("Please enter valid email");
      formIsValid = false;
    }
    if (!values.email) {
      setErrorMessage("Email is required");
      formIsValid = false;
    }

    if (!(values.email === values.confirmemail)) {
      setErrorconfirmEmail("Confirm Email must be same");
      formIsValid = false;
    }
    if (!emailvalid.test(values.confirmemail)) {
      setErrorconfirmEmail("Please enter valid email");
      formIsValid = false;
    }
    if (emailCheck.success) {
      formIsValid = false;
    }
    if (!values.confirmemail) {
      setErrorconfirmEmail("Confirm Email is required");
      formIsValid = false;
    }
    if (!firstvalid.test(values.firstname)) {
      setErrorFirstName("Invalid first Name");
      formIsValid = false;
    }
    if (values.firstname.length < 2) {
      setErrorFirstName(
        "Must be exactly greater than or equal to 2 characters"
      );
      formIsValid = false;
    }
    if (!firstvalid.test(values.lastname)) {
      setErrorLastName("Invalid last Name");
      formIsValid = false;
    }
    if (values.lastname.length < 2) {
      setErrorLastName("Must be exactly greater than or equal to 2 characters");
      formIsValid = false;
    }
    if (values.registration === "email") {
      if (!passwordvalid.test(values.password)) {
        setErrorPassword(
          "Password must atleast 8 characters containing one uppercase, lowercase, number and special character"
        );
        formIsValid = false;
      }
      if (!values.password) {
        setErrorPassword("Password should not empty");
        formIsValid = false;
      }
      if (!(values.password === values.confirmpassword)) {
        setErrorconfirmpassword("It must be same as password");
        formIsValid = false;
      }
      if (!values.confirmpassword) {
        setErrorconfirmpassword("Confirm Password should not empty");
        formIsValid = false;
      }
    }
    if (!values.dob) {
      setErrordob("Select Birth Date");
      formIsValid = false;
    }
    if (!phonevalid.test(values.phone)) {
      setErrorphone("Invalid Mobile Number");
      formIsValid = false;
    }
    if (!values.phone) {
      setErrorphone("Phone Number is required");
      formIsValid = false;
    }
    if (!values.country || values.country === "Select Country") {
      setErrorcountry("Please select country");
      formIsValid = false;
    }
    if (!values.state) {
      setErrorstate("Please select state");
      formIsValid = false;
    }
    if (!values.city) {
      setErrorcity("Please select city");
      formIsValid = false;
    }

    return formIsValid;
  };

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const dateValid = formatDate(new Date(new Date().getFullYear() - 18, 11, 31));
  const currentDate = formatDate(new Date());

  const submitFormData = (e) => {
    e.preventDefault();
    if (checkmore()) {
      nextStep();
    }
  };

  return (
    <div>
      <div className="basic">
        <div className="sign-up">
          <div className="logo">
            <a href={URL} className="navbar-brand">
              <img src={newlogo2} className="img-fluid" alt="img"  width={"150px"} height = {"60px"}/>
            </a>
          </div>
          <div className="heading">
            <h1>Sign up now</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </div>
          <div className="process">
            <ul>
              <li>
                <div className="node green">1</div>
                <p> Basic information</p>
              </li>
              <li>
                <div className="divider"></div>
              </li>
              <li>
                <div className="node green">2</div>
                <p>Other details</p>
              </li>
              <li>
                <div className="divider"></div>
              </li>
              <li>
                <div className="node green">3</div>
                <p>Personal details</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="wrap">
          <div>
            <h2>Basic Information</h2>
            <div className="photo">
              <div className="pro-pic">
                <img src={image} alt="" style={{ width: "100%" }} />
              </div>
              <div className="pic">
                <input
                  type="file"
                  id="profile_pic"
                  name="profile_pic"
                  style={{ display: "none" }}
                  onChange={handlepicture("profile_pic")}
                />{" "}
                <label htmlFor="profile_pic">
                  <div className="camera">
                    <img src={camera} alt="img" />
                  </div>
                </label>
              </div>
            </div>
            <div className="row">
              <div className="row">
                <div className="col-lg-6">
                  <div className="label-input100 mt-3 ">Email*</div>
                  <div className="input">
                    {values.registration === "google" ||
                    values.registration === "facebook" ||
                    values.registration === "apple" ? (
                      <input
                        className="input100"
                        type="text"
                        name="email"
                        value={values.email}
                        placeholder="Enter the email id"
                        disabled
                      />
                    ) : (
                      <input
                        className="input100"
                        onClick={setStatus}
                        type="text"
                        name="email"
                        value={values.email}
                        onChange={handleFormData("email")}
                        placeholder="Enter the email id"
                      />
                    )}
                    {emailCheck.success === true ? (
                      <label className="label-input100 text-danger">
                        Email already exists
                      </label>
                    ) : emailCheck === "notValid" ? (
                      <div></div>
                    ) : (
                      <div></div>
                    )}
                  </div>

                  {errorMessage && (
                    <label className="label-input100 text-danger">
                      {errorMessage}
                    </label>
                  )}
                </div>
                <div className="col-lg-6">
                  <div className="label-input100 mt-3 ">Confirm Email ID*</div>
                  {values.registration === "google" ||
                  values.registration === "facebook" ||
                  values.registration === "apple" ? (
                    <input
                      className="input100"
                      type="text"
                      name="confirmemail"
                      value={values.confirmemail}
                      placeholder="Enter the email Id"
                      disabled
                    />
                  ) : (
                    <input
                      className="input100"
                      onClick={setconfirmemail}
                      type="text"
                      name="confirmemail"
                      value={values.confirmemail}
                      onChange={handleFormData("confirmemail")}
                      placeholder="Enter the email Id"
                    />
                  )}
                  {errorconfirmEmail && (
                    <label className="label-input100 text-danger">
                      {errorconfirmEmail}
                    </label>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="label-input100 mt-3">First Name*</div>
                  <input
                    className="input100"
                    type="text"
                    onClick={setfirstname}
                    name="firstname"
                    value={values.firstname}
                    onChange={handleFormData("firstname")}
                    placeholder="First Name"
                  />
                  {errorFirstName && (
                    <label className="label-input100 text-danger">
                      {errorFirstName}
                    </label>
                  )}
                </div>
                <div className="col-lg-6">
                  <div className="label-input100 mt-3">Last Name*</div>
                  <input
                    className="input100"
                    type="text"
                    onClick={setlastname}
                    name="lastname"
                    value={values.lastname}
                    onChange={handleFormData("lastname")}
                    placeholder="Last Name"
                  />
                  {errorLastName && (
                    <label className="label-input100 text-danger">
                      {errorLastName}
                    </label>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 position-relative">
                  <div className="label-input100 mt-3">Password*</div>
                  {/* {values.registration==="google"||values.registration==="facebook"||values.registration==="apple" ? <input className="input100" type={passwordShown ? "text" : "password"} name="password" value={values.password} placeholder="**********" disabled/> : */}
                  <input
                    className="input100"
                    type={passwordShown ? "text" : "password"}
                    onClick={setpassword}
                    name="password"
                    value={values.password}
                    onChange={handleFormData("password")}
                    placeholder="**********"
                  />
                  <span className="eye-icon" onClick={togglePassword}>
                    {passwordShown ? (
                      <Visibility color="action" />
                    ) : (
                      <VisibilityOff color="action" />
                    )}
                  </span>
                  {errorPassword && (
                    <label className="label-input100 text-danger">
                      {errorPassword}
                    </label>
                  )}
                </div>
                <div className="col-lg-6  position-relative">
                  <div className="label-input100 mt-3">Confirm Password*</div>
                  {/* {values.registration==="google"||values.registration==="facebook"||values.registration==="apple" ?  <input className="input100" type={passwordShown ? "text" : "password"} name="confirmpassword" value={values.confirmpassword} placeholder="**********" disabled/> :  */}
                  <input
                    className="input100"
                    type={passwordShown ? "text" : "password"}
                    onClick={setconfirmpassword}
                    name="confirmpassword"
                    value={values.confirmpassword}
                    onChange={handleFormData("confirmpassword")}
                    placeholder="**********"
                  />
                  <span className="eye-icon" onClick={togglePassword}>
                    {passwordShown ? (
                      <Visibility color="action" />
                    ) : (
                      <VisibilityOff color="action" />
                    )}
                  </span>
                  {errorconfirmpassword && (
                    <label className="label-input100 text-danger">
                      {errorconfirmpassword}
                    </label>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="label-input100 mt-3 ">DOB*</div>
                  {/* <input
                    className="input100"
                    type="date"
                    min="1940-01-01"
                    max="9999-12-31T23:59"
                    onClick={setdateofbirth}
                    name="dob"
                    value={values.dob}
                    onChange={handleFormData("dob")}
                    placeholder="06-03-1996"
                  /> */}

                  <DatePicker 
                          selected={values.dob === "" ? values.dob : new Date(values.dob)} 
                          onFocus = {setdateofbirth}
                          dateFormat = "yyyy/MM/dd"
                          onChange={handleFormData("dob")} 
                          minDate = {new Date('1940-01-01')}
                          maxDate = {subtractYears(new Date(), 18)}
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown={true}
                          showYearDropdown
                          showMonthDropdown
                          // dropdownMode = 'scroll'
                          customInput = {
                            <MaskedInput mask="1111/11/11"></MaskedInput>
                          }
                    />  

                  {errordob && (
                    <label className="label-input100 text-danger">
                      {errordob}
                    </label>
                  )}
                </div>
                <div className="col-lg-6">
                  <div className="label-input100 mt-3">Phone Number*</div>
                  <input
                    className="input100"
                    onClick={setphone}
                    type="text"
                    name="phone"
                    value={values.phone}
                    onChange={handleFormData("phone")}
                    placeholder="Enter your phone*"
                  />
                  {errorphone && (
                    <label className="label-input100 text-danger">
                      {errorphone}
                    </label>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="label-input100 mt-3">Country*</div>

                  <select
                    className=" classNameic"
                    onClick={setcountry}
                    aria-label="Default select example"
                    value={values.country}
                    onChange={handleFormData("country")}
                  >
                    <option defaultValue>Select Country</option>
                    {countrylist.countryList?.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.country_name}
                        </option>
                      );
                    })}
                  </select>
                  {errorcountry && (
                    <label className="label-input100 text-danger">
                      {errorcountry}
                    </label>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-sm-12">
                  <div className="left">
                    <div className="label-input100 mt-3">State*</div>
                    <select
                      className=" classNameic"
                      onClick={setstate}
                      aria-label="Default select example"
                      value={values.state}
                      onChange={handleFormData("state")}
                    >
                      <option defaultValue>Select State</option>
                      {statedata3.stateList?.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.state_name}
                          </option>
                        );
                      })}
                    </select>
                    {errorstate && (
                      <label className="label-input100 text-danger">
                        {errorstate}
                      </label>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="left">
                    <div className="label-input100 mt-3">City*</div>
                    <select
                      className=" classNameic"
                      onClick={setcity}
                      aria-label="Default select example"
                      value={values.city}
                      onChange={handleFormData("city")}
                    >
                      <option defaultValue>Select City</option>
                      {citydata.cityList?.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.city_name}
                          </option>
                        );
                      })}
                    </select>
                    {errorcity && (
                      <label className="label-input100 text-danger">
                        {errorcity}
                      </label>
                    )}
                    <div className="txt">
                      Choose the closest city if not in list.
                    </div>
                    <div className="text-end mt-5">
                      <span className="btn btn-xl" onClick={submitFormData}>
                        Next
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Basic;
