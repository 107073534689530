import React, { useEffect, useState } from "react";
import Loader from "../../../components/Loaders/ComponentLoader";
import { Modal } from "react-bootstrap";

const AccountSettings = ({submitData}) => {

  const [loading, setLoading] = useState(false);
  const [isOpenAccount, setIsOpenAccount] = useState(false);
  
  
  const openAccountModal = () => setIsOpenAccount(true);
  const closeAccountModal = () => setIsOpenAccount(false);

  return (
    <div>
      {loading ? <Loader/>:null}
      <div className="tabs_pannel" id="tab4">
      <div className="notification">
      <p>
         Do you want to delete or deactivate this account? 
          </p>
          
          <div className="mt-2">
                                  <span className="btn-outline" style={{padding:"10px 15px", cursor:"pointer"}} onClick={openAccountModal}>
                                    Delete Account
                                  </span>
                                </div>
          </div>
      </div>
      <Modal
      show={isOpenAccount}
      onHide={closeAccountModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
      </Modal.Header>

      <Modal.Body className="px-5 terms-1">
    
        <h5 style={{ color: "#000000", textAlign: "center" }}>Are you sure you want to sure deactivate this account?</h5>
      </Modal.Body>
      <div style={{ display: "flex", justifyContent: "center", paddingBottom: "20px" }}>
      
        <div className="text-left small">
          <button className="btn btn-outline mx-3 " onClick={closeAccountModal}>No</button>
          <button className="btn btn-xl shadow-none " onClick={submitData}>Yes</button>
        </div>

      </div>
     
    </Modal>
    </div>
    
  );
};

export default AccountSettings;
