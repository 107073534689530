import React, { useState, useEffect } from 'react';
import logo from '../assets/img/logo.png';
import { call } from '../utils/apiCall'
import { useParams, useHistory } from 'react-router-dom';

export default function UserVerification() {

  const [responsePath, setResponseMessage] = useState('');
  const [showContent, setShowContent] = useState(false)
  //const token = localStorage.getItem("accessToken"); 
  const history = useHistory()
  const [second, setSecond] = useState(5)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const { id, token } = useParams();

  if(showContent === true || error=== true){
    setInterval(() => {
        setSecond(second - 1)
    }, 1000)
    if(second === 0){
        history.push('/login')
    }
}

  useEffect(() => {

    let userData = { user_id: id };
    (async () => {
      try {
        const res = await call(
          'patch',
          '/update-user-verification',
          null,
          userData,
          token
        )
        if(res.data.success === true){
            setResponseMessage(res.data.message)
            setShowContent(true)  
        }
        else{
          setError(true)
          setErrorMessage(res.data.message)
        }


      } catch (err) {
        if(err?.response?.data?.success == false){
          setError(true)
          if(err?.response?.data?.error && err?.response?.data?.error.message == "jwt expired"){
            setErrorMessage("The link is expired. Please connect with admin.")
          }
          else if(err?.response?.data?.error && err?.response?.data?.error.message == "invalid signature"){
            setErrorMessage("E-mail already verified using this link")
          }
        }
      }
    })()

  }, []);


  return (
    showContent ? 
    <div>
      <div className="limiter">
        <div className="container-login100">

          <div className="wrap-login100">
            <form className="login100-form validate-form">
              <div >
                <span className="login100-form-title"> Email Activation </span>
                <p className="mt-3">Thanks for verifying your new Email id. {responsePath}</p>
                <p className="mt-3">Redirecting to login page in {second} second....</p>
              </div>
              <div className="text-center mt-4 p-b-20">
              </div>

            </form>

            <div className="login100-more" >
              <div className="logo m-4 ">
                <a href="/" >
                  <img src={logo} className="img-fluid" alt='img' />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> : 
    error ? 
    <div>
      <div className="limiter">
        <div className="container-login100">

          <div className="wrap-login100">
            <form className="login100-form validate-form">
              <div >
                <span className="login100-form-title"> Error in email activation </span>
                <p className="mt-3">{errorMessage}</p>
                <p className="mt-3">Redirecting to login page in {second} second....</p>
              </div>
              <div className="text-center mt-4 p-b-20">
              </div>

            </form>

            <div className="login100-more" >
              <div className="logo m-4 ">
                <a href="/" >
                  <img src={logo} className="img-fluid" alt='img' />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> : <>Please Wait</>
  )
}
